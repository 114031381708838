<section>
  <div class="wrap">
    <div class="head">
      <h2>{{'OPENPAY.TITLE' | translate}}</h2>
    </div>
    <div class="body">
      <div class="cards">
        <div class="credit">
          <p>{{'OPENPAY.CREDIT-CARDS' | translate}}</p>
          <div class="img-container">
            <img src="assets/images/open-pay/cards1.png" alt="">
          </div>
        </div>
        <hr>
        <div class="credit">
          <p>{{'OPENPAY.DEBITS-CARDS' | translate}}</p>
          <div class="img-container">
            <img src="assets/images/open-pay/cards2.png" alt="">
          </div>
        </div>
      </div>
      <form [formGroup]="myForm" id="payment-form">
        <input type="hidden" name="token_id" id="token_id">
        <div class="inputs-container">
          <div class="form-group">
            <label>{{'OPENPAY.NAME-CARD' | translate}}</label>
            <input
              [ngClass]="{'alert': myForm.controls['holder_name'].invalid && myForm.controls['holder_name'].touched}"
              type="text" [placeholder]="'OPENPAY.PLACEHOLDER.HOLDER-NAME' | translate" autocomplete="off"
              formControlName="holder_name">
          </div>
          <div class="form-group">
            <label>{{'OPENPAY.NUMBER-CARD' | translate}}</label>
            <input
              [ngClass]="{'alert': myForm.controls['card_number'].invalid && myForm.controls['card_number'].touched}"
              type="tel" [placeholder]="'OPENPAY.PLACEHOLDER.CARD-NUMBER' | translate" autocomplete="off"
              formControlName="card_number" mask="0000-0000-0000-0000">
          </div>
          <div class="form-group">
            <label>{{'OPENPAY.DATE-CARD' | translate}}</label>
            <div class="half">
              <input
                [ngClass]="{'alert': myForm.controls['expiration_month'].invalid && myForm.controls['expiration_month'].touched}"
                type="text" [placeholder]="'OPENPAY.PLACEHOLDER.MONTH' | translate" maxlength="2"
                formControlName="expiration_month" oninput="(value=value.replace(/\D+/g, ''))">
              <input
                [ngClass]="{'alert': myForm.controls['expiration_year'].invalid && myForm.controls['expiration_year'].touched}"
                type="text" [placeholder]="'OPENPAY.PLACEHOLDER.YEAR' | translate" maxlength="2"
                formControlName="expiration_year" oninput="(value=value.replace(/\D+/g, ''))">
            </div>
          </div>
          <div class="form-group">
            <label>{{'OPENPAY.CVV' | translate}}</label>
            <div class="half">
              <input [ngClass]="{'alert': myForm.controls['cvv2'].invalid && myForm.controls['cvv2'].touched}"
                type="password" [placeholder]="'OPENPAY.PLACEHOLDER.CVV' | translate" autocomplete="off" minlength="3"
                maxlength="4" formControlName="cvv2" oninput="(value=value.replace(/\D+/g, ''))">
              <div class="cvv">
                <img src="assets/images/open-pay/cvv.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="cards">
            <div class="credit">
              <p class="open">{{'OPENPAY.TRANSACTIONS' | translate}}</p>
              <div class="img-container">
                <img src="assets/images/open-pay/openpay.png" alt="">
              </div>
            </div>
            <hr>
            <div class="debit">
              <div class="img-container">
                <img src="assets/images/open-pay/security.png" alt="">
                <p>{{'OPENPAY.ENCRYPTION' | translate}}</p>
              </div>
            </div>
          </div>
          <div class="btn-container">
            <div class="sctn-row">
              <!-- <a class="button rght" id="pay-button" (click)="pay()" [class.disabled]="isPaying">{{isPaying ?
                "Pagando..." : "Pagar"}}</a> -->
              <a class="button rght" id="pay-button" (click)="pay()" [class.disabled]="isPaying">{{'OPENPAY.BTN' |
                translate}}</a>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
