import { Component, OnInit } from '@angular/core';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RouterListenersService } from './services/translate-service/router-listeners/router-listeners.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'mextoptours-web';
  constructor(
    private translate: TranslateService,
    private routerListenerService: RouterListenersService,
    public router: Router,
  ) {
    this.translate.setDefaultLang('en');

    let sub = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        let url = event.url;
        this.setInitialLanguageFromRoute(url);
        sub.unsubscribe();
      }
    });

    this.routerListenerService.setRouterEventListener();
  }

  setInitialLanguageFromRoute(url: string) {
    let segments = this.routerListenerService.transformRouteFromUnsplited(url);
    // console.log(segments);

    if (segments.length == 0) return;
    if (segments[0] != 'es' && segments[0] != 'en') {
      this.translate.use('en');
      return;
    }
    if (segments[0] == 'es') {
      this.translate.use('es');
    } else if (segments[0] == 'en') {
      this.translate.use('en');
    }
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });
}
}
