
<ng-container *ngIf="data.type == 1">
    <div mat-dialog-content>
        <h1 mat-dialog-title style="color: red; text-align: center;">{{'ERROR_TITLE' | translate}}</h1>
        <br>
        <p style="text-align: center;font-size: 17px;">{{'ERROR_TXT' | translate}}</p>
    </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close>{{'CLOSE' | translate}}</button>
    </div>
</ng-container>
<ng-container *ngIf="data.type == 2">
    <div mat-dialog-content style="text-align: center;">
        <p style="text-align: center;font-size: 17px;">{{'LOADER' | translate}}</p>
        <img src="assets/images/loader.gif" alt="" width="170">
    </div>
</ng-container>