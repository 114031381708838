import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HomeComponent } from './pages/home/home.component';
import { TransfersComponent } from './pages/transfers/transfers.component';
import { FormTransferComponent } from './pages/transfers/form-transfer/form-transfer.component';
import { InfoTransferComponent } from './pages/transfers/info-transfer/info-transfer.component';
import { SliderTransferComponent } from './pages/transfers/slider-transfer/slider-transfer.component';
import { TransferCardsComponent } from './components/transfer-cards/transfer-cards.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreComponent } from './pages/store/store.component';
import { CartItemComponent } from './components/cart-item/cart-item.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { NumberDirective } from './directives/valid-number.directive';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPayPalModule } from 'ngx-paypal';
import { ThanksPageComponent } from './components/thanks-page/thanks-page.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
// import { NgxPrintModule } from 'ngx-print';
import { StripeElementsComponent } from './components/stripe-elements/stripe-elements.component';
import { EditCheckoutInfoComponent } from './edit-checkout-info/edit-checkout-info.component';
import { HomeFormComponent } from './components/home-form/home-form.component';
import { ChoseTransferComponent } from './components/chose-transfer/chose-transfer.component';
import { LimitPaxFormComponent } from './components/limit-pax-form/limit-pax-form.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { DatePipe } from '@angular/common';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PaypalElementsComponent } from './components/paypal-elements/paypal-elements.component';
import { OpenpayElementsComponent } from './components/openpay-elements/openpay-elements.component';

@NgModule({
  declarations: [
    AppComponent,
    SearchBarComponent,
    HomeComponent,
    TransfersComponent,
    FormTransferComponent,
    InfoTransferComponent,
    SliderTransferComponent,
    TransferCardsComponent,
    HeaderComponent,
    FooterComponent,
    NumberDirective,
    StoreComponent,
    CartItemComponent,
    CheckoutComponent,
    ThanksPageComponent,
    ErrorModalComponent,
    StripeElementsComponent,
    EditCheckoutInfoComponent,
    HomeFormComponent,
    ChoseTransferComponent,
    LimitPaxFormComponent,
    PaypalElementsComponent,
    OpenpayElementsComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    AppRoutingModule,
    RouterModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatDialogModule,
    MatBadgeModule,
    MatListModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatRadioModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    NgxMaskModule.forRoot(),
    MatNativeDateModule,
    NgxPayPalModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [HttpClient]
      }
    }),
    // NgxPrintModule,
    Ng2TelInputModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,

  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
