<div class="container-info-transfer">
    <div class="info-transfer">
        <!-- <p class="dir">
            {{'TRANSFER.DIR' | translate}} / Transfers / {{transferInfo.city}} Tours
        </p> -->
        <h3>
            {{lang == 'es' ? transferInfo.nameEs : transferInfo.nameEn}}
        </h3>

        <p class="price" *ngIf="price != 0">
            $ {{price}}.00 USD
        </p>
    </div>
    <form [formGroup]="submitForm" (submit)="save()">
        <div class="form-control">
            <mat-form-field appearance="outline">
                <mat-label>PAX</mat-label>
                <mat-select formControlName="persons" (selectionChange)="selectPersonAndPax()" required>
                    <mat-option value="1">1 persona</mat-option>
                    <mat-option value="2">2 personas</mat-option>
                    <mat-option value="3">3 personas</mat-option>
                    <mat-option value="4">4 personas</mat-option>
                    <mat-option value="5">5 personas</mat-option>
                    <mat-option value="6">6 personas</mat-option>
                    <mat-option value="7">7 personas</mat-option>
                    <mat-option value="8">8 personas</mat-option>
                    <mat-option value="9">9 personas</mat-option>
                    <mat-option value="10">10 personas</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="form-control">
            <mat-form-field appearance="outline">
                <mat-label> {{"TRANSFER.FORM.FIELD1.TEXT" | translate}} </mat-label>
                <mat-select formControlName="wayRoundTrip" (selectionChange)="selectPersonAndPax()">
                    <mat-option value="roundTrip">{{"TRANSFER.FORM.FIELD1.OPTION1" | translate}}</mat-option>
                    <mat-option value="oneWayHotel">{{"TRANSFER.FORM.FIELD1.OPTION2" | translate}}</mat-option>
                    <mat-option value="oneWayAirport">{{"TRANSFER.FORM.FIELD1.OPTION3" | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <!-- <div class="form-control">
            <mat-form-field appearance="outline">
                <mat-label>{{"TRANSFER.FORM.FIELD2" | translate}}</mat-label>
                <mat-select formControlName="hotelCity">
                    <ng-container *ngFor="let item of hotels">
                        <mat-option [value]="item.name"> {{item.name}} </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="form-control">
            <mat-form-field appearance="outline">
                <mat-label>{{"TRANSFER.FORM.FIELD3" | translate}}</mat-label>
                <input matInput formControlName="otherPlaceHotel">
            </mat-form-field>
        </div>
        <div class="form-control">
            <mat-form-field appearance="outline">
                <mat-label>{{"TRANSFER.FORM.FIELD4" | translate}}</mat-label>
                <input matInput formControlName="airline">
            </mat-form-field>
        </div>
        <div class="form-control">
            <mat-form-field appearance="outline">
                <mat-label>{{"TRANSFER.FORM.FIELD5" | translate}}</mat-label>
                <input matInput formControlName="flightNumber">
            </mat-form-field>
        </div> -->
        <div class="form-control">
            <mat-form-field appearance="outline">
                <mat-label>{{"TRANSFER.FORM.FIELD6" | translate}}</mat-label>
                <input readonly matInput [matDatepicker]="picker" formControlName="arrivalDate" [min]="minDate" (dateChange)="changeDate()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="form-control">
            <mat-form-field appearance="outline">
                <mat-label>{{"TRANSFER.FORM.FIELD7" | translate}}</mat-label>
                <input readonly matInput [matDatepicker]="picker2" formControlName="departureDate" [min]="departureDate">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>
        <!-- <div class="form-control">
            <mat-form-field appearance="outline">
                <mat-label>{{"TRANSFER.FORM.FIELD8" | translate}} </mat-label>
                <input matInput formControlName="firstName">
            </mat-form-field>

        </div>
        <div class="form-control">
            <mat-form-field appearance="outline">
                <mat-label>{{"TRANSFER.FORM.FIELD9" | translate}}</mat-label>
                <input matInput formControlName="lastName">
            </mat-form-field>
        </div>
        <div class="form-control">
            <mat-form-field appearance="outline">
                <mat-label>{{"TRANSFER.FORM.FIELD10" | translate}} </mat-label>
                <input matInput formControlName="phoneNumber" type="text" mask="0000000000">
            </mat-form-field>
        </div>
        <div class="form-control">
            <mat-form-field appearance="outline">
                <mat-label>{{"TRANSFER.FORM.FIELD11" | translate}}</mat-label>
                <textarea matInput formControlName="specialRequirements"></textarea>
            </mat-form-field>
        </div>
        <div class="form-control">
            <mat-form-field appearance="outline">
                <mat-label>{{"TRANSFER.FORM.FIELD12" | translate}}</mat-label>
                <input matInput type="number" min="1" formControlName="quantity" class="example-right-align" placeholder="0">
            </mat-form-field>
        </div> -->

        <button mat-flat-button class="btn-green">{{"NEXT" | translate}}</button>
    </form>
    <!-- <div class="add-and-share">
        <p>
            {{"TRANSFER.SAVETEXT" | translate}}
        </p>
        <button mat-flat-button class="btn-green">
            <mat-icon aria-hidden="false">favorite_border</mat-icon>  {{"TRANSFER.BUTTONFAV" | translate}}
        </button>
        <p class="questions">
            {{"TRANSFER.QUESTIONTEXT" | translate}}
        </p>
        <button mat-flat-button class="btn-green"> 
            <svg style="width: 30px;" viewBox="0 0 22 22"
                xml:space="http://www.w3.org/2000/svg">
                <path fill="#FFFFFF" fill-rule="evenodd"
                    d="M11 3c4.507 0 8 3.301 8 7.76 0 4.46-3.493 7.76-8 7.76-.81 0-1.587-.106-2.316-.307a.638.638 0 0 0-.427.032l-1.587.7a.641.641 0 0 1-.898-.566l-.044-1.423a.645.645 0 0 0-.215-.456C3.956 15.108 3 13.093 3 10.76 3 6.301 6.493 3 11 3zm-4.936 9.44c-.233.341.22.728.566.483l2.595-1.833a.524.524 0 0 1 .594-.001l1.92 1.34c.576.402 1.4.26 1.784-.306l2.413-3.563c.233-.341-.22-.728-.566-.483l-2.593 1.831a.524.524 0 0 1-.594.002l-1.92-1.34c-.576-.402-1.4-.26-1.784.306L6.064 12.44z">
                </path>
            </svg>  {{"TRANSFER.BUTTONMSM" | translate}}</button>
    </div> -->
</div>