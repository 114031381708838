import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { lastValueFrom } from 'rxjs';
import { DefaultResponse } from 'src/app/models/http.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OpenpayService {
  private PAYMENT_URI: string = `${environment.URL_API}/payment/`;

  constructor(
    private http: HttpClient

  ) { }

  public async paymentCharge(chargeRequest: any, paymentInfo: any): Promise<DefaultResponse> {
    try {
      // const response = await lastValueFrom(this.http.post<DefaultResponse>(this.PAYMENT_URI + 'create-charge', { chargeRequest, paymentInfo }));
      const response: any = await this.http.post<{ code: number, message: string, data: any }>(this.PAYMENT_URI + 'create-charge', { chargeRequest, paymentInfo }).toPromise();
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async paymentChargeRenew(chargeRequest: any, paymentInfo: any): Promise<DefaultResponse> {
    try {
      // const response = await lastValueFrom(this.http.post<DefaultResponse>(this.PAYMENT_URI + 'create-charge-renew', { chargeRequest, paymentInfo }));
      const response: any = await this.http.post<{ code: number, message: string, data: any }>(this.PAYMENT_URI + 'create-charge-renew', { chargeRequest, paymentInfo }).toPromise();
      return response;
    } catch (error) {
      console.log(error);

      throw error;
    }
  }

  // public async createSubscription(payment_info: any): Promise<DefaultResponse> {
  //   try {
  //     // const response = await lastValueFrom(this.http.post<DefaultResponse>(this.PAYMENT_URI + 'create-subscription', { payment_info: payment_info }));
  //     const response: any = await this.http.post<{ code: number, message: string, data: any }>(this.PAYMENT_URI, { chargeRequest, paymentInfo }).toPromise();

  //     return response;
  //   } catch (error) {
  //     console.log(error);

  //     throw error;
  //   }
  // }

  // public async createFreeSubscription(payment_info: any): Promise<DefaultResponse> {
  //   try {
  //     const response = await lastValueFrom(this.http.post<DefaultResponse>(this.PAYMENT_URI + 'create-subscription-free', { payment_info: payment_info }));
  //     const response: any = await this.http.post<{ code: number, message: string, data: any }>(this.PAYMENT_URI, { chargeRequest, paymentInfo }).toPromise();
  //     return response;
  //   } catch (error) {
  //     console.log(error);

  //     throw error;
  //   }
  // }
}
