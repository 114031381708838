import { Component, Input, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart/cart.service';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit {

  @Input() transfer;
  public cant = Array.from({length:20},(v,k)=>k+1);

  constructor(private cartService: CartService) { }

  ngOnInit(): void {
  }

}
