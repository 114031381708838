import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from "rxjs/operators";
import { Hotel } from 'src/app/models/hotel.model';
import { Transfer } from 'src/app/models/transfer.model';

@Injectable({
    providedIn: 'root'
})
export class HotelsService {

    constructor(
        private afs: AngularFirestore
    ) { }


    getHotelsByCity(city: string) {
        try {
            let cityNew = city == 'cancun' ? 'Cancún' : 'México';
            let ref = this.afs.collection('hotels', ref => ref.where('city', '==', cityNew));
            let obs$ = ref.snapshotChanges().pipe(
                map((actions) => {
                    return actions.map((a) => {
                        const data = a.payload.doc.data() as Hotel;
                        const id = a.payload.doc.id;
                        return { id, ...data }
                    });
                })
            );
            return obs$;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    searchHotelTrasnfers(city: string) {
        try {
            // DEVELOPMENT
            // let ref = this.afs.collection('transfers-hotels', ref => ref.where('city', '==', city).limit(5));
            // PRODUCTION
            let ref = this.afs.collection('transfers-hotels', ref => ref.where('city', '==', city));
            let obs$ = ref.snapshotChanges().pipe(
                map((actions) => {
                    return actions.map((a) => {
                        const data = a.payload.doc.data() as any;
                        const id = a.payload.doc.id;
                        return { id, ...data }
                    });
                })
            );
            return obs$;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async searchHotelByName(name: string) {
        try {
            let ref = this.afs.collection('transfers-hotels', ref => ref.where('nameEs', '==', name));
            var snapshot = await ref.get().toPromise();
            var docs = snapshot.docs.map(individualDoc => {
                return individualDoc.data();
            })
            return docs;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getZone(zoneID: string) {
        try {
            let res = await this.afs.collection('transfers').doc(zoneID).ref.get();
            return res.data() as any;
        } catch (error) {
            console.log(error);
            throw error;

        }
    }
}
