<div class="overlay" (click)="closeMobileMenu()" *ngIf="toggle == true"></div>

<div class="header desktop">
  <div class="hcontainer">
    <div class="dmheader">
      <div class="cols">
        <div class="image">
          <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx':'https://www.mexicotoptours.com/'">
            <img src="assets/images/logo.webp" alt="">
          </a>
        </div>
        <div class="list">
          <nav>
            <ul>

              <!-- HOME -->
              <li>
                <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx':'https://www.mexicotoptours.com/'">
                  <span>
                    {{'MENU.HOME' | translate}}
                  </span>
                </a>
              </li>

              <!-- CANCUN EXCURSION -->
              <li class="dropdown" *ngIf="lang == 'en'">
                <a class="dropbtn"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/excursiones-cancun':'https://www.mexicotoptours.com/excursions'">
                  <span>
                    {{'MENU.TRIP' | translate}}
                    <i class="fa fa-chevron-down"></i>
                  </span>
                </a>
                <div class="dropdown-content">

                  <div class="cancun-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/cancun-excursions':'https://www.mexicotoptours.com/cancun-excursions'"
                      mat-menu-item><span> {{'CANCUN-EXCURSIONS.ALL-EXCURSIONS' | translate}} </span></a>
                  </div>

                  <div class="cancun-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/ecoparques':'https://www.mexicotoptours.com/eco-parks'"
                      mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.TITLE' | translate}} <i
                          class="fa fa-chevron-right"></i></span></a>
                    <div class="dropdown-content-2" [ngStyle]="{'left': '100%', 'top': '11%'}">
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ATV-CENOTE-p222022215':'https://www.mexicotoptours.com/tienda/ATV-CENOTE-p222022215'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.1' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.2' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637':'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.3' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545':'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.4' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738':'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.5' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190':'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.6' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272':'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.7' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XENOTES-p467504432':'https://www.mexicotoptours.com/tienda/XENOTES-p467504432'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.8' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006':'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.9' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-p467759778':'https://www.mexicotoptours.com/tienda/XPLOR-p467759778'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.10' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329':'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.11' | translate}}</span></a>
                    </div>
                  </div>

                  <div class="mexico-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/family':'https://www.mexicotoptours.com/family'"
                      mat-menu-item><span>{{'CANCUN-EXCURSIONS.FAMILY.TITLE' | translate}} <i
                          class="fa fa-chevron-right"></i></span></a>
                    <div class="dropdown-content-3 family" [ngStyle]="{'left': '100%', 'top': '22%'}">
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274':'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.2' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229':'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.3' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174':'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.4' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448':'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.5' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861':'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.6' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563':'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.7' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.8' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637':'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.9' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545':'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.10' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738':'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.11' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190':'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.12' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018':'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.13' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272':'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.14' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XENOTES-p467504432':'https://www.mexicotoptours.com/tienda/XENOTES-p467504432'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.15' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006':'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.16' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-p467759778':'https://www.mexicotoptours.com/tienda/XPLOR-p467759778'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.17' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329':'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.18' | translate}}</span></a>
                    </div>
                  </div>

                  <div class="mexico-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/archeological':'https://www.mexicotoptours.com/archeological'"
                      mat-menu-item><span>{{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.TITLE' | translate}} <i
                          class="fa fa-chevron-right"></i></span></a>
                    <div class="dropdown-content-3" [ngStyle]="{'left': '100%', 'top': '33%'}">
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229':'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.1' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563':'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.2' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.3' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018':'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.4' | translate}}c</span></a>
                    </div>
                  </div>

                  <div class="mexico-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/sailing':'https://www.mexicotoptours.com/sailing'"
                      mat-menu-item><span>{{'CANCUN-EXCURSIONS.SAILING.TITLE' | translate}} <i
                          class="fa fa-chevron-right"></i></span></a>
                    <div class="dropdown-content-3" [ngStyle]="{'left': '100%', 'top': '44%'}">
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274':'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.SAILING.1' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174':'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.SAILING.2' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861':'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.SAILING.3' | translate}}</span></a>
                    </div>
                  </div>

                  <div class="mexico-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/water-activities':'https://www.mexicotoptours.com/water-activities'"
                      mat-menu-item><span>{{'CANCUN-EXCURSIONS.WATER.TITLE' | translate}} <i
                          class="fa fa-chevron-right"></i></span></a>
                    <div class="dropdown-content-3" [ngStyle]="{'left': '100%', 'top': '55%'}">
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274':'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.1' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174':'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.2' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448':'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.3' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861':'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.4' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.5' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637':'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.6' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738':'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.7' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190':'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.8' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018':'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.9' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272':'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.10' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XENOTES-p467504432':'https://www.mexicotoptours.com/tienda/XENOTES-p467504432'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.11' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-p467759778':'https://www.mexicotoptours.com/tienda/XPLOR-p467759778'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.12' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329':'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.13' | translate}}</span></a>
                    </div>
                  </div>

                  <div class="mexico-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/adventure':'https://www.mexicotoptours.com/adventure'"
                      mat-menu-item><span>{{'CANCUN-EXCURSIONS.ADVENTURE.TITLE' | translate}} <i
                          class="fa fa-chevron-right"></i></span></a>
                    <div class="dropdown-content-3" [ngStyle]="{'left': '100%', 'top': '66%'}">
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ATV-CENOTE-p222022215':'https://www.mexicotoptours.com/tienda/ATV-CENOTE-p222022215'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.1' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174':'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.2' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448':'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.3' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861':'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.4' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563':'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.5' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.6' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637':'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.7' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545':'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.8' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XENOTES-p467504432':'https://www.mexicotoptours.com/tienda/XENOTES-p467504432'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.9' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-p467759778':'https://www.mexicotoptours.com/tienda/XPLOR-p467759778'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.10' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329':'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.11' | translate}}</span></a>
                    </div>
                  </div>

                  <div class="mexico-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/cultural':'https://www.mexicotoptours.com/cultural'"
                      mat-menu-item><span>{{'CANCUN-EXCURSIONS.CULTURAL.TITLE' | translate}} <i
                          class="fa fa-chevron-right"></i></span></a>
                    <div class="dropdown-content-3" [ngStyle]="{'left': '100%', 'top': '78%'}">
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229':'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.CULTURAL.1' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563':'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.CULTURAL.2' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.CULTURAL.3' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738':'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.CULTURAL.4' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190':'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.CULTURAL.5' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018':'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.CULTURAL.6' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006':'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.CULTURAL.7' | translate}}</span></a>
                    </div>
                  </div>

                  <div class="mexico-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/nightlife':'https://www.mexicotoptours.com/nightlife'"
                      mat-menu-item><span>{{'CANCUN-EXCURSIONS.NIGHTLIFE.TITLE' | translate}} <i
                          class="fa fa-chevron-right"></i></span></a>
                    <div class="dropdown-content-3" [ngStyle]="{'left': '100%', 'top': '89%'}">
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COCO-BONGO-CANCUN-REGULAR-PREMIUM-p222118973':'https://www.mexicotoptours.com/tienda/COCO-BONGO-CANCUN-REGULAR-PREMIUM-p222118973'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.NIGHTLIFE.1' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COCO-BONGO-CANCUN-GOLD-MEMBER-FRONT-ROW-p674203455':'https://www.mexicotoptours.com/tienda/COCO-BONGO-CANCUN-GOLD-MEMBER-FRONT-ROW-p674203455'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.NIGHTLIFE.2' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/THE-BONGO-TOUR-p222134249':'https://www.mexicotoptours.com/tienda/THE-BONGO-TOUR-p222134249'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.NIGHTLIFE.3' | translate}} </span></a>
                    </div>
                  </div>

                </div>

              <li class="dropdown" *ngIf="lang == 'es'">
                <a class="dropbtn"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/excursiones-cancun':'https://www.mexicotoptours.com/excursions'">
                  <span>
                    {{'MENU.TRIP' | translate}}
                    <i class="fa fa-chevron-down"></i>
                  </span>
                </a>

                <div class="dropdown-content">

                  <div class="cancun-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/cancun-excursions':'https://www.mexicotoptours.com/cancun-excursions'"
                      mat-menu-item><span> {{'CANCUN-EXCURSIONS.ALL-EXCURSIONS' | translate}} </span></a>
                  </div>

                  <div class="cancun-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/eco-parks':'https://www.mexicotoptours.com/eco-parks'"
                      mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.TITLE' | translate}} <i
                          class="fa fa-chevron-right"></i></span></a>
                    <div class="dropdown-content-2" [ngStyle]="{'left': '100%', 'top': '11%'}">
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/tienda/ATV-CENOTE-p222022215':'https://www.mexicotoptours.com/tienda/ATV-CENOTE-p222022215'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.1' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/tienda/TULUM-&-XEL-HA-p689814236':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.2' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/tienda/RIO-SECRETO-p467742637':'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.3' | translate}}</span></a>
                      <a  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/tienda/XAVAGE-BASIC-p533150545':'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.4' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738':'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.5' | translate}}</span></a>
                      <a  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/tienda/XCARET-BASIC-p689836963':'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.6' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/tienda/XCARET-PLUS-p689836867':'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p689836867'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.7' | translate}}</span></a>
                      <a  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/tienda/XENOTES-p467504432':'https://www.mexicotoptours.com/tienda/XENOTES-p467504432'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.8' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/tienda/XOXIMILCO-p467374006':'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.9' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/tienda/XPLOR-p691067024':'https://www.mexicotoptours.com/tienda/XPLOR-p467759778'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.10' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/tienda/XPLOR-FUEGO-p531703329':'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329'"
                        mat-menu-item><span>{{'CANCUN-EXCURSIONS.ECO-PARKS.11' | translate}}</span></a>
                    </div>
                  </div>

                  <div class="mexico-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/familiar':'https://www.mexicotoptours.com/family'"
                      mat-menu-item><span>{{'CANCUN-EXCURSIONS.FAMILY.TITLE' | translate}} <i
                          class="fa fa-chevron-right"></i></span></a>
                    <div class="dropdown-content-3 family" [ngStyle]="{'left': '100%', 'top': '22%'}">
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274':'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.2' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229':'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.3' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174':'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.4' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448':'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.5' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861':'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.6' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563':'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.7' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.8' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637':'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.9' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545':'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.10' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738':'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.11' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190':'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.12' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018':'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.13' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272':'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.14' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XENOTES-p467504432':'https://www.mexicotoptours.com/tienda/XENOTES-p467504432'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.15' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006':'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.16' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-p467759778':'https://www.mexicotoptours.com/tienda/XPLOR-p467759778'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.17' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329':'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.FAMILY.18' | translate}}</span></a>
                    </div>
                  </div>

                  <div class="mexico-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/archeological':'https://www.mexicotoptours.com/archeological'"
                      mat-menu-item><span>{{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.TITLE' | translate}} <i
                          class="fa fa-chevron-right"></i></span></a>
                    <div class="dropdown-content-3" [ngStyle]="{'left': '100%', 'top': '33%'}">
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229':'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.1' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563':'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.2' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.3' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018':'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.4' | translate}}c</span></a>
                    </div>
                  </div>

                  <div class="mexico-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/sailing':'https://www.mexicotoptours.com/sailing'"
                      mat-menu-item><span>{{'CANCUN-EXCURSIONS.SAILING.TITLE' | translate}} <i
                          class="fa fa-chevron-right"></i></span></a>
                    <div class="dropdown-content-3" [ngStyle]="{'left': '100%', 'top': '44%'}">
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274':'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.SAILING.1' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174':'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.SAILING.2' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861':'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.SAILING.3' | translate}}</span></a>
                    </div>
                  </div>

                  <div class="mexico-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/water-activities':'https://www.mexicotoptours.com/water-activities'"
                      mat-menu-item><span>{{'CANCUN-EXCURSIONS.WATER.TITLE' | translate}} <i
                          class="fa fa-chevron-right"></i></span></a>
                    <div class="dropdown-content-3" [ngStyle]="{'left': '100%', 'top': '55%'}">
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274':'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.1' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174':'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.2' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448':'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.3' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861':'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.4' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.5' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637':'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.6' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738':'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.7' | translate}}8</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190':'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.8' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018':'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.9' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272':'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.10' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XENOTES-p467504432':'https://www.mexicotoptours.com/tienda/XENOTES-p467504432'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.11' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-p467759778':'https://www.mexicotoptours.com/tienda/XPLOR-p467759778'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.12' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329':'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.WATER.13' | translate}}</span></a>
                    </div>
                  </div>

                  <div class="mexico-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/adventure':'https://www.mexicotoptours.com/adventure'"
                      mat-menu-item><span>{{'CANCUN-EXCURSIONS.ADVENTURE.TITLE' | translate}} <i
                          class="fa fa-chevron-right"></i></span></a>
                    <div class="dropdown-content-3" [ngStyle]="{'left': '100%', 'top': '66%'}">
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ATV-CENOTE-p222022215':'https://www.mexicotoptours.com/tienda/ATV-CENOTE-p222022215'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.1' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174':'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.2' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448':'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.3' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861':'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.4' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563':'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.5' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.6' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637':'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.7' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545':'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.8' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XENOTES-p467504432':'https://www.mexicotoptours.com/tienda/XENOTES-p467504432'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.9' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-p467759778':'https://www.mexicotoptours.com/tienda/XPLOR-p467759778'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.10' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329':'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.ADVENTURE.11' | translate}}</span></a>
                    </div>
                  </div>

                  <div class="mexico-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/cultural':'https://www.mexicotoptours.com/cultural'"
                      mat-menu-item><span>{{'CANCUN-EXCURSIONS.CULTURAL.TITLE' | translate}} <i
                          class="fa fa-chevron-right"></i></span></a>
                    <div class="dropdown-content-3" [ngStyle]="{'left': '100%', 'top': '78%'}">
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229':'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.CULTURAL.1' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563':'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.CULTURAL.2' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.CULTURAL.3' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738':'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.CULTURAL.4' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190':'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.CULTURAL.5' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018':'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.CULTURAL.6' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006':'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.CULTURAL.7' | translate}}</span></a>
                    </div>
                  </div>

                  <div class="mexico-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/nightlife':'https://www.mexicotoptours.com/nightlife'"
                      mat-menu-item><span>{{'CANCUN-EXCURSIONS.NIGHTLIFE.TITLE' | translate}} <i
                          class="fa fa-chevron-right"></i></span></a>
                    <div class="dropdown-content-3" [ngStyle]="{'left': '100%', 'top': '89%'}">
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COCO-BONGO-CANCUN-REGULAR-PREMIUM-p222118973':'https://www.mexicotoptours.com/tienda/COCO-BONGO-CANCUN-REGULAR-PREMIUM-p222118973'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.NIGHTLIFE.1' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COCO-BONGO-CANCUN-GOLD-MEMBER-FRONT-ROW-p674203455':'https://www.mexicotoptours.com/tienda/COCO-BONGO-CANCUN-GOLD-MEMBER-FRONT-ROW-p674203455'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.NIGHTLIFE.2' | translate}} </span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/THE-BONGO-TOUR-p222134249':'https://www.mexicotoptours.com/tienda/THE-BONGO-TOUR-p222134249'"
                        mat-menu-item> <span>{{'CANCUN-EXCURSIONS.NIGHTLIFE.3' | translate}} </span></a>
                    </div>
                  </div>

                </div>

              </li>

              <!-- CIRCUITS -->
              <li class="dropdown">
                <a class="dropbtn"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/circuitos':'https://www.mexicotoptours.com/circuits'">
                  <span>
                    {{'MENU.TOURS' | translate}}
                    <i class="fa fa-chevron-down"></i>
                  </span>
                </a>
                <div class="dropdown-content">
                  <div class="cancun-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/circuitos-cancun':'https://www.mexicotoptours.com/cancun-circuits'"
                      mat-menu-item><span>{{'TOURS.CANCUN_TOURS' |
                        translate}} <i class="fa fa-chevron-right"></i></span></a>
                    <div class="dropdown-content-2" [ngStyle]="{'right': lang == 'es' ? '-185px' : '-175px' }">
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/la-peninsula-de-yucatan':'https://www.mexicotoptours.com/the-yucatan-peninsula'"
                        mat-menu-item><span>{{'TOURS.YUCATAN_PENINSULA' | translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/maravillas-de-yucatan':'https://www.mexicotoptours.com/wonders-of-yucatan'"
                        mat-menu-item><span>{{'TOURS.WONDERS_OF_YUCATAN' |
                          translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/el-mundo-maya':'https://www.mexicotoptours.com/the-mayan-world'"
                        mat-menu-item><span>{{'TOURS.THE_MAYAN_WORLD' |
                          translate}}</span></a>
                      <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/aventura-maya':'https://www.mexicotoptours.com/mayan-adventure'"
                        mat-menu-item><span>{{'TOURS.MAYAN_ADVENTURE' |
                          translate}}</span></a>
                    </div>
                  </div>
                  <div class="mexico-trigger">
                    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/circuitos-cdmx':'https://www.mexicotoptours.com/mexico-city'"
                      mat-menu-item><span>{{'TOURS.MEXICO_CITY_TOURS' |
                        translate}}<i class="fa fa-chevron-right"></i></span></a>
                    <div class="dropdown-content-3" [ngStyle]="{'right': lang == 'es' ? '-291px' : '-250px' }">
                      <a mat-menu-item
                        [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/el-camino-del-sur':'https://www.mexicotoptours.com/south-road'"><span>{{'TOURS.THE_SUN_ROAD'
                          | translate}}</span></a>
                      <a mat-menu-item
                        [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/cdmx-peninsula-de-yucatan':'https://www.mexicotoptours.com/mexico-city-and-yucatan-peninsula'"><span>{{'TOURS.MEXICO_CITY_AND_YUCATAN_PENINSULA'
                          | translate}}</span></a>
                      <a mat-menu-item
                        [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/mexico-breve':'https://www.mexicotoptours.com/brief-mexico'"><span>{{'TOURS.BRIEF_MEXICO'
                          | translate}}</span></a>
                      <a mat-menu-item
                        [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/la-ruta-de-la-independencia':'https://www.mexicotoptours.com/the-independence-route'"><span>{{'TOURS.THE_ROUTE_OF_THE_INDEPENDENCE'
                          | translate}}</span></a>
                      <a mat-menu-item
                        [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/contrastes-de-mexico':'https://www.mexicotoptours.com/mexico-contrast'"><span>{{'TOURS.MEXICO_CONTRASTS'
                          | translate}}</span></a>
                      <a mat-menu-item
                        [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/mexico-clasico':'https://www.mexicotoptours.com/classic-mexico'"><span>{{'TOURS.CLASSIC_MEXICO'
                          | translate}}</span></a>
                    </div>
                  </div>
                </div>
              </li>

              <!-- TRANSFERS -->
              <li>
                <a
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/traslados-contacto':'https://www.mexicotoptours.com/transfer-contact'">
                  <span>
                    {{'MENU.TRANSFERS' | translate}}
                  </span>
                </a>
              </li>

              <!-- AGENCIES -->
              <li>
                <a
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/agencias':'https://www.mexicotoptours.com/agencies'">
                  <span>
                    {{'MENU.AGENCIES' | translate}}
                  </span>
                </a>
              </li>

              <!-- CONTACT US -->
              <li>
                <a
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/contacto':'https://www.mexicotoptours.com/contact'">
                  <span>
                    {{'MENU.CONTACT_US' | translate}}
                  </span>
                </a>
              </li>


              <!-- <li>
                                <a [matMenuTriggerFor]="transfersList" mat-flat-button>
                                    <span>
                                        {{'MENU.TRANSFERS' | translate}}
                                        <i class="fa fa-chevron-down"></i>
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a [matMenuTriggerFor]="tourslist" mat-flat-button>
                                    <span>
                                        {{'MENU.TOURS' | translate}}
                                        <i class="fa fa-chevron-down"></i>
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a [matMenuTriggerFor]="tlist" mat-flat-button>
                                    <span>
                                        {{'MENU.CIRCUITS' | translate}}
                                        <i class="fa fa-chevron-down"></i>
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.mexicotoptours.com/estancias-en-riviera-maya" mat-flat-button>
                                    <span>
                                        {{'MENU.EXPERIENCE' | translate}}
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.mexicotoptours.com/partners" mat-flat-button>
                                    <span>
                                        {{'MENU.PARTNERS' | translate}}
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.mexicotoptours.com/contact" mat-flat-button>
                                    <span>
                                        {{'MENU.CONTACT_US' | translate}}
                                    </span>
                                </a>
                            </li> -->

            </ul>
          </nav>
        </div>

        <div class="lang">

          <div class="dropdown">

            <button>
              <img [src]="lang == 'en' ? 'assets/images/us.png' : 'assets/images/mx.png'" alt="" width="25">
              {{lang == 'es' ? 'Español' : 'English'}}
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>

            <div class="dropdown-content">
              <a *ngIf="lang == 'en'" href="javascript:void(0)" (click)="change('es')">
                <img src="assets/images/mx.png" alt="" width="25"> Español </a>
              <a *ngIf="lang == 'es'" href="javascript:void(0)" (click)="change('en')">
                <img src="assets/images/us.png" alt="" width="25"> English </a>

            </div>

          </div>



          <!-- <div class="flag">
            <a (click)="change('en')" *ngIf="lang == 'es'">
              <img src="assets/images/mx.png" alt="" width="25">
              <p>Español</p>
              <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
            <a (click)="change('es')" *ngIf="lang == 'en'">
              <img src="assets/images/us.png" alt="" width="25">
              <p>English</p>
              <mat-icon>keyboard_arrow_down</mat-icon>
            </a>
          </div> -->


        </div>


      </div>
    </div>
  </div>
</div>

<mat-menu #tlist="matMenu">
  <div class="matmenulist">
    <nav>
      <ul>
        <li>
          <a href="https://www.mexicotoptours.com/cancun">
            <span>
              {{'MENU.CANCUN' | translate}}
            </span>
          </a>
        </li>
        <li>
          <a href="https://www.mexicotoptours.com/mexico-city">
            <span>
              {{'MENU.MEXICOCITY' | translate}}
            </span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</mat-menu>

<mat-menu #transfersList="matMenu">
  <div class="matmenulist">
    <nav>
      <ul>
        <li>
          <a href="https://transfers.mexicotoptours.com/eng/home">
            <span>
              {{'MENU.CANCUN' | translate}}
            </span>
          </a>
        </li>
        <li>
          <a href="https://transfers.mexicotoptours.com/eng/home">
            <span>
              {{'MENU.MEXICOCITY' | translate}}
            </span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</mat-menu>

<mat-menu #tripList="matMenu">
  <div class="matmenulist">
    <nav>
      <ul>
        <li>
          <a mat-menu-item href="https://www.mexicotoptours.com/cancun-private-trips">
            <span class="mat-menu-txt">
              {{'TRIPS.CANCUN_PRIVATE_TRIPS' | translate}}
            </span>
          </a>
        </li>
        <li>
          <a mat-menu-item href="https://www.mexicotoptours.com/cancun-regular-trips">
            <span class="mat-menu-txt">
              {{'TRIPS.CANCUN_REGULAR_TRIPS' | translate}}
            </span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</mat-menu>

<mat-menu #tourList="matMenu">
  <div class="matmenulist">
    <nav>
      <ul>
        <li>
          <a href="https://www.mexicotoptours.com/cancun-tours" mat-menu-item
            [matMenuTriggerFor]="cancunTours"><span>{{'TOURS.CANCUN_TOURS' | translate}}</span></a>
        </li>
        <li>
          <a href="https://www.mexicotoptours.com/mexico-city" mat-menu-item
            [mat-menu-trigger-for]="mexicoTours"><span>{{'TOURS.MEXICO_CITY_TOURS' | translate}}</span></a>
        </li>
      </ul>
    </nav>
  </div>
</mat-menu>

<mat-menu #cancunTours="matMenu">
  <div class="matmenulist">
    <nav>
      <ul>
        <li>
          <a href="https://www.mexicotoptours.com/the-yucatan-peninsula" mat-menu-item><span>{{'TOURS.YUCATAN_PENINSULA'
              | translate}}</span></a>
        </li>
        <li>
          <a href="https://www.mexicotoptours.com/wonders-of-yucatan" mat-menu-item><span>{{'TOURS.WONDERS_OF_YUCATAN' |
              translate}}</span></a>
        </li>
        <li>
          <a href="https://www.mexicotoptours.com/the-mayan-world" mat-menu-item><span>{{'TOURS.THE_MAYAN_WORLD' |
              translate}}</span></a>
        </li>
        <li>
          <a href="https://www.mexicotoptours.com/mayan-adventure" mat-menu-item><span>{{'TOURS.MAYAN_ADVENTURE' |
              translate}}</span></a>
        </li>
      </ul>
    </nav>
  </div>
</mat-menu>

<mat-menu #mexicoTours="matMenu">
  <div class="matmenulist">
    <nav>
      <ul>
        <li>
          <a mat-menu-item href="https://www.mexicotoptours.com/the-sun-road"><span>{{'TOURS.THE_SUN_ROAD' |
              translate}}</span></a>
        </li>
        <li>
          <a mat-menu-item
            href="https://www.mexicotoptours.com/mexico-city-and-yucatan-peninsula"><span>{{'TOURS.MEXICO_CITY_AND_YUCATAN_PENINSULA'
              | translate}}</span></a>
        </li>
        <li>
          <a mat-menu-item href="https://www.mexicotoptours.com/brief-mexico"><span>{{'TOURS.BRIEF_MEXICO' |
              translate}}</span></a>
        </li>
        <li>
          <a mat-menu-item
            href="https://www.mexicotoptours.com/the-independence-route"><span>{{'TOURS.THE_ROUTE_OF_THE_INDEPENDENCE'
              | translate}}</span></a>
        </li>
        <li>
          <a mat-menu-item
            href="https://www.mexicotoptours.com/mexicos-contrast-information"><span>{{'TOURS.MEXICO_CONTRASTS'
              | translate}}</span></a>
        </li>
        <li>
          <a mat-menu-item href="https://www.mexicotoptours.com/clasic-mexico"><span>{{'TOURS.CLASSIC_MEXICO'
              | translate}}</span></a>
        </li>
      </ul>
    </nav>
  </div>

</mat-menu>

<mat-menu #tourslist="matMenu">
  <div class="matmenulist">
    <nav>
      <ul>
        <li>
          <a href="https://www.mexicotoptours.com/tours-cancun">
            <span>
              {{'MENU.CANCUNTOURS' | translate}}
            </span>
          </a>
        </li>
        <li>
          <a href="https://www.mexicotoptours.com/mexico">
            <span>
              {{'MENU.MEXICOTOURS' | translate}}
            </span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</mat-menu>

<div class="header-mobile">
  <div class="col button">
    <mat-icon aria-hidden="false" aria-label="Example home icon" (click)="changeToggle()">menu</mat-icon>
  </div>
  <div class="col logo">
    <a href="https://www.mexicotoptours.com/">
      <img src="assets/images/logo.webp" alt="">
    </a>
  </div>
  <!-- <div class="col"></div> -->
  <div class="col lang">
    <div class="flag">
      <a (click)="change('en')" *ngIf="lang == 'es'">
        <img src="assets/images/mx.png" alt="" width="25">
      </a>
      <a (click)="change('es')" *ngIf="lang == 'en'">
        <img src="assets/images/us.png" alt="" width="25">
      </a>
    </div>
  </div>
  <!-- <div class="col phone">
        <a href="tel:555-555-5555" target="_blank">
            <mat-icon aria-hidden="false" aria-label="Example home icon">call</mat-icon>
        </a>
    </div> -->
</div>

<div *ngIf="toggle == true" class="close-btn">
  <mat-icon aria-hidden="false" (click)="changeToggle()" aria-label="Example home icon">close</mat-icon>
</div>

<div class="menu-mobile" [ngClass]="{'open': toggle}">
  <div class="container-menu">
    <ul class="mt">
      <li class="link">
        <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx':'https://www.mexicotoptours.com/'"
          (click)="changeToggle()">
          <span>
            {{'MENU.HOME' | translate}}
          </span>
        </a>
      </li>


      <li class="submenu link">
        <div>
          <p>
            <a style="color: inherit;"
              [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/excursiones-cancun':'https://www.mexicotoptours.com/excursions'">
              {{'MENU.TRIP' | translate}}</a>
            <i (click)="showExcursions = !showExcursions"
              [ngStyle]="{'transform': showExcursions ? 'rotate(180deg)' : ''}" class="fa fa-chevron-down"></i>
          </p>
          <ul class="submenu-lvl1" *ngIf="showExcursions">
            <li class="tour">
              <p>
                <a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/cancun-excursions':'https://www.mexicotoptours.com/cancun-excursions'">
                  {{'CANCUN-EXCURSIONS.ALL-EXCURSIONS' | translate}} </a>
              </p>

            </li>


            <li class="tour">
              <p><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/ecoparques':'https://www.mexicotoptours.com/eco-parks'">
                  {{'CANCUN-EXCURSIONS.ECO-PARKS.TITLE' | translate}} </a> <i
                  (click)="showCancunTours = !showCancunTours" [ngClass]=" showCancunTours ? 'activeMenu' : ''"
                  class="fa fa-chevron-down"></i></p>
              <ul class="submenu-lvl2" *ngIf="showCancunTours">
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ATV-CENOTE-p222022215':'https://www.mexicotoptours.com/tienda/ATV-CENOTE-p222022215'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.1' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.2' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637':'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.3' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545':'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.4' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738':'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.5' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190':'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.6' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272':'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.7' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XENOTES-p467504432':'https://www.mexicotoptours.com/tienda/XENOTES-p467504432'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.8' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006':'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.9' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-p467759778':'https://www.mexicotoptours.com/tienda/XPLOR-p467759778'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.10' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329':'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.11' | translate}}</span>
                  </a>
                </li>


              </ul>
            </li>
            <li class="tour">
              <p><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/family':'https://www.mexicotoptours.com/family'">
                  {{'CANCUN-EXCURSIONS.FAMILY.TITLE' | translate}}</a> <i (click)="showMexicoTours = !showMexicoTours"
                  [ngClass]=" showMexicoTours ? 'activeMenu' : ''" class="fa fa-chevron-down"></i></p>
              <ul class="submenu-lvl2" *ngIf="showMexicoTours">
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274':'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.2' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229':'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.3' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174':'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.4' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448':'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.5' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861':'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.6' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563':'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.7' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.8' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637':'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.9' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545':'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.10' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738':'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.11' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190':'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.12' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018':'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.13' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272':'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.14' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XENOTES-p467504432':'https://www.mexicotoptours.com/tienda/XENOTES-p467504432'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.15' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006':'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.16' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-p467759778':'https://www.mexicotoptours.com/tienda/XPLOR-p467759778'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.17' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329':'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.18' | translate}}</span>
                  </a>
                </li>


              </ul>
            </li>

            <li class="tour">
              <p><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/archeological':'https://www.mexicotoptours.com/archeological'">
                  {{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.TITLE' | translate}} </a> <i
                  (click)="showExcursions1 = !showExcursions1" [ngClass]=" showExcursions1 ? 'activeMenu' : ''"
                  class="fa fa-chevron-down"></i></p>
              <ul class="submenu-lvl2" *ngIf="showExcursions1">
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229':'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229'">
                    <span>{{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.1' | translate}}</span></a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563':'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563'">
                    <span>{{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.2' | translate}}</span></a>

                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'">
                    <span>{{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.3' | translate}}</span></a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018':'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018'">
                    <span>{{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.4' | translate}}c</span></a>
                </li>
              </ul>
            </li>


            <li class="tour">
              <p><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/sailing':'https://www.mexicotoptours.com/sailing'">
                  {{'CANCUN-EXCURSIONS.SAILING.TITLE' | translate}} </a> <i (click)="showExcursions2 = !showExcursions2"
                  [ngClass]=" showExcursions2 ? 'activeMenu' : ''" class="fa fa-chevron-down"></i></p>
              <ul class="submenu-lvl2" *ngIf="showExcursions2">
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274':'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274'">
                    <span>{{'CANCUN-EXCURSIONS.SAILING.1' | translate}}</span></a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174':'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174'">
                    <span>{{'CANCUN-EXCURSIONS.SAILING.2' | translate}}</span></a>

                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861':'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861'">
                    <span>{{'CANCUN-EXCURSIONS.SAILING.3' | translate}}</span></a>
                </li>

              </ul>
            </li>


            <li class="tour">
              <p><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/water-activities':'https://www.mexicotoptours.com/water-activities'">
                  {{'CANCUN-EXCURSIONS.WATER.TITLE' | translate}} </a> <i (click)="showExcursions3 = !showExcursions3"
                  [ngClass]=" showExcursions3 ? 'activeMenu' : ''" class="fa fa-chevron-down"></i></p>
              <ul class="submenu-lvl2" *ngIf="showExcursions3">
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274':'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.1' | translate}}</span></a>
                </li>
                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174':'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.2' | translate}}</span></a>
                </li>
                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448':'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.3' | translate}}</span></a>
                </li>
                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861':'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.4' | translate}}</span></a>
                </li>
                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.5' | translate}}</span></a>
                </li>
                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637':'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.6' | translate}}</span></a>
                </li>
                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738':'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.7' | translate}}8</span></a>
                </li>
                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190':'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.8' | translate}}</span></a>
                </li>
                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018':'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.9' | translate}}</span></a>
                </li>

                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272':'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.10' | translate}}</span></a>
                </li>

                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XENOTES-p467504432':'https://www.mexicotoptours.com/tienda/XENOTES-p467504432'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.11' | translate}}</span></a>
                </li>

                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-p467759778':'https://www.mexicotoptours.com/tienda/XPLOR-p467759778'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.12' | translate}}</span></a>
                </li>

                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329':'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.13' | translate}}</span></a>
                </li>

              </ul>
            </li>


            <li class="tour">
              <p><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/adventure':'https://www.mexicotoptours.com/adventure'">
                  {{'CANCUN-EXCURSIONS.ADVENTURE.TITLE' | translate}} </a> <i
                  (click)="showExcursions4 = !showExcursions4" [ngClass]=" showExcursions4 ? 'activeMenu' : ''"
                  class="fa fa-chevron-down"></i></p>
              <ul class="submenu-lvl2" *ngIf="showExcursions4">
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ATV-CENOTE-p222022215' : 'https://www.mexicotoptours.com/tienda/ATV-CENOTE-p222022215'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.1' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174' : 'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.2' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448' : 'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.3' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861' : 'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.4' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563' : 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.5' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238' : 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.6' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637' : 'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.7' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545' : 'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.8' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XENOTES-p467504432' : 'https://www.mexicotoptours.com/tienda/XENOTES-p467504432'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.9' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-p467759778' : 'https://www.mexicotoptours.com/tienda/XPLOR-p467759778'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.10' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329' : 'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.11' | translate }}</span></a></li>
              </ul>
            </li>

            <li class="tour">
              <p><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/cultural':'https://www.mexicotoptours.com/cultural'">
                  {{'CANCUN-EXCURSIONS.CULTURAL.TITLE' | translate}} </a> <i
                  (click)="showExcursions5 = !showExcursions5" [ngClass]=" showExcursions5 ? 'activeMenu' : ''"
                  class="fa fa-chevron-down"></i></p>
              <ul class="submenu-lvl2" *ngIf="showExcursions5">
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229' : 'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229'">
                    <span> {{'CANCUN-EXCURSIONS.CULTURAL.1' | translate }} </span> </a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563' : 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563'">
                    <span> {{'CANCUN-EXCURSIONS.CULTURAL.2' | translate }} </span> </a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238' : 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'">
                    <span> {{'CANCUN-EXCURSIONS.CULTURAL.3' | translate }} </span> </a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738' : 'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738'">
                    <span> {{'CANCUN-EXCURSIONS.CULTURAL.4' | translate }} </span> </a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190' : 'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190'">
                    <span> {{'CANCUN-EXCURSIONS.CULTURAL.5' | translate }} </span> </a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018' : 'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018'">
                    <span> {{'CANCUN-EXCURSIONS.CULTURAL.6' | translate }} </span> </a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006' : 'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006'">
                    <span> {{'CANCUN-EXCURSIONS.CULTURAL.7' | translate }} </span> </a></li>
              </ul>
            </li>


            <li class="tour">
              <p><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/nightlife':'https://www.mexicotoptours.com/nightlife'">
                  {{'CANCUN-EXCURSIONS.NIGHTLIFE.TITLE' | translate}} </a> <i
                  (click)="showExcursions6 = !showExcursions6" [ngClass]=" showExcursions6 ? 'activeMenu' : ''"
                  class="fa fa-chevron-down"></i></p>
              <ul class="submenu-lvl2" *ngIf="showExcursions6">
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COCO-BONGO-CANCUN-REGULAR-PREMIUM-p222118973':'https://www.mexicotoptours.com/tienda/COCO-BONGO-CANCUN-REGULAR-PREMIUM-p222118973'">
                    <span>{{'CANCUN-EXCURSIONS.NIGHTLIFE.1' | translate}} </span></a>
                </li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COCO-BONGO-CANCUN-GOLD-MEMBER-FRONT-ROW-p674203455':'https://www.mexicotoptours.com/tienda/COCO-BONGO-CANCUN-GOLD-MEMBER-FRONT-ROW-p674203455'">
                    <span>{{'CANCUN-EXCURSIONS.NIGHTLIFE.2' | translate}} </span></a>
                </li>
                <li> <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/THE-BONGO-TOUR-p222134249':'https://www.mexicotoptours.com/tienda/THE-BONGO-TOUR-p222134249'">
                    <span>{{'CANCUN-EXCURSIONS.NIGHTLIFE.3' | translate}} </span></a>
                </li>


              </ul>
            </li>


          </ul>
        </div>
      </li>



      <li class="submenu link">
        <div>
          <p>
            <a style="color: inherit;"
              [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/circuitos':'https://www.mexicotoptours.com/circuits'">
              {{'MENU.TOURS' | translate}}</a>
            <i (click)="showToursMenu = !showToursMenu" [ngStyle]="{'transform': showToursMenu ? 'rotate(180deg)' : ''}"
              class="fa fa-chevron-down"></i>
          </p>
          <ul *ngIf="showToursMenu == true">
            <li class="submenu-item-lvl1">
              <p>
                <a
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/circuitos-cancun':'https://www.mexicotoptours.com/cancun-circuits'">
                  {{'TOURS.CANCUN_TOURS' | translate}}
                </a>
                <i (click)="showCancunTours = !showCancunTours"
                  [ngStyle]="{'transform': showCancunTours ? 'rotate(180deg)' : ''}" class="fa fa-chevron-down"></i>
              </p>
              <ul *ngIf="showCancunTours">
                <li class="submenu-item-lvl2"><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/la-peninsula-de-yucatan':'https://www.mexicotoptours.com/the-yucatan-peninsula'"><span>
                      {{'TOURS.YUCATAN_PENINSULA' | translate}}
                    </span></a></li>
                <li class="submenu-item-lvl2"><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/maravillas-de-yucatan':'https://www.mexicotoptours.com/wonders-of-yucatan'"><span>
                      {{'TOURS.WONDERS_OF_YUCATAN' | translate}}
                    </span></a></li>
                <li class="submenu-item-lvl2"><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/el-mundo-maya':'https://www.mexicotoptours.com/the-mayan-world'"><span>
                      {{'TOURS.THE_MAYAN_WORLD' | translate}}
                    </span></a></li>
                <li class="submenu-item-lvl2"><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/aventura-maya':'https://www.mexicotoptours.com/mayan-adventure'"><span>
                      {{'TOURS.MAYAN_ADVENTURE' | translate}}
                    </span></a></li>
              </ul>
            </li>
            <li class="submenu-item-lvl1">
              <p>
                <a
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/circuitos-cdmx':'https://www.mexicotoptours.com/mexico-city'">
                  {{'TOURS.MEXICO_CITY_TOURS' | translate}}
                </a>
                <i (click)="showMexicoTours = !showMexicoTours"
                  [ngStyle]="{'transform': showMexicoTours ? 'rotate(180deg)' : ''}" class="fa fa-chevron-down"></i>
              </p>
              <ul *ngIf="showMexicoTours">
                <li class="submenu-item-lvl2"><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/el-camino-del-sur':'https://www.mexicotoptours.com/south-road'"><span>
                      {{'TOURS.THE_SUN_ROAD' | translate}}
                    </span></a>
                </li>
                <li class="submenu-item-lvl2"><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/cdmx-peninsula-de-yucatan':'https://www.mexicotoptours.com/mexico-city-and-yucatan-peninsula'"><span>
                      {{'TOURS.MEXICO_CITY_AND_YUCATAN_PENINSULA' | translate}}
                    </span></a></li>
                <li class="submenu-item-lvl2"><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/mexico-breve':'https://www.mexicotoptours.com/brief-mexico'"><span>
                      {{'TOURS.BRIEF_MEXICO' | translate}}
                    </span></a>
                </li>
                <li class="submenu-item-lvl2"><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/la-ruta-de-la-independencia':'https://www.mexicotoptours.com/the-independence-route'"><span>
                      {{'TOURS.THE_ROUTE_OF_THE_INDEPENDENCE' | translate}}
                    </span></a></li>
                <li class="submenu-item-lvl2"><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/contrastes-de-mexico':'https://www.mexicotoptours.com/mexico-contrast'"><span>
                      {{'TOURS.MEXICO_CONTRASTS' | translate}}
                    </span></a></li>
                <li class="submenu-item-lvl2"><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/mexico-clasico':'https://www.mexicotoptours.com/classic-mexico'"><span>
                      {{'TOURS.CLASSIC_MEXICO' | translate}}
                    </span></a></li>
              </ul>
            </li>
          </ul>
        </div>
      </li>

      <li class="link">
        <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/traslados-contacto':'https://www.mexicotoptours.com/transfer-contact'"
          (click)="changeToggle()">
          <span>
            {{'MENU.TRANSFERS' | translate}}
          </span>
        </a>
      </li>


      <li class="link">
        <a
          [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/agencias':'https://www.mexicotoptours.com/agencies'">
          <span>
            {{'MENU.AGENCIES' | translate}}
          </span>
        </a>
      </li>

      <li class="link">
        <a
          [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/contacto':'https://www.mexicotoptours.com/contact'">
          <span>
            {{'MENU.CONTACT_US' | translate}}
          </span>
        </a>
      </li>

      <li>
        <div class="col lang">
          <div class="flag">
            <a (click)="change('en')" *ngIf="lang == 'es'">
              <img src="assets/images/mx.png" alt="" width="25">
            </a>
            <a (click)="change('es')" *ngIf="lang == 'en'">
              <img src="assets/images/us.png" alt="" width="25">
            </a>
          </div>
        </div>
      </li>

      <!-- *: OLD -->
      <!-- <li>
                <a [routerLink]="['/home']" mat-button (click)="changeToggle()">
                    <span>
                        {{'MENU.TRANSFERS' | translate}}
                    </span>
                </a>
            </li> -->
      <!-- <li>
                <button mat-button [matMenuTriggerFor]="menu">{{'MENU.CIRCUITS' | translate}}</button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item>
                        <a href="https://www.mexicotoptours.com/cancun">
                            <span>
                                {{'MENU.CANCUN' | translate}}
                            </span>
                        </a>
                    </button>
                    <button mat-menu-item>
                        <a href="https://www.mexicotoptours.com/mexico-city">
                            <span>
                                {{'MENU.MEXICOCITY' | translate}}
                            </span>
                        </a>
                    </button>
                </mat-menu>
            </li>
            <li>
                <button mat-button [matMenuTriggerFor]="menu">{{'MENU.TOURS' | translate}}</button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item>
                        <a href="https://www.mexicotoptours.com/tours-cancun">
                            <span>
                                {{'MENU.CANCUNTOURS' | translate}}
                            </span>
                        </a>
                    </button>
                    <button mat-menu-item>
                        <a href="https://www.mexicotoptours.com/mexico">
                            <span>
                                {{'MENU.MEXICOTOURS' | translate}}
                            </span>
                        </a>
                    </button>
                </mat-menu>
            </li>
            <li>
                <a href="https://www.mexicotoptours.com/estancias-en-riviera-maya" mat-button>
                    <span>
                        {{'MENU.EXPERIENCE' | translate}}
                    </span>
                </a>
            </li>
            <li>
                <a href="https://www.mexicotoptours.com/testimonies" mat-button>
                    <span>
                        {{'MENU.TESTIMONIALS' | translate}}
                    </span>
                </a>
            </li>
            <li style="margin-top: 0.5rem;">
                <a href="https://www.mexicotoptours.com/about" mat-button>
                    <span>
                        {{'MENU.ABOUT_US' | translate}}
                    </span>
                </a>
            </li>
            <li>
                <a href="https://www.mexicotoptours.com/blogf208e0e1" mat-button>
                    <span>
                        {{'MENU.BLOG' | translate}}
                    </span>
                </a>
            </li>
            <li>
                <a href="https://www.mexicotoptours.com/partners" mat-button>
                    <span>
                        {{'MENU.PARTNERS' | translate}}
                    </span>
                </a>
            </li>
            <li>
                <a href="https://www.mexicotoptours.com/contact" mat-button>
                    <span>
                        {{'MENU.CONTACT_US' | translate}}
                    </span>
                </a>
            </li>
            <li>
                <a (click)="changeToggle()" class="social" href="mailto:grupos@mexicotoptours.com" target="_blank">
                    <img src="assets/images/email.png" alt="" width="20">
                </a>
                <a (click)="changeToggle()" class="social" href="https://web.whatsapp.com/send?phone=529988874760"
                    target="_blank">
                    <img src="assets/images/whatsapp.png" alt="" width="20">
                </a>
            </li> -->
    </ul>

  </div>
  <div class="contact-btn-container">
    <button class="contact-btn">
      <a
        [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/contacto':'https://www.mexicotoptours.com/contact'"><span>{{'BTN_3'
          | translate}}</span></a>
    </button>
  </div>
</div>
