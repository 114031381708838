import { Component, OnInit, HostListener  } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Logs } from 'selenium-webdriver';
import { AgencyService } from 'src/app/services/agencies/agency.service';
import { HotelsService } from 'src/app/services/hotels/hotels.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home-form',
  templateUrl: './home-form.component.html',
  styleUrls: ['./home-form.component.scss']
})
export class HomeFormComponent implements OnInit {
  public selectedTrip = '1'

  public citySelect: string = '';
  public transfers: any = [];
  public lang: string;
  private subs: Subscription

  public placeSelected: any;
  public hotels: Array<any>;
  public hotelSelected: string = '';
  public otherHotel = false;
  public myControlHotel = new FormControl();
  filteredOptions: Observable<any[]>;

  public addForm: FormGroup;
  public hotelsTransfers = [];
  public hotelsControl = new FormControl();
  public filteredHotels: Observable<string[]>;

  public currentStep = 1;
  public resultZone;

  public resultZones: Array<any> = [];

  public transporter = "https://firebasestorage.googleapis.com/v0/b/mexico-top-tours.appspot.com/o/Volkswagen%20transporter%20(1).png?alt=media&token=7bf9caaa-acf6-49fe-b809-74c05b39ad67"
  public toyota = "https://firebasestorage.googleapis.com/v0/b/mexico-top-tours.appspot.com/o/Toyota%20hiace.png?alt=media&token=ac6770ee-1851-446a-9cd6-490f2d8fcfa6"
  public transporters = "https://firebasestorage.googleapis.com/v0/b/guerreros-del-voto.appspot.com/o/camionetas-top-tours%2FDos-Volkswagen.png?alt=media&token=552787de-5eac-47dd-a359-e9a00d5089c4"
  public toyotas = "https://firebasestorage.googleapis.com/v0/b/guerreros-del-voto.appspot.com/o/camionetas-top-tours%2FDos-Toyota%20hiace%20(1).png?alt=media&token=ccc97e72-b10e-4b2e-b430-89c819a9beec"

  isDesktop: boolean = true;
  public adultsArray = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18]
  public kidsArray = [0,1,2,3,4,5]

  constructor(
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private hotelService: HotelsService,
    private agencyService: AgencyService,
    private router: Router
  ) {
    this.addForm = this.formBuilder.group({
      city: ['cancun', Validators.required],
      tripType: ['1', Validators.required],
      adults: [1,[ Validators.required, Validators.max(18)]],
      kids: [0, [Validators.required, Validators.max(5)]],
      agencyId: []
    });
    this.isDesktop = window.innerWidth >= 480;
  }

  ngOnInit(): void {
    console.log('init');
    
    this.lang = this.translate.defaultLang;
    this.subs = this.translate.onLangChange.subscribe(lang => {
      this.lang = lang.lang;
    })
    this.findHotel('cancun')
    window.localStorage.removeItem('flightInfo');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isDesktop = window.innerWidth >= 480; // Puedes ajustar el valor 768 según tus necesidades
  }

  _filterHotels(value: string): string[] {
    if(value.length >= 3 ){
      const filterValue = value.toLowerCase();
      return this.hotelsTransfers.filter(option => option.nameEs.toLowerCase().includes(filterValue));
    }
  }

  findHotel(e: any) {
    // console.log('e', e);

    this.hotelService.searchHotelTrasnfers(e).subscribe(res => {
      this.hotelsTransfers = res;
      this.filteredHotels = this.hotelsControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterHotels(value))
      );
    })
  }

  async searchTrip() {
    if (this.hotelsControl.value == null) {
      this.hotelsControl.markAllAsTouched();
      Swal.fire({
        icon: 'info',
        title: this.lang == 'es' ? 'Asegúrate de llenar todos los campos' : 'Make sure you fill in all the fields',
        text: '',
        confirmButtonText: this.lang == 'es' ? 'Aceptar' : 'Ok',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonColor: '#e5514e',
      })
      return;
    }

    window.scrollTo(0, 0)
    let data = {
      hotel: this.hotelsControl.value,
      type: parseInt(this.addForm.value.tripType),
      persons: this.addForm.value.adults + this.addForm.value.kids,
      price: 0,
      subtitle: '',
      adults: this.addForm.value.adults,
      kids: this.addForm.value.kids,
      image: '',
      title: '',
      agencyId: this.addForm.value.agencyId || ''
    }

    if (data.adults <= 0) {
      Swal.fire({
        icon: 'info',
        title: this.lang == 'es' ? 'Por favor seleccione un número de adultos' : 'Please select a number of adults',
        text: '',
        confirmButtonText: this.lang == 'es' ? 'Aceptar' : 'Ok',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonColor: '#e5514e',
      })
      return
    }

    if (data.persons <= 0) {
      Swal.fire({
        icon: 'info',
        title: this.lang == 'es' ? 'Debes seleccionar al menos una persona' : 'You must select at least one person',
        text: '',
        confirmButtonText: this.lang == 'es' ? 'Aceptar' : 'Ok',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonColor: '#e5514e',
      })
      return
    }

    const isValidCode = await this.validateAgencyCode(data.agencyId);
    if(!isValidCode && data.agencyId != '') {
      Swal.fire({
        icon: 'info',
        title: this.lang == 'es' ? 'No encontramos el código ingresado' : "We can't find the code entered",
        text: this.lang == 'es' ? 'Por favor, verifique que el código sea correcto' : 'Please verify that the code is correct',
        confirmButtonText: this.lang == 'es' ? 'Aceptar' : 'Ok',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonColor: '#e5514e',
      })
      return
    }

    var listOfHotels = await this.getTransferInformationByHotelName(data.hotel);
    if (listOfHotels.length == 0) {
      return;
    }

    var hotelInformation = listOfHotels[0];

    var zone = await this.hotelService.getZone(hotelInformation["zone"]);
    let agencyCode = this.addForm.controls.agencyId.value ?? ''
    let dataaAgency:any = await this.agencyService.searchAgencyCode(agencyCode);

    // SET IMAGE
    if (data.persons <= 7) data.image = this.transporter
    if (data.persons >= 8 && data.persons <= 9) data.image = this.toyota
    if (data.persons >= 10 && data.persons <= 14) data.image = this.transporters
    if (data.persons >= 15) data.image = this.toyotas

    console.log('zone', zone);
    
    switch (data.type) {
      case 1:
        if (data.persons <= 3) {
          data.title = zone.nameEs,
            data.price = zone.roundTripPricesPaxOne > 0 ? zone.roundTripPricesPaxOne : 10;
          // data.image = zone.image
          data.subtitle = '1-3 pax';
        }
        if (data.persons >= 4 && data.persons <= 7) {
          data.title = zone.nameEs,
            data.subtitle = '4-7 pax';
          // data.image = zone.image
          data.price = zone.roundTripPricesPaxTwo > 0 ? zone.roundTripPricesPaxTwo : 10;
        }

        if (data.persons >= 8 && data.persons <= 9) {
          data.title = zone.nameEs,
            data.subtitle = '8-10 pax';
          // data.image = this.toyota;
          data.price = zone.roundTripPricesPaxThree > 0 ? zone.roundTripPricesPaxThree : 10;
        }
        if (data.persons >= 10 && data.persons <= 13) {
          data.title = zone.nameEs,
            data.subtitle = '10-13 pax';
          // data.image = this.transporters
          data.price = zone.roundTripPricesPaxFour > 0 ? zone.roundTripPricesPaxFour : 10;

        }
        if (data.persons >= 14 && data.persons <= 18) {
          console.log("14 a 18");

          data.title = zone.nameEs,
            data.subtitle = '14-18 pax';
          // data.image = this.toyotas
          data.price = zone.roundTripPricesPaxFive > 0 ? zone.roundTripPricesPaxFive : 10;
        }
        if (data.persons > 18) {
          data.title = zone.nameEs,
              data.subtitle = '18+ pax';
          // data.image = zone.image
          // this.navigateToLimitPax(data);
          this.showMaxPaxAlert()
          return;
        }
        if(dataaAgency.length > 0){
          let agency = dataaAgency[0].tariff.key;
          if (data.persons <= 3) {
            data.title = zone.nameEs,
                data.price = this.validatePrice(zone[agency].roundTripPricesPaxOne, zone.roundTripPricesPaxOne);;
            // data.image = zone.image
            data.subtitle = '1-3 pax';
          }
          if (data.persons >= 4 && data.persons <= 7) {
              data.title = zone.nameEs,
                  data.subtitle = '4-7 pax';
              // data.image = zone.image
              data.price = this.validatePrice(zone[agency].roundTripPricesPaxTwo, zone.roundTripPricesPaxTwo);
          }
          if (data.persons >= 8 && data.persons <= 10) {
              data.title = zone.nameEs,
                  data.subtitle = '8-10 pax';
              // data.image = zone.image
              data.price = this.validatePrice(zone[agency].roundTripPricesPaxThree, zone.roundTripPricesPaxThree);
          }
          if (data.persons == 10) {
            data.title = zone.nameEs,
              data.subtitle = '8-10 pax';
            // data.image = this.transporters
            data.price = zone.roundTripPricesPaxThree;
          }
          if (data.persons > 10 && data.persons <= 13) {
            data.title = zone.nameEs,
              data.subtitle = '10-13 pax';
            // data.image = this.transporters
            data.price = this.validatePrice(zone[agency].roundTripPricesPaxFour, zone.roundTripPricesPaxFour)
          }
          if (data.persons >= 14 && data.persons <= 18) {
            data.title = zone.nameEs,
              data.subtitle = '14-18 pax';
            // data.image = this.toyotas
            data.price = this.validatePrice(zone.roundTripPricesPaxFive, zone.roundTripPricesPaxFive);
          }
          if (data.persons > 18) {
              data.title = zone.nameEs,
                  data.subtitle = '18+ pax';
              // data.image = zone.image
              // this.navigateToLimitPax(data);
              this.showMaxPaxAlert()
              return;
          }
        }
        break;
      case 2:
        if (data.persons <= 3) {
          data.title = zone.nameEs,
            data.subtitle = '1-3 pax';
          // data.image = zone.image
          data.price = zone.hotelPricesPaxOne > 0 ? zone.hotelPricesPaxOne : 10;
        }
        if (data.persons >= 4 && data.persons <= 7) {
          data.title = zone.nameEs,
            data.subtitle = '4-7 pax';
          // data.image = zone.image
          data.price = zone.hotelPricesPaxTwo > 0 ? zone.hotelPricesPaxTwo : 10;
        }
        if (data.persons >= 8 && data.persons <= 9) {

          data.subtitle = '8-9 pax';
          // data.image = zone.image
          data.price = zone.hotelPricesPaxThree > 0 ? zone.hotelPricesPaxThree : 10;
        }
        if (data.persons >= 10 && data.persons <= 13) {

          data.subtitle = '10-13 pax';
          // data.image = zone.image
          data.price = zone.hotelPricesPaxFour > 0 ? zone.hotelPricesPaxFour : 10;
        }
        if (data.persons >= 14 && data.persons <= 18) {

          data.subtitle = '14-18 pax';
          // data.image = zone.image
          data.price = zone.hotelPricesPaxFive > 0 ? zone.hotelPricesPaxFive : 10;
        }
        if (data.persons > 18) {
          data.title = zone.nameEs,
            data.subtitle = '18+ pax';
          // data.image = zone.image
          // this.navigateToLimitPax(data);
          this.showMaxPaxAlert()
          return;
        }
        if(dataaAgency.length > 0){
          let agency = dataaAgency[0].tariff.key;
          if (data.persons <= 3) {
            data.title = zone.nameEs,
                data.price = this.validatePrice(zone[agency].hotelPricesPaxOne,zone.hotelPricesPaxOne);
            // data.image = zone.image
            data.subtitle = '1-3 pax';
          }
          if (data.persons >= 4 && data.persons <= 7) {
              data.title = zone.nameEs,
                  data.subtitle = '4-7 pax';
              // data.image = zone.image
              data.price = this.validatePrice(zone[agency].hotelPricesPaxTwo,zone.hotelPricesPaxTwo);
          }
          if (data.persons >= 8 && data.persons <= 9) {

            data.subtitle = '8-9 pax';
            // data.image = zone.image
            data.price = this.validatePrice(zone[agency].hotelPricesPaxThre,zone.hotelPricesPaxThree);
          }
          if (data.persons >= 10 && data.persons <= 13) {

            data.subtitle = '10-13 pax';
            // data.image = zone.image
            data.price = this.validatePrice(zone[agency].hotelPricesPaxFour,zone.hotelPricesPaxFour);;
          }
          if (data.persons >= 14 && data.persons <= 18) {

            data.subtitle = '14-18 pax';
            // data.image = zone.image
            data.price = this.validatePrice(zone[agency].hotelPricesPaxFive,zone.hotelPricesPaxFive);;
          }
          if (data.persons > 18) {
            data.title = zone.nameEs,
              data.subtitle = '18+ pax';
            // data.image = zone.image
            // this.navigateToLimitPax(data);
            this.showMaxPaxAlert()
            return;
          }
        }
        break;
      case 3:
        if (data.persons <= 3) {
          data.title = zone.nameEs,
            data.subtitle = '1-3 pax';
          data.price = zone.hotelPricesPaxOne > 0 ? zone.hotelPricesPaxOne : 10;
          // data.image = zone.image
        }
        if (data.persons >= 4 && data.persons <= 7) {
          data.title = zone.nameEs,
            data.subtitle = '4-7 pax';
          data.price = zone.hotelPricesPaxTwo > 0 ? zone.hotelPricesPaxTwo : 10;
          // data.image = zone.image
        }
        if (data.persons >= 8 && data.persons <= 9) {
          data.title = zone.nameEs,
            data.subtitle = '8-9 pax';
          data.price = zone.hotelPricesPaxThree > 0 ? zone.hotelPricesPaxThree : 10;
          // data.image = zone.image
        }
        if (data.persons >= 10 && data.persons <= 13) {
          data.title = zone.nameEs,
            data.subtitle = '10-13 pax';
          data.price = zone.hotelPricesPaxFour > 0 ? zone.hotelPricesPaxFour : 10;
          // data.image = zone.image
        }
        if (data.persons >= 14 && data.persons <= 18) {
          data.title = zone.nameEs,
            data.subtitle = '14-18 pax';
          data.price = zone.hotelPricesPaxFive > 0 ? zone.hotelPricesPaxFive : 10;
          // data.image = zone.image
        }
        if (data.persons > 18) {
          data.title = zone.nameEs,
            data.subtitle = '18+ pax';
          // data.image = zone.image
          // this.navigateToLimitPax(data);
          this.showMaxPaxAlert()
          return;
        }
        console.log('dataaAgency',dataaAgency);
        
        if(dataaAgency.length > 0){
          let agency = dataaAgency[0].tariff.key;
          if (data.persons <= 3) {
            console.log('entra aquí');
            console.log('zone[agency].hotelPricesPaxOne',zone[agency]);
            console.log('zone.hotelPricesPaxOne',zone.hotelPricesPaxOne);
            data.title = zone.nameEs,
                data.price =  this.validatePrice(zone[agency].hotelPricesPaxOne, zone.hotelPricesPaxOne);
            // data.image = zone.image
            data.subtitle = '1-3 pax';
          }
          if (data.persons >= 4 && data.persons <= 7) {
              data.title = zone.nameEs,
                  data.subtitle = '4-7 pax';
              // data.image = zone.image
              data.price =  this.validatePrice(zone[agency].hotelPricesPaxTwo, zone.hotelPricesPaxTwo);
          }
          if (data.persons >= 8 && data.persons <= 9) {
              data.title = zone.nameEs,
                  data.subtitle = '8-9 pax';
              // data.image = zone.image
              data.price =  this.validatePrice(zone[agency].hotelPricesPaxThree,zone.hotelPricesPaxThree);
          }
          if (data.persons >= 10 && data.persons <= 13) {
            data.title = zone.nameEs,
                data.subtitle = '10-13 pax';
            // data.image = zone.image
            data.price =  this.validatePrice(zone[agency].hotelPricesPaxFour,zone.hotelPricesPaxFour);
          }
          if (data.persons >= 14 && data.persons <= 18) {
            data.title = zone.nameEs,
                data.subtitle = '14-18 pax';
            // data.image = zone.image
            data.price =  this.validatePrice(zone[agency].hotelPricesPaxFive,zone.hotelPricesPaxFive);
          }
          if (data.persons > 18) {
              data.title = zone.nameEs,
                  data.subtitle = '18+ pax';
              // data.image = zone.image
              // this.navigateToLimitPax(data);
              this.showMaxPaxAlert()
              return;
          }
        }
        break;
      default:
        break;
    }
    console.log('data', data);
    this.navigateToTransferPage(data);
    this.saveForm()
  }

  async validateAgencyCode(agencyCode: string) {
    const response = await this.agencyService.searchAgencyCode(agencyCode);
    return response.length == 1
  }

  navigateToTransferPage(data: any) {
    // console.log("Data: ", data);

    this.resultZones.push({
      price: data.price,
      image: data.image,
      subtitle: data.subtitle,
      hotel: data.hotel,
      type: data.type,
      adults: data.adults,
      kids: data.kids,
      persons: data.persons,
      title: data.title,
      agencyCode: data.agencyId
    })

    var params = JSON.stringify(this.resultZones[0]);
    this.router.navigate(['/checkout'], { queryParams: { data: params } });
  }

  navigateToLimitPax(TravelInfo: any) {
    this.router.navigate(['/limit-pax/'], { queryParams: TravelInfo })
  }

  async getTransferInformationByHotelName(hotelName: string) {
    let result = await this.hotelService.searchHotelByName(hotelName);
    return result;
  }

  saveForm(){
    localStorage.setItem('adults', this.addForm.value.adults)
    localStorage.setItem('city', this.addForm.value.city)
    localStorage.setItem('hotel',this.hotelsControl.value)
    localStorage.setItem('kids', this.addForm.value.kids)
    localStorage.setItem('tripType', this.addForm.value.tripType)
    localStorage.setItem('agencyId', this.addForm.value.agencyId)
  }

  validatePrice(agencyPrice:number, publicPrice:number){
    if(agencyPrice == 0 || agencyPrice == null || agencyPrice == undefined){
      return publicPrice;
    }else if(agencyPrice == 0 && publicPrice == 0){
      return 45;
    }else{
      return agencyPrice
    }
  }

  showMaxPaxAlert(){
    Swal.fire({
      icon: 'info',
      title: this.lang == 'es' ? 'La cantidad máxima de pasajeros permitidos es 18' : 'Maximum number of passengers allow is 18',
      text: '',
      confirmButtonText: this.lang == 'es' ? 'Aceptar' : 'Ok',
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonColor: '#e5514e',
    })
  }

}
