import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EmailService } from 'src/app/services/email/email.service';
import { TransferService } from 'src/app/services/transfer/transfer.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-thanks-page',
  templateUrl: './thanks-page.component.html',
  styleUrls: ['./thanks-page.component.scss']
})
export class ThanksPageComponent implements OnInit {

  public id: string;
  public transferPurchase;
  public infoPurchase;
  public infoTransfer;
  public disableMail = false;
  public clientInfo;
  public statusPayment;

  // Stripe messages payments
  public message = '';
  public message2 = '';
  public imgStatus = '';
  public activeLang = 'en';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private transferServie: TransferService,
    private emailService: EmailService,
    private translate: TranslateService
  ) { }

  stripe;

  async ngOnInit(): Promise<void> {
    this.activeLang = this.translate.currentLang;
    this.message = this.activeLang == 'es' ? '¡Excelente! Tu orden fue procesada correctamente.' : 'Excellent! Your order was successfully processed.'
    this.message2 = this.activeLang == 'es' ? 'Por favor revisa tu correo electrónico para tu Comprobante de Transportación' : 'Please check your email for your transportation voucher'
    this.imgStatus = 'assets/images/success.png'
    let sub = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.activeLang = this.translate.currentLang;
        this.message = this.activeLang == 'es' ? '¡Excelente! Tu orden fue procesada correctamente.' : 'Excellent! Your order was successfully processed.'
        this.message2 = this.activeLang == 'es' ? 'Por favor revisa tu correo electrónico para tu Comprobante de Transportación' : 'Please check your email for your transportation voucher'
        setTimeout(() => {
        }, 200);
      }
    });
    window.localStorage.removeItem('flightInfo');
    // Stripe
    this.stripe = Stripe("pk_live_51Hm4n1E8EO7nJS3EVbH8q2TQdgvELzyAHpJozAhWOZrSFfb77e05Z5KKIDc9AY0jK7xkfj0uCDxzCqUTS09fe99200UDqUnWm0")
    try {
      const clientSecret = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret'
      );
      const creditPayment = new URLSearchParams(window.location.search).get(
        'credit_payment'
      )
      console.log(creditPayment);
      console.log(creditPayment);
      if (creditPayment) {
        this.message = this.activeLang == 'es' ? '¡Excelente! Tu orden fue procesada correctamente.' : 'Excellent! Your order was successfully processed.'
        this.message2 = this.activeLang == 'es' ? 'Por favor revisa tu correo electrónico para tu Comprobante de Transportación' : 'Please check your email for your transportation voucher'
        this.imgStatus = 'assets/images/success.png'
        console.log('Success! Payment received.');
      } else {
        this.stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
          switch (paymentIntent.status) {
            case 'succeeded':
              this.message = this.activeLang == 'es' ? '¡Excelente! Tu orden fue procesada correctamente.' : 'Excellent! Your order was successfully processed.'
              this.message2 = this.activeLang == 'es' ? 'Por favor revisa tu correo electrónico para tu Comprobante de Transportación' : 'Please check your email for your transportation voucher'
              this.imgStatus = 'assets/images/success.png'
              console.log('Success! Payment received.');

              break;

            case 'processing':
              this.message = 'Pago en proceso. Te haremos saber cuando el pago se haya realizado.'
              this.imgStatus = 'assets/images/processing.png'
              console.log('Payment processing. We\'ll update you when payment is received.');

              break;

            case 'requires_payment_method':
              this.message = 'Pago no realizado. Por favor, intenté con otro método de pago.'
              this.imgStatus = 'assets/images/failed.png'
              console.log('Payment failed. Please try another payment method.');
              // Redirect your user back to your payment page to attempt collecting
              // payment again
              break;

            default:
              this.message = 'Algo salió mal, porfavor intenté más tarde.';
              this.imgStatus = 'assets/images/wrong.png'
              console.log('Something went wrong.');
              break;
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  redirect() {
    window.location.href = 'https://www.mexicotoptours.com';
  }

  deleteFormLS() {
    window.localStorage.removeItem('flightInfo');
  }
}
