<ng-container *ngIf="loading">
  <div class="spinner">
  </div>
</ng-container>

<div class="content">
  <div class="pay">
    <ngx-paypal [config]="payPalConfig"></ngx-paypal>
  </div>
</div>

<!-- <form id="payment-form">

    <div id="cardElement" #cardElement>

    </div>

    <p>{{cardErrors}}</p>

    <button class="btn-buy" (click)="confirmStripePayment(globalElements)">{{'STRIPE-FORM.BTN-RESERVATION' |
        translate}}</button>
</form> -->
