<div class="" style="height: 100px;"></div>
<section>
  <!-- <div class="head-page">
        <div class="row">
            <div class="col-xs-12 head-title">

                <button class="btn-back" *ngIf="paySection" mat-raised-button (click)="goBack()">
                    <mat-icon>keyboard_backspace</mat-icon>
                    {{'BTN-BACK' | translate}}
                </button>

                <p class="h1 title">{{'FORM.INFOANDMORE' | translate}}</p>
                <button *ngIf="paySection" class="hidden"></button>
            </div>
        </div>
    </div> -->
  <div class="container" style="margin-bottom: 20px;">
    <button class="btn-back" mat-raised-button (click)="goBack()">
      <mat-icon>keyboard_backspace</mat-icon>
      {{'BTN-BACK' | translate}}
    </button>
    <!-- <p style="margin: 0">newFinalPrice: {{newFinalPrice}}</p>  -->
    <!-- <p style="margin: 0">arrivalDate: {{submitForm.value.arrivalDate}}</p>  -->
  </div>
  <!-- <p>teléfono: {{submitForm.value.phoneNumber }}</p> -->
  <div class="container checkout-info-container">
    <div class="cart">
      <h1>Checkout</h1>
      <div class="item">
        <div class="img">
          <img [src]="transferData.image" alt="">
        </div>
        <div class="summary">
          <div class="header">
          </div>
          <ul>
            <li><label>Pax:</label> {{transferData.persons}}</li>
            <li *ngIf="transferData.type == 1"><label>{{ "TRIP_TYPE" | translate }}:</label>
              {{'FORM.ROUNDTRIP' |
              translate}}
            </li>
            <li *ngIf="transferData.type == 2"><label>{{ "TRIP_TYPE" | translate }}:</label>
              {{'FORM.HOTELTOAIR' |
              translate}}
            </li>
            <li *ngIf="transferData.type == 3"><label>{{ "TRIP_TYPE" | translate }}:</label>
              {{'FORM.AIRTOHOTEL' |
              translate}}
            </li>
            <li><label>{{ "CART.HOTEL" | translate }}:</label> {{transferData.hotel}}</li>
            <li><label>{{ "CART.ZONE" | translate }}:</label> {{transferData.title}}</li>
          </ul>
          <div class="btn">
            <button *ngIf="!paySection" mat-raised-button (click)="editData()">{{'CART.EDIT' |
              translate}}</button>
            <!-- <button *ngIf="!paySection" mat-raised-button (click)="testMail()">TEST</button> -->
          </div>
        </div>
      </div>
      <hr>
      <!-- <div class="total">
                <h1>SUBTOTAL</h1>
                <h1>{{getTaxes(transferData.price)| currency}} USD</h1>
            </div>
            <div class="total">
                <h1>{{ "TAXES" | translate }}</h1>
                <h1>{{(transferData.price) - getTaxes(transferData.price) | currency}}(16%)</h1>
            </div> -->
      <div class="total" *ngIf="!couponUsed">
        <h1>TOTAL</h1>
        <h1>{{transferData.price | currency}} USD</h1>
      </div>

      <!-- Coupon used -->
      <div class="total" *ngIf="couponUsed && agencyDiscount == 0">
        <h1>{{"COUPONS.DISCOUNT" | translate}}</h1>
        <h1>{{(transferData.price) - newFinalPrice | currency}} USD</h1>
      </div>
      <div class="total" *ngIf="couponUsed">
        <h1>{{"COUPONS.NEW-TOTAL" | translate}}</h1>
        <h1> {{newFinalPrice | currency}} USD</h1>
      </div>

      <!-- Credit used -->
      <div class="total" *ngIf="agencyDiscount > 0">
        <h1>50% {{"CREDIT" | translate}}</h1>
        <h1>-{{agencyDiscount | currency}} USD</h1>
      </div>
      <!-- Credit used -->
      <div class="total" *ngIf="agencyDiscount > 0">
        <h1>{{"PENDING_PAID" | translate}}</h1>
        <h1>{{agencyDiscount | currency}} USD</h1>
      </div>

      <!-- Credit used -->
      <!-- <div class="total" *ngIf="agencyDiscount > 0 && couponUsed">
                <h1>Total</h1>
                <h1>-{{newFinalPrice - agencyDiscount}}USD</h1>
            </div> -->



      <div class="cupon" *ngIf="!couponUsed && paySection == false">
        <input [readonly]="" type="text" placeholder="{{'COUPONS.PLACEHOLDER' | translate}}" [(ngModel)]="couponInput">
        <button class="couponBtn" type="button" [disabled]="couponInput == '' || couponUsed"
          (click)="validCoupon(transferData.price)">
          {{"COUPONS.REDEEM-BTN" | translate}}
        </button>
      </div>

      <div class="cupon" *ngIf="couponUsed">
        <p>{{"COUPONS.TEXT" | translate}}: {{coupon.coupon}} - {{coupon.discount * 100}}%</p>
        <button *ngIf="!paySection" class="deleteCouponBtn" type="button" (click)="deleteCoupon()">
          {{"COUPONS.DELETE-BTN" | translate}}
        </button>
      </div>



      <div class="checkbox">
        <div class="content">

          <p style="margin-bottom: 0px;">{{'TERMS.SECTION3.TITLE' | translate}}</p>
          <p class="view-more"><span (click)="manageAcordion3($event)">{{'TERMS.VIEWMORE' | translate}}</span>
          </p>
          <mat-expansion-panel #acordion3 hideToggle>
            <mat-expansion-panel-header>
            </mat-expansion-panel-header>
            <ul class="list-latam mb3">
              <li>{{'TERMS.SECTION3.1' | translate}}</li>
              <li>{{'TERMS.SECTION3.2' | translate}}</li>
              <li>{{'TERMS.SECTION3.3' | translate}}</li>
              <li>{{'TERMS.SECTION3.4' | translate}}</li>
              <li>{{'TERMS.SECTION3.5' | translate}}</li>
              <li>{{'TERMS.SECTION3.6' | translate}}</li>
              <li>{{'TERMS.SECTION3.7' | translate}}</li>
              <li>{{'TERMS.SECTION3.8' | translate}}</li>
              <li>{{'TERMS.SECTION3.9' | translate}}</li>
              <li>{{'TERMS.SECTION3.10' | translate}}</li>
              <li>{{'TERMS.SECTION3.11' | translate}}</li>
              <li>{{'TERMS.SECTION3.12' | translate}}</li>
              <li>{{'TERMS.SECTION3.13' | translate}}</li>
              <li>{{'TERMS.SECTION3.14' | translate}}</li>
            </ul>
          </mat-expansion-panel>

          <hr>

          <p style="margin-bottom: 0px;">{{'TERMS.TITLE' | translate}}</p>
          <p class="view-more"><span (click)="manageAcordion($event)">{{'TERMS.VIEWMORE' | translate}}</span>
          </p>
          <mat-accordion #acordion1 multi>
            <mat-expansion-panel hideToggle>
              <mat-expansion-panel-header>
              </mat-expansion-panel-header>
              <ul class="list-latam">
                <li>{{'TERMS.1' | translate}}</li>
                <br>
                <li>{{'TERMS.2' | translate}}</li>
                <br>
                <li>{{'TERMS.3' | translate}}</li>
              </ul>
            </mat-expansion-panel>
          </mat-accordion>

          <hr>
          <p>{{'TERMS.TITLE2' | translate}}</p>
          <p class="view-more"><span (click)="manageAcordion2($event)">{{'TERMS.VIEWMORE' | translate}}</span>
          </p>
          <mat-accordion #acordion1 multi>
            <mat-expansion-panel hideToggle>
              <mat-expansion-panel-header>
              </mat-expansion-panel-header>
              <ul class="list-latam">
                <li>{{'TERMS.a' | translate}}</li>
                <br>
                <li>{{'TERMS.b' | translate}}</li>
                <br>
                <li>{{'TERMS.c' | translate}}</li>
              </ul>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-expansion-panel #myPanel hideToggle>
            <mat-expansion-panel-header>
            </mat-expansion-panel-header>
            <ul class="list-latam">
              <li>{{'TERMS.a' | translate}}</li>
              <br>
              <li>{{'TERMS.b' | translate}}</li>
              <br>
              <li>{{'TERMS.c' | translate}}</li>
            </ul>
          </mat-expansion-panel>

        </div>
        <mat-checkbox *ngIf="!paySection" class="example-margin" [(ngModel)]="terms">{{'CHECKOUT.TERMS' |
          translate}}</mat-checkbox>
      </div>
    </div>

    <div class="checkout">

      <!-- <h1>{{'CHECKOUT.TITLE' | translate}}</h1> -->
      <ng-container *ngIf="!paySection">
        <div class="first-part">
          <p class="email-legend">{{'CHECKOUT.LEGEND' | translate}}</p>
          <form [formGroup]="submitForm">
            <div *ngIf="showWarning !== ''" class="warning-container">
              <p>{{showWarning | translate}}</p>
            </div>
            <div class="flight-container">
              <p>{{'FLIGTH_INFO' | translate}}</p>
              <div *ngIf="transferData.type == 1 || transferData.type == 3" class="row">
                <div class="col-md-12 text-center">
                  <h3><strong>{{'CHECKOUT.ARRIVAL_INFO' | translate}}</strong></h3>
                </div>
              </div>
              <div *ngIf="transferData.type == 1 || transferData.type == 3" class="row">
                <div class="col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{"TRANSFER.FORM.FIELD6" | translate}}</mat-label>
                    <input readonly matInput [matDatepicker]="picker" formControlName="arrivalDate" [min]="minDate"
                      [max]="maxDate" (dateChange)="changeDate()" (click)="openPicker()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker
                      (monthSelected)="setMonthAndYear($event, picker, 'arrivalDate')"></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{"TRANSFER.FORM.FIELD75" | translate}}</mat-label>
                    <input matInput formControlName="arrivalHour" [owlDateTimeTrigger]="dt5" [owlDateTime]="dt5">
                    <owl-date-time [pickerType]="'timer'" [hour12Timer]="true" #dt5></owl-date-time>
                  </mat-form-field>
                </div>
              </div>
              <div *ngIf="transferData.type == 1 || transferData.type == 3" class="row">
                <div class="col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ "TRANSFER.FORM.FIELD4" | translate }}</mat-label>
                    <input matInput formControlName="arrival_airline">
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{"TRANSFER.FORM.FIELD5" | translate}}</mat-label>
                    <input matInput formControlName="arrival_flightNumber">
                  </mat-form-field>
                </div>
              </div>
              <div *ngIf="transferData.type == 1 || transferData.type == 2" class="row">
                <div class="col-md-12 text-center">
                  <h3><strong>{{'CHECKOUT.DEPARTURE_INFO' | translate}}</strong></h3>
                </div>
              </div>
              <div *ngIf="transferData.type == 1 || transferData.type == 2" class="row">
                <div class="col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{"TRANSFER.FORM.FIELD7" | translate}}</mat-label>
                    <input readonly matInput [matDatepicker]="picker2" formControlName="departureDate"
                      [min]="submitForm.value.arrivalDate" [max]="maxDate" (click)="openPicker2()">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{"TRANSFER.FORM.FIELD76" | translate}}</mat-label>
                    <input matInput formControlName="departureHour" [owlDateTimeTrigger]="dt6" [owlDateTime]="dt6">
                    <owl-date-time [pickerType]="'timer'" [hour12Timer]="true" #dt6></owl-date-time>
                  </mat-form-field>
                </div>
              </div>
              <div *ngIf="transferData.type == 1 || transferData.type == 2" class="row">
                <div class="col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ "TRANSFER.FORM.FIELD4" | translate }}</mat-label>
                    <input matInput formControlName="departure_airline">
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{"TRANSFER.FORM.FIELD5" | translate}}</mat-label>
                    <input matInput formControlName="departure_flightNumber">
                  </mat-form-field>
                </div>
              </div>
              <!-- </mat-expansion-panel> -->
            </div>

            <div class="contact-container">
              <!-- <mat-expansion-panel style="margin: 0.5rem auto;" [expanded]="true"> -->
              <!-- <mat-expansion-panel-header> -->
              <!-- <mat-panel-title> -->
              <p>{{'CONTACT_INFO' | translate}}</p>
              <!-- </mat-panel-title> -->
              <!-- </mat-expansion-panel-header> -->
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{"TRANSFER.FORM.FIELD8" | translate}} </mat-label>
                    <input matInput formControlName="firstName">
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{"TRANSFER.FORM.FIELD9" | translate}}</mat-label>
                    <input matInput formControlName="lastName">
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">

                  <mat-form-field appearance="outline">
                    <mat-label>{{"TRANSFER.FORM.FIELDEM" | translate}} </mat-label>
                    <input matInput formControlName="email">
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label style="padding-left: 2.5rem;">{{"TRANSFER.FORM.FIELD10" | translate}}
                    </mat-label>
                    <input class="phone" matInput formControlName="phoneNumber" type="text" ng2TelInput
                      (intlTelInputObject)="telInputObject($event)"
                      [ng2TelInputOptions]="{initialCountry: phoneIsoCodeSelected, preferredCountries: ['es']}"
                      (countryChange)="onCountryChange($event)" oninput="(value=value.replace(/\D+/g, ''))"
                      minlength="8" maxlength="12">
                  </mat-form-field>
                </div>
              </div>
              <!-- </mat-expansion-panel> -->
              <!-- </mat-accordion> -->
            </div>

            <div *ngIf="transferData.agencyCode != ''" class="agency-container">

              <p>{{"TRANSFER.FORM.FIELD13" | translate}}</p>
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{"TRANSFER.FORM.FIELD14" | translate}}</mat-label>
                    <mat-select formControlName="paymentAgencyMethod">
                      <mat-option *ngFor="let option of agencyPaymentMethods"
                        [value]=option.value>{{getAgencyOptionName(option.name)}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </form>

          <div class="btn" [disabled]="!terms || !submitForm.valid">
            <button (click)="initConfig()">{{'CHECKOUT.BTN' |
              translate}}</button>
            <div>
              <mat-icon>https</mat-icon>
              <p>{{'CHECKOUT.SECURE' | translate}}</p>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="paySection">
        <div class="second-part">
          <div class="header">
            <mat-icon matPrefix>check_circle_outline</mat-icon>
            <div>
              <p>{{'CHECKOUT.EMAIL' | translate}}</p>
              {{submitForm.value.email}} <a href="javascript:void(0)"
                (click)="paySection = false">{{'CHECKOUT.EMAIL_CHANGE' | translate}}</a>
            </div>
          </div>

          <div class="options-pays">
            <!-- <app-stripe-elements [amount]="newFinalPrice" [infoPurchase]="infoOrder"></app-stripe-elements>
              <app-paypal-elements [amount]="newFinalPrice" [infoPurchase]="infoOrder"></app-paypal-elements>
              <app-openpay-elements [amount]="newFinalPrice" [infoPurchase]="infoOrder"></app-openpay-elements> -->

            <!-- <div class="top-options">
                <button (click)="changeMethodPay('paypal')" [ngClass]="{'active-method': actualMethodPay === 'paypal'}"
                  class="btn"><img src="/assets/images/logos/paypal.png" alt=""></button>
                  <button (click)="changeMethodPay('openpay')" [ngClass]="{'active-method': actualMethodPay === 'openpay'}"
                  class="btn"><img src="/assets/images/logos/openpay.png" alt=""></button>
              </div> -->

            <div class="method">
              <div class="top">
                <app-openpay-elements [amount]="newFinalPrice" [orderInfo]="infoOrder"></app-openpay-elements>
              </div>
              <div class="dividor">
                <div class="line"></div>
                <p>{{'OPENPAY.BUTTON-OR' | translate}}</p>
                <div class="line"></div>
              </div>
              <div class="paypal">
                <app-paypal-elements [amount]="newFinalPrice" [infoPurchase]="infoOrder"></app-paypal-elements>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

</section>
