import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  shootSimpleAlert(icon: SweetAlertIcon, title: string, text?: string) {
    Swal.fire({
      position: 'center',
      icon: icon,
      title: title,
      text: text,
      showConfirmButton: true,
      confirmButtonColor: "#d71212",
    });
  }

  shootLoadingAlert() {
    Swal.fire({
      title: 'Espere un momento...',
      html: 'Cargando',
      timerProgressBar: true,
      backdrop: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })
  }

  async shootConfirmAlert(icon: SweetAlertIcon, title: string, text?: string) {
    return await Swal.fire({
      position: 'center',
      icon: icon,
      title: title,
      text: text,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: "#d71212",
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    });
  }

  closeAlert() {
    Swal.close();
  }

  async toastAlert(icon: SweetAlertIcon, title: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 2500,
      timerProgressBar: true
    });

    Toast.fire({
      icon: icon,
      title: title,
    });
  }
}
