import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { EmailService } from '../../services/email/email.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-limit-pax-form',
  templateUrl: './limit-pax-form.component.html',
  styleUrls: ['./limit-pax-form.component.scss']
})
export class LimitPaxFormComponent implements OnInit {

  // lang
  public lang: string;
  private subs: Subscription

  // public limitPaxForm: FormGroup;
  public loading: boolean = false;

  public resultZones: Array<any> = [];

  constructor(
    private translate: TranslateService,
    private emailService: EmailService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  limitPaxForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,63}')]],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    phoneNumber: ['', [Validators.required]],
    message: ['', [Validators.required]],
  })

  ngOnInit() {
    this.lang = this.translate.defaultLang;
    this.subs = this.translate.onLangChange.subscribe(lang => {
      this.lang = lang.lang;
    })


    let selectedHotel = this.activatedRoute.snapshot.queryParamMap.get('hotel')
    let selectedType = this.activatedRoute.snapshot.queryParamMap.get('type')
    let selectedPersons = this.activatedRoute.snapshot.queryParamMap.get('persons')
    let selectedPrice = this.activatedRoute.snapshot.queryParamMap.get('price')
    let selectedSubtitle = this.activatedRoute.snapshot.queryParamMap.get('subtitle')
    let selectedImage = this.activatedRoute.snapshot.queryParamMap.get('image')
    let selectedKids = this.activatedRoute.snapshot.queryParamMap.get('kids')
    let selectedAdults = this.activatedRoute.snapshot.queryParamMap.get('adults')
    let selectedTitle = this.activatedRoute.snapshot.queryParamMap.get('title')

    this.resultZones.push({
      price: selectedPrice,
      image: selectedImage,
      subtitle: selectedSubtitle,
      hotel: selectedHotel,
      type: selectedType,
      adults: selectedAdults,
      kids: selectedKids,
      persons: selectedPersons,
      title: selectedTitle
    })
  }

  ngOnDestroy() {
    if (this.subs) this.subs.unsubscribe();
  }

  async sendMail() {
    this.loading = true;

    if (this.limitPaxForm.invalid) {
      this.limitPaxForm.markAllAsTouched();
      this.loading = false;
      Swal.fire({
        icon: 'info',
        title: this.lang == 'es' ? 'Asegúrate de llenar todos los campos' : 'Make sure you fill in all the fields',
        text: '',
        confirmButtonText: this.lang == 'es' ? 'Aceptar' : 'Ok',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonColor: '#e5514e',
      }).then(async (result) => {
        this.loading = false;
      });
      return;
    }

    try {
      const response = await this.emailService.sendPersonalizedTripMail(this.limitPaxForm.value);

      console.log(response);

      if (response.code == 200) {
        Swal.fire({
          icon: 'success',
          title: 'Excellent!',
          text: 'We\'ll be in touch soon...',
          confirmButtonText:  'Ok',
          // title: this.lang == 'es' ? '¡Excelente!' : 'Excellent!',
          // text: this.lang == 'es' ? 'Nos pondremos en contacto pronto...' : 'We\'ll be in touch soon...',
          // confirmButtonText: this.lang == 'es' ? 'Aceptar' : 'Ok',
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonColor: '#e5514e',
        }).then(async (result) => {
          this.loading = false;
          if (result.isConfirmed) {
            this.router.navigateByUrl('/');
          }
        });
        this.loading = false;
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong!',
          text: 'Please try again later!',
          confirmButtonText: 'Ok',
          //title: this.lang == 'es' ? 'Ha ocurrido un error!' : 'Something went wrong!',
          //text: this.lang == 'es' ? 'Por favor, intente de nuevo más tarde!' : 'Por favor, intente de nuevo más tarde!',
          //confirmButtonText: this.lang == 'es' ? 'Aceptar' : 'Ok',
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonColor: '#e5514e',
        }).then(async (result) => {
          this.loading = false;
          if (result.isConfirmed) {
            this.router.navigateByUrl('/');
          }
          this.loading = false;
        });
      }
    } catch (error) {
      console.log(error);
      throw new Error(error);
      this.loading = false;
    }

  }

  goBack() {
    history.go(-1);
  }

}
