import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private sendMailURI = `${environment.URL_API}/email-send`;
  private sendMailAdminURI = `${environment.URL_API}/admin-email-send`;
  private sendPersonalizedTripMailURI = `${environment.URL_API}/email/personalized-trip`;
  private sendTestMailURI = `${environment.URL_API}/email-test`;

  constructor(
    private http: HttpClient
  ) { }

  async sendMail(data) {
    try {
      console.log("data");

      let res = await this.http.post<{ code: number, message: string }>(this.sendMailURI, data).toPromise();
      return res;
    } catch (error) {
      console.log(error);

    }
  }

  async sendMailAdmin(data) {
    try {
      console.log("data", data);
      let res = await this.http.post<{ code: number, message: string }>(this.sendMailAdminURI, data).toPromise();
      return res;
    } catch (error) {
      console.log(error);

    }
  }

  async sendPersonalizedTripMail(dataTrip) {
    try {
      console.log("data");
      let res = await this.http.post<{ code: number, message: string }>(this.sendPersonalizedTripMailURI, dataTrip).toPromise();
      return res;
    } catch (error) {
      console.log(error);

    }
  }

  async sendTestMail(data, lang) {
    data.activeLang = lang;
    try {
      let res = await this.http.post<{ code: number, message: string }>(this.sendTestMailURI, data).toPromise();
      return res;
    } catch (error) {
      console.log(error);
    }
  }
}
