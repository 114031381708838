import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ChoseTransferComponent } from './components/chose-transfer/chose-transfer.component';
import { ThanksPageComponent } from './components/thanks-page/thanks-page.component';
import { HomeComponent } from './pages/home/home.component';
import { StoreComponent } from './pages/store/store.component';
import { TransfersComponent } from './pages/transfers/transfers.component';
import { LimitPaxFormComponent } from './components/limit-pax-form/limit-pax-form.component';
import { pathToFileURL } from 'url';

const routes: Routes = [
  {
    path: ':lang', children: [
      { path: 'home/cancun', component: HomeComponent },
      // { path: ':lang/home/cancun', component: HomeComponent },

      { path: 'home/mexico', component: HomeComponent },
      // { path: ':lang/home/mexico', component: HomeComponent },

      { path: 'home', component: HomeComponent },
      // { path: ':lang/home', component: HomeComponent },

      { path: 'transfer/:id/:hotel', component: TransfersComponent },
      // { path: ':lang/transfer/:id/:hotel', component: TransfersComponent },

      { path: 'thanks', component: ThanksPageComponent },
      { path: 'thanks/:id', component: ThanksPageComponent },

      { path: 'store', component: StoreComponent },
      // { path: ':lang/store', component: StoreComponent },

      { path: 'chose-transfer', component: ChoseTransferComponent },
      // { path: ':lang/chose-transfer', component: ChoseTransferComponent },

      { path: 'checkout', component: CheckoutComponent },
      // { path: ':lang/checkout', component: CheckoutComponent },

      { path: 'limit-pax', component: LimitPaxFormComponent },
      // { path: ':lang/limit-pax', component: LimitPaxFormComponent },

      { path: '', component: HomeComponent },
      { path: "**", redirectTo: 'home', pathMatch: 'full' }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
