import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { CartService } from 'src/app/services/cart/cart.service';
import { EmailService } from 'src/app/services/email/email.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Component({
  selector: 'app-paypal-elements',
  templateUrl: './paypal-elements.component.html',
  styleUrls: ['./paypal-elements.component.scss']
})
export class PaypalElementsComponent implements OnInit {
  @Input() amount
  @Input() infoPurchase: any;

  loading = false;
  public payPalConfig?: IPayPalConfig;
  public loaderpage: boolean = false;
  public activeLang = 'en';

  constructor(
    private router: Router,
    private cartService: CartService,
    private emailService: EmailService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    let sub = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          console.log('idiomas', this.translate.currentLang);
          this.activeLang = this.translate.currentLang;
        }, 200);
      }
    });
    this.initPaypalConfig();
  }

  private initPaypalConfig(): void {
    let finalPrice = this.amount;
    // finalPrice = total
    // let finalPrice = 10;
    // for testing = AeBS0Iz8OFqIIpTjFk9nUMwf1LJP44dHbLHhCfB8Xl0J7dwCvowxZ_PnBaekCVAY55sxBhkQGpC1_Ngm
    // for prod = AXZ4WNtXMsZ7kvN0fLXqV64GPUNalzp-9JjYLQ0j2jecgQy6qv4wC5g4_phNlfmC5lXAktM19TWuWOgJ
    this.payPalConfig = {
      currency: 'USD',//this.currentExchangeRate.type == 0 ? 'USD' : this.currentExchangeRate.type == 1 ? 'MXN' : 'EUR',
      // SANDBOX
      // clientId: 'Adj06sJ37zpz7WhwfvkZFIPMQcs0LsmyhO19zzVTbSkhuGGFpO92DVW1mrXtkXkmGiCzyd3hw0BKXNL7',

      // PRODUCCION
      clientId: 'ATDIZ795-vXt11k--ZsclX6uF2WfaCroInhDDXi1D1NYpXFbx1gXATbOf-J3KzOZaI_N7_ZQQnx6CJtp',
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        // application_context: {
        //   locale: 'en_US'
        // },
        purchase_units: [
          {
            amount: {
              currency_code: 'USD',//this.currentExchangeRate.type == 0 ? 'USD' : this.currentExchangeRate.type == 1 ? 'MXN' : 'EUR',
              value: `${finalPrice}`,
              breakdown: {
                item_total: {
                  currency_code: 'USD',//this.currentExchangeRate.type == 0 ? 'USD' : this.currentExchangeRate.type == 1 ? 'MXN' : 'EUR',
                  value: `${finalPrice}`
                }
              }
            },
            items: [
              {
                name: this.infoPurchase.item_info.subtitle,
                quantity: `1`,
                category: 'PHYSICAL_GOODS',
                unit_amount: {
                  currency_code: 'USD',//this.currentExchangeRate.type == 0 ? 'USD' : this.currentExchangeRate.type == 1 ? 'MXN' : 'EUR',
                  value: `${finalPrice}`,
                },
              }
            ]
          }
        ]
      },
      advanced: {
        commit: 'true',
        // locale: 'en_US',
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
        // size: 'responsive',
      },
      onApprove: (data, actions) => {
        this.loaderpage = true;
        actions.order.get().then((details: any) => {
          console.log('transaction was approved');
        });
      },
      onClientAuthorization: async (data) => {
        console.log('completed transaction', data);
        Swal.showLoading();
        try {
          // Swal.fire({
          //   position: 'center',
          //   // icon: 'info',
          //   title: 'Pagando...',
          //   text: 'Por favor no salga de la página',
          //   showConfirmButton: false,
          //   backdrop: false
          // })
          let orderId = this.generateOrderId();
          this.infoPurchase.orderId = orderId;
          // await this.emailService.sendTestMail(this.infoPurchase);
          await this.uploadOrder(this.infoPurchase);
          // await this.reservationService.createReservationPaypal(this.formInformation,data);
          Swal.close();
        } catch (error) {
          Swal.close();
          this.loaderpage = false;
        }
        this.loaderpage = false;
        this.router.navigate(['thanks']);
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        this.loaderpage = false;
        this.shootSimpleAlert('info', this.activeLang === 'es' ? 'Pago no realizado' : 'Unsuccessful payment', '');
      },
      onError: err => {
        console.log('OnError', err);
        this.loaderpage = false;
        // this.shootSimpleAlert('info', 'An error has ocurred', 'Try again later')
        this.shootSimpleAlert('info', this.activeLang === 'es' ? 'Ha ocurrido un error' : 'An error has occurred', this.activeLang === 'es' ? 'Inténtalo de nuevo más tarde' : 'Try again later');
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
        this.loaderpage = true;
        if (data.fundingSource == 'card')
          this.loaderpage = false;
      },

    }
  }

  shootSimpleAlert(icon: SweetAlertIcon, title: string, text?: string) {
    Swal.fire({
      position: 'center',
      icon: icon,
      title: title,
      text: text,
      showConfirmButton: true,
      confirmButtonColor: '#e4c72c',
      confirmButtonText: 'Ok',
    });
  }

  generateOrderId(): string {
    const letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numeros = '0123456789';
    let resultado = '';
    for (let i = 0; i < 3; i++) {
      const letraAleatoria = letras.charAt(Math.floor(Math.random() * letras.length));
      const numeroAleatorio = numeros.charAt(Math.floor(Math.random() * numeros.length));
      resultado += letraAleatoria + numeroAleatorio;
    }
    return resultado;
  }

  async uploadOrder(infoPurchase: any, paymentIntent?: any) {
    infoPurchase.item_info.finalPrice = this.amount;
    // infoPurchase.paymentIntentId = paymentIntent.data.id;
    // infoPurchase.infoPurchase = paymentIntent.data;
    // infoPurchase.orderId = orderId;
    infoPurchase.item_info.serviceType = 1;
    if (infoPurchase.item_info.type == 2) {
      infoPurchase.client_info.arrivalDate = infoPurchase.client_info.departureDate;
      infoPurchase.client_info.arrivalHour = infoPurchase.client_info.departureHour;
      infoPurchase.item_info.serviceType = 2;
    }
    if (infoPurchase.item_info.type == 3) {
      infoPurchase.client_info.departureDate = infoPurchase.client_info.arrivalDate;
      infoPurchase.client_info.departureHour = infoPurchase.client_info.arrivalHour;
      infoPurchase.item_info.serviceType = 3;
    }
    infoPurchase.client_info.arrivalDateFormatted = this.formatFecha(infoPurchase.client_info.arrivalDate, this.activeLang);
    infoPurchase.client_info.departureDateFormatted = this.formatFecha(infoPurchase.client_info.departureDate, this.activeLang);
    infoPurchase.client_info.arrivalHourFormatted = this.formatHora(infoPurchase.client_info.arrivalHour);
    infoPurchase.client_info.departureHourFormatted = this.formatHora(infoPurchase.client_info.departureHour);
    infoPurchase.payment_status = 'success';
    await this.cartService.saveOrder(infoPurchase);
    if (infoPurchase.item_info.type == 1) {
      infoPurchase.client_info.arrivalDate = infoPurchase.client_info.departureDate;
      infoPurchase.client_info.arrivalHour = infoPurchase.client_info.departureHour;
      // infoPurchase.infoPurchase.amount = 0;
      infoPurchase.item_info.finalPrice = 0;
      infoPurchase.paymentIntentId = '';
      infoPurchase.payment_status = 'success';
      if (infoPurchase.client_info.paymentAgencyMethod == 'paymentOf50PercentInCredit') {
        infoPurchase.payment_status = 'paymentOf50PercentInCredit';
      }
      infoPurchase.item_info.serviceType = 2;
      await this.cartService.saveOrder(infoPurchase);
    }
    await this.emailService.sendTestMail(infoPurchase, this.activeLang);
  }

  formatFecha(timestamp, lang) {
    const meses = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto",
      "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];
    const months = [
      "January", "February", "March", "April", "May", "June", "July", "August",
      "September", "October", "November", "December"
    ];
    const fecha = new Date(timestamp);
    const dia = fecha.getUTCDate();
    const mes = lang == 'es' ? meses[fecha.getUTCMonth()] : months[fecha.getUTCMonth()];
    const año = fecha.getUTCFullYear();
    return `${dia} ${mes} ${año}`;
  }

  formatHora(fechaString) {
    const fecha = new Date(fechaString);
    let horas = fecha.getHours();
    const minutos = fecha.getMinutes();
    let ampm = "am";
    console.log('fecha', fecha);
    if (horas > 12) {
      horas -= 12;
      ampm = "pm";
    } else if (horas === 12) {
      ampm = "pm";
    } else if (horas === 0) {
      horas = 12;
    }
    return `${horas}:${minutos.toString().padStart(2, "0")}${ampm}`;
  }

}
