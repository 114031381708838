import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Transfer } from 'src/app/models/transfer.model';
import { TransferService } from 'src/app/services/transfer/transfer.service';

@Component({
  selector: 'app-transfers',
  templateUrl: './transfers.component.html',
  styleUrls: ['./transfers.component.scss']
})
export class TransfersComponent implements OnInit {

  public subs:Subscription;
  public lang:string;

  public transferId: string;
  public hotel: string;
  public transferInfo;

  constructor(
    private transfer_service: TransferService,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.transferId = this.route.snapshot.paramMap.get('id');
    this.hotel = this.route.snapshot.paramMap.get('hotel');
    this.transferInfo = await this.transfer_service.getTransferById(this.transferId);
    this.lang = this.translate.defaultLang;
    this.subs = this.translate.onLangChange.subscribe(lang => {
      this.lang = lang.lang;
    })

  }

}
