<app-search-bar>
</app-search-bar>
<section id="transfer">

    <div class="step">
        <h2>{{'STEP' | translate}}4</h2>
        <h1>{{'FILL_INFO' | translate}}</h1>
    </div>

    <div class="container-transfer" *ngIf="transferInfo">
        <div class="slider-transfer">
            <app-slider-transfer [transferInfo]="transferInfo"></app-slider-transfer>
            <div class="desktop">
                <app-info-transfer [lang]="lang" [transferInfo]="transferInfo"></app-info-transfer>
            </div>
        </div>
        <div class="form-transfer">
            <app-form-transfer [lang]="lang" [transferInfo]="transferInfo" [hotel]="hotel"></app-form-transfer>
        </div>
        <div class="info-transfer mobile">
            <app-info-transfer [lang]="lang" [transferInfo]="transferInfo"></app-info-transfer>
        </div>
    </div>
</section>