import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EmailService } from 'src/app/services/email/email.service';
import { TransferService } from 'src/app/services/transfer/transfer.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { CartService } from '../../services/cart/cart.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

declare var Stripe: stripe.StripeStatic;

@Component({
  selector: 'app-stripe-elements',
  templateUrl: './stripe-elements.component.html',
  styleUrls: ['./stripe-elements.component.scss']
})
export class StripeElementsComponent implements OnInit {

  // lang
  public lang: string;
  private subs: Subscription;
  public stripeLang = ''
  public activeLang = 'en';

  constructor(
    private transferService: TransferService,
    private cartService: CartService,
    private emailService: EmailService,
    private route: ActivatedRoute,
    private router: Router,
    private transferServie: TransferService,
    private translate: TranslateService,
  ) { }

  @Input() amount
  @Input() infoPurchase: any;
  @ViewChild('cardElement') cardElement: ElementRef;

  stripe;
  card;
  cardErrors;
  globalElements;

  loading = false;
  confirmation

  public id: string;
  public transferPurchase;
  // public infoPurchase;
  public infoTransfer;
  public disableMail = false;
  public clientInfo;
  public statusPayment;

  private paymentIntent;
  private paymentIntentId;

  async ngOnInit() {
    this.lang = this.translate.defaultLang;
    this.subs = this.translate.onLangChange.subscribe(lang => {
      console.log('idiomaa', lang.lang);
      this.lang = lang.lang;
    })
    this.activeLang = this.translate.currentLang;
    this.stripeLang = this.translate.currentLang;
    let sub = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          console.log('idiomas', this.translate.currentLang);
          this.activeLang = this.translate.currentLang;
          this.stripeLang = this.translate.currentLang;
        }, 200);
      }
    });

    // if (window.location.href.includes('eng')) {
    //   this.stripeLang = 'en'
    // } else {
    //   this.stripeLang = 'en'
    //   //this.stripeLang = 'es'
    // }

    this.initStripeConfig();
  }

  async initStripeConfig() {
    this.loading = true
    this.amount = parseFloat(this.amount);

    const roundAmount: number = Math.round(this.amount * 100) / 100;

    if (roundAmount < 0.50) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        // text: 'Sorry, we cannot make a payment less than 0.50 USD',
        // title: this.lang == 'es' ? 'Ups!' : 'Oops...',
        text: this.activeLang == 'es' ? `Lo sentimos, no podemos efectuar un pago menor a 0.50 USD` : 'Sorry, we cannot make a payment less than 0.50 USD',
      })
      history.go(-1);
      this.loading = false
      return;
    }

    let response = await this.transferService.paymentIntentStripe(roundAmount);
    if (response.id != undefined) {
      this.paymentIntent = response;
      this.paymentIntentId = response.id;
    }
    let client_secret = response.client_secret;
    const appearance = {
      clientSecret: client_secret,
      appearance: {
        theme: 'stripe',
        variables: {
          colorPrimary: '#0570de',
          colorBackground: '#ffffff',
          colorText: '#30313d',
          colorDanger: '#df1b41',
          fontFamily: 'Ideal Sans, system-ui, sans-serif',
          spacingUnit: '6px',
          borderRadius: '8px',
          // See all possible variables below
        }
      }
    }
    // Stripe key
    this.stripe = Stripe(environment.stripe_pk, {
      locale: this.stripeLang
    });
    const elements = this.stripe.elements(appearance);
    this.globalElements = elements;
    this.card = elements.create('payment', {
      hidePostalCode: true
    });
    this.card.mount(this.cardElement.nativeElement);
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    this.card.addEventListener('change', ({ error }) => {
      this.cardErrors = error && error.message;
    });
  }

  async confirmStripePayment(elements) {
    this.loading = true;
    // this.emailService.sendMailAdmin(this.infoPurchase)
    let paymentIntentIdExists = await this.transferService.getOrderByPaymentIntent(this.paymentIntentId);
    if (paymentIntentIdExists.length == 0) {
      await this.uploadOrder(this.infoPurchase, this.paymentIntentId);
    }
    const { error } = await this.stripe.confirmPayment({
      elements,
      confirmParams: {
        // return_url: 'http://localhost:4200/thanks'
        return_url: this.activeLang == 'es' ? 'https://transfers.mexicotoptours.com/es/thanks' : 'https://transfers.mexicotoptours.com/en/thanks'
      },
    });
    if (error) {
      this.loading = false;
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${error.message}`,
      })
    }
  }

  async uploadOrder(infoPurchase: any, paymentIntentId: string) {
    let orderId = this.generateOrderId();
    infoPurchase.item_info.finalPrice = this.amount;
    infoPurchase.paymentIntentId = paymentIntentId;
    infoPurchase.infoPurchase = this.paymentIntent;
    infoPurchase.orderId = orderId;
    infoPurchase.item_info.serviceType = 1;
    if (infoPurchase.item_info.type == 2) {
      infoPurchase.client_info.arrivalDate = infoPurchase.client_info.departureDate;
      infoPurchase.client_info.arrivalHour = infoPurchase.client_info.departureHour;
      infoPurchase.item_info.serviceType = 2;
    }
    if (infoPurchase.item_info.type == 3) {
      infoPurchase.client_info.departureDate = infoPurchase.client_info.arrivalDate;
      infoPurchase.client_info.departureHour = infoPurchase.client_info.arrivalHour;
      infoPurchase.item_info.serviceType = 3;
    }
    infoPurchase.client_info.arrivalDateFormatted = this.formatFecha(infoPurchase.client_info.arrivalDate, this.activeLang);
    infoPurchase.client_info.departureDateFormatted = this.formatFecha(infoPurchase.client_info.departureDate, this.activeLang);
    infoPurchase.client_info.arrivalHourFormatted = this.formatHora(infoPurchase.client_info.arrivalHour);
    infoPurchase.client_info.departureHourFormatted = this.formatHora(infoPurchase.client_info.departureHour);
    await this.cartService.saveOrder(infoPurchase);
    if (infoPurchase.item_info.type == 1) {
      infoPurchase.client_info.arrivalDate = infoPurchase.client_info.departureDate;
      infoPurchase.client_info.arrivalHour = infoPurchase.client_info.departureHour;
      // infoPurchase.infoPurchase.amount = 0;
      infoPurchase.item_info.finalPrice = 0;
      infoPurchase.paymentIntentId = '';
      infoPurchase.payment_status = 'success';
      if (infoPurchase.client_info.paymentAgencyMethod == 'paymentOf50PercentInCredit') {
        infoPurchase.payment_status = 'paymentOf50PercentInCredit';
      }
      infoPurchase.item_info.serviceType = 2;
      await this.cartService.saveOrder(infoPurchase);
      console.log("Order Type 1: ", infoPurchase);
    }

  }

  async updateOrder(infoPurchase: any, paymentIntentId: string) {
    // Actualizarlo
    infoPurchase.item_info.finalPrice = this.amount;
    infoPurchase.paymentIntentId = paymentIntentId;
    infoPurchase.infoPurchase = this.paymentIntent;
    infoPurchase.payment_status = 'pending';
    this.cartService.saveOrder(infoPurchase);
  }

  generateOrderId(): string {
    const letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numeros = '0123456789';
    let resultado = '';
    for (let i = 0; i < 3; i++) {
      const letraAleatoria = letras.charAt(Math.floor(Math.random() * letras.length));
      const numeroAleatorio = numeros.charAt(Math.floor(Math.random() * numeros.length));
      resultado += letraAleatoria + numeroAleatorio;
    }
    return resultado;
  }

  formatFecha(timestamp, lang) {
    const meses = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto",
      "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];
    const months = [
      "January", "February", "March", "April", "May", "June", "July", "August",
      "September", "October", "November", "December"
    ];
    const fecha = new Date(timestamp);
    const dia = fecha.getUTCDate();
    const mes = lang == 'es' ? meses[fecha.getUTCMonth()] : months[fecha.getUTCMonth()];
    const año = fecha.getUTCFullYear();
    return `${dia} ${mes} ${año}`;
  }

  formatHora(fechaString) {
    const fecha = new Date(fechaString);
    let horas = fecha.getHours();
    const minutos = fecha.getMinutes();
    let ampm = "am";
    console.log('fecha', fecha);
    if (horas > 12) {
      horas -= 12;
      ampm = "pm";
    } else if (horas === 12) {
      ampm = "pm";
    } else if (horas === 0) {
      horas = 12;
    }
    return `${horas}:${minutos.toString().padStart(2, "0")}${ampm}`;
  }
}

