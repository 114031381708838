import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Agency } from 'src/app/models/agency.model';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {

  constructor(
    private afs: AngularFirestore
  ) { }

  async searchAgencyCode(code: string) {
    const querySnapshot = await this.afs.collection<Agency>('agencies', ref => ref.where('agencyId', '==', code)).get().toPromise();
    if (querySnapshot.empty) {
      return []
    } else {
      return querySnapshot.docs.map(doc => doc.data());
    }
  }
}