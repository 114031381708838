import { Component, Input, OnInit } from '@angular/core';
import { Transfer } from 'src/app/models/transfer.model';

@Component({
  selector: 'app-info-transfer',
  templateUrl: './info-transfer.component.html',
  styleUrls: ['./info-transfer.component.scss']
})
export class InfoTransferComponent implements OnInit {

  @Input() transferInfo:Transfer
  @Input() lang:string;

  constructor() { }

  ngOnInit(): void {}

}
