<app-search-bar></app-search-bar>
<div  >
    <div class="head-page">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <p class="h1 title">{{'FORM.CHOOSE' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center order-info" style="padding: 40px; width: 100%; margin-bottom: 50px;;">
        <div class="col-md-10">
            <div class="row">
                <div class="col-md-3">
                    <img [src]="resultZones[0]?.image" alt="" width="200">
                </div>
                <div class="col-md-6">
                    <div class="info-flex transfer-info">
                        <h2>{{resultZones[0]?.title}}</h2>
                        <p *ngIf="resultZones[0]?.type == 1">{{'FORM.ROUNDTRIP' | translate}}</p>
                        <p *ngIf="resultZones[0]?.type == 2">{{'FORM.HOTELTOAIR' | translate}}</p>
                        <p *ngIf="resultZones[0]?.type == 3">{{'FORM.AIRTOHOTEL' | translate}}</p>
                        <p>{{resultZones[0]?.hotel}}</p>
                        <!-- <p>{{resultZones[0]?.subtitle}} ({{resultZones[0]?.adults}} {{'FORM.ADULTS' | translate}}, -->
                            <!-- {{resultZones[0]?.kids}} {{'FORM.KIDS' | translate}})</p> -->
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="price-flex">
                        <p>{{resultZones[0]?.price | currency}}USD</p>
                        <p id="taxes"> {{ 'FORM.TAXES' | translate }} </p>
                        <button (click)="bookNow()" class="btn btn-next">{{'FORM.BOOKNOW' |
                            translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>