<section class="bg-home">
  <div class="form-div">
    <app-search-bar></app-search-bar>
    <form action="" [formGroup]="addForm">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{'FORM.CITY' | translate}}</mat-label>
        <mat-select formControlName="city">
          <mat-option value="cancun">Cancún</mat-option>
          <!-- <mat-option value="cdmx">{{'FORM.CDMX' | translate}}</mat-option> -->
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{'FORM.TYPE' | translate}}</mat-label>
        <mat-select formControlName="tripType">
          <mat-option value="1">{{'FORM.ROUNDTRIP' | translate}}</mat-option>
          <mat-option value="2">{{'FORM.HOTELTOAIR' | translate}}</mat-option>
          <mat-option value="3">{{'FORM.AIRTOHOTEL' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Hotel</mat-label>
        <input type="text" placeholder="Please type 3 characters" aria-label="Hotel" matInput
          [formControl]="hotelsControl" [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredHotels | async" [value]="option.nameEs">
            {{option.nameEs}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <ng-container *ngIf="hotelsControl.value == null && hotelsControl.touched">
        <p class="text-danger">{{'FORM.FIELD-REQUIRED' | translate}}</p>
      </ng-container>

      <div class="full-width two-inputs">
        <div *ngIf="isDesktop">
          <mat-form-field appearance="fill">
            <mat-label>{{'FORM.ADULTS' | translate}}</mat-label>
            <input matInput type="number" formControlName="adults" min="0" max="18">
          </mat-form-field>
          <ng-container *ngIf="addForm.get('adults').hasError('max')">
            <p class="text-danger">{{'FORM.ADULT-MAX' | translate}}</p>
          </ng-container>
        </div>
        <div *ngIf="!isDesktop" style="width: 100%;">
          <mat-form-field appearance="fill">
            <mat-label>{{'FORM.ADULTS' | translate}}</mat-label>
            <mat-select formControlName="adults">
              <mat-option *ngFor="let option of adultsArray" [value]="option">
                {{option}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-container *ngIf="addForm.get('adults').hasError('max')">
            <p class="text-danger">{{'FORM.ADULT-MAX' | translate}}</p>
          </ng-container>
        </div>

        <div *ngIf="isDesktop">
          <mat-form-field appearance="fill">
            <mat-label>{{'FORM.KIDS' | translate}}</mat-label>
            <input matInput value="{{0}}" type="number" formControlName="kids" min="0" max="5">
          </mat-form-field>
          <ng-container *ngIf="addForm.get('kids').hasError('max')">
            <p class="text-danger">{{'FORM.CHILDREN-MAX' | translate}}</p>
          </ng-container>
        </div>
        <div *ngIf="!isDesktop" style="width: 100%;">
          <mat-form-field appearance="fill">
            <mat-label>{{'FORM.KIDS' | translate}}</mat-label>
            <mat-select formControlName="kids">
              <mat-option *ngFor="let option of kidsArray" [value]="option">
                {{option}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-container *ngIf="addForm.get('adults').hasError('max')">
            <p class="text-danger">{{'FORM.ADULT-MAX' | translate}}</p>
          </ng-container>
        </div>
      </div>

      <mat-form-field class="full-width" appearance="fill">
        <mat-label>{{'FORM.AGENCY' | translate}}</mat-label>
        <input type="text" placeholder="Código" aria-label="Hotel" matInput formControlName="agencyId" maxlength="6">
      </mat-form-field>
    </form>
    <button (click)="searchTrip()" class="btn btn-next" [disabled]="!addForm.valid">{{'FORM.NEXT' |
      translate}}</button>
  </div>
  <br>
  <div class="form-div">
    <div class="container contact-container">
      <h1>{{'GROUP.TITLE' | translate}}</h1>
      <br>
      <a *ngIf="lang != 'es'" href="https://www.mexicotoptours.com/group-transportation" target="_blank"
        class="btn btn-contact">{{'GROUP.BUTTON' | translate}}</a>
      <a *ngIf="lang == 'es'" href="https://www.mexicotoptours.com/es-mx/transporte-grupal" target="_blank"
        class="btn btn-contact">{{'GROUP.BUTTON' | translate}}</a>
    </div>
  </div>
</section>
