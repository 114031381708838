import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


import { HotelsService } from 'src/app/services/hotels/hotels.service';
import { MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AgencyService } from '../services/agencies/agency.service';

@Component({
  selector: 'app-edit-checkout-info',
  templateUrl: './edit-checkout-info.component.html',
  styleUrls: ['./edit-checkout-info.component.scss']
})
export class EditCheckoutInfoComponent implements OnInit {


  public editForm: FormGroup;
  private subs: Subscription;
  public hotelsControl = new FormControl();
  public hotelsTransfers = [];
  public filteredHotels: Observable<string[]>;
  public resultZones = [];
  public currentStep = 1;
  public newValues = [];
  public lang: string;
  public langSpanish: boolean;
  public transferData: any;
  isDesktop: boolean = true;
  public adultsArray = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18']
  public kidsArray = ['0', '1', '2', '3', '4', '5']

  constructor(

    private formBuilder: FormBuilder,
    private hotelService: HotelsService,
    private dialogRef: MatDialogRef<EditCheckoutInfoComponent>,
    private translate: TranslateService,
    private router: Router,
    public route: ActivatedRoute,
    private agencyService: AgencyService,
  ) {

    this.editForm = this.formBuilder.group({
      city: ['', Validators.required],
      tripType: ['', Validators.required],
      adults: [, [Validators.required, Validators.max(18)]],
      kids: [, [Validators.required, Validators.max(5)]],
      agencyId: []
    });

    this.isDesktop = window.innerWidth >= 480;

  }

  ngOnInit(): void {
    this.getForm()


    //   this.route.queryParams.subscribe(async params => {
    //     this.transferData = JSON.parse(params.data);
    //     console.log("data Completa", this.transferData);
    //     this.editForm = this.formBuilder.group({
    //       city: ["cancun", Validators.required],
    //       tripType: [this.transferData.type, Validators.required],
    //       adults: [this.transferData.adults, Validators.required],
    //       kids: [this.transferData.kids, Validators.required],
    //     });
    //     if(this.transferData.title == "Cancún"){
    //       this.editForm.controls['city'].setValue("cancun")
    //     }else{
    //       this.editForm.controls['city'].setValue("cdmx")
    //     }

    //     this.hotelsControl.setValue(this.transferData.hotel)
    // })



    this.lang = this.translate.defaultLang;
    this.subs = this.translate.onLangChange.subscribe(lang => {
      this.lang = lang.lang;
    })

    if (this.router.url.includes('/en')) {
      this.langSpanish = false;
    } else {
      this.langSpanish = true;
    }

    this.findHotel('cancun');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isDesktop = window.innerWidth >= 480; // Puedes ajustar el valor 768 según tus necesidades
  }

  _filterHotels(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.hotelsTransfers.filter(option => option.nameEs.toLowerCase().includes(filterValue));
  }

  findHotel(e: any) {
    this.hotelService.searchHotelTrasnfers(e).subscribe(res => {
      this.hotelsTransfers = res;
      this.filteredHotels = this.hotelsControl.valueChanges.pipe(
        startWith(this.hotelsControl.value),
        map(value => this._filterHotels(value))
      );
    })
  }

  async save() {
    var form = this.editForm.value
    var newValues = {
      adults: form.adults,
      city: form.city,
      hotel: this.hotelsControl.value,
      kids: form.kids,
      tripType: form.tripType,
      agencyId: form.agencyId,
    }

    const isValidCode = await this.validateAgencyCode(newValues.agencyId);
    if (!isValidCode && newValues.agencyId != '') {
      Swal.fire({
        icon: 'info',
        title: this.lang == 'es' ? 'No encontramos el código ingresado' : "We can't find the code entered",
        text: this.lang == 'es' ? 'Por favor, verifique que el código sea correcto' : 'Please verify that the code is correct',
        confirmButtonText: this.lang == 'es' ? 'Aceptar' : 'Ok',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonColor: '#e5514e',
      })
      return
    }

    if (newValues.hotel == null) {
      this.hotelsControl.markAllAsTouched();
      Swal.fire({
        icon: 'info',
        title: this.langSpanish == true ? 'Asegúrate de llenar todos los campos' : 'Make sure you fill in all the fields',
        text: '',
        confirmButtonText: this.lang == 'es' ? 'Aceptar' : 'Ok',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonColor: '#e5514e',
      })
      return;
    }

    if ((parseInt(newValues.adults) <= 0)) {
      Swal.fire({
        icon: 'info',
        title: this.lang == 'es' ? 'Por favor seleccione un número de adultos' : 'Please select a number of adults',
        text: '',
        confirmButtonText: this.lang == 'es' ? 'Aceptar' : 'Ok',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonColor: '#e5514e',
      })
      return
    }

    if ((parseInt(newValues.adults) + parseInt(newValues.kids) <= 0)) {
      Swal.fire({
        icon: 'info',
        title: this.langSpanish == true ? 'Asegúrate de colocar al menos una persona' : 'Make sure to place at least one person',
        text: '',
        confirmButtonText: this.lang == 'es' ? 'Aceptar' : 'Ok',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonColor: '#e5514e',
      })
      return;
    }

    console.log('newValues', newValues);

    if ((parseInt(newValues.adults) + parseInt(newValues.kids) > 18)) {
      Swal.fire({
        icon: 'info',
        title: this.lang == 'es' ? 'La cantidad máxima de pasajeros permitidos es 18' : 'Maximum number of passengers allow is 18',
        text: '',
        confirmButtonText: this.lang == 'es' ? 'Aceptar' : 'Ok',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonColor: '#e5514e',
      })
      return;
    }

    this.updateAgencyCode(newValues.agencyId);

    this.dialogRef.close(
      { newValues }
    )
    this.saveForm()
    this.getForm()
  }

  close() {
    this.dialogRef.close()
  }

  saveForm() {
    localStorage.setItem('adults', this.editForm.value.adults)
    localStorage.setItem('city', this.editForm.value.city)
    localStorage.setItem('hotel', this.hotelsControl.value)
    localStorage.setItem('kids', this.editForm.value.kids)
    localStorage.setItem('tripType', this.editForm.value.tripType)
    localStorage.setItem('agencyId', this.editForm.value.agencyId)
  }
  getForm() {
    var newValues = {
      adults: localStorage.getItem('adults'),
      city: localStorage.getItem('city'),
      hotel: localStorage.getItem('hotel'),
      kids: localStorage.getItem('kids'),
      tripType: localStorage.getItem('tripType'),
      agencyId: localStorage.getItem('agencyId') == 'null' ? '' : localStorage.getItem('agencyId'),
    }

    this.editForm.controls['city'].setValue(newValues.city)
    this.editForm.controls['tripType'].setValue(newValues.tripType)
    this.editForm.controls['adults'].setValue(newValues.adults)
    this.hotelsControl.setValue(newValues.hotel)
    this.editForm.controls['kids'].setValue(newValues.kids)
    this.editForm.controls['agencyId'].setValue(newValues.agencyId)
  }

  async validateAgencyCode(agencyCode: string) {
    const response = await this.agencyService.searchAgencyCode(agencyCode);
    return response.length == 1
  }

  updateAgencyCode(agencyCode: string) {
    if (agencyCode) {
      const queryParams = { ...this.router.parseUrl(this.router.url).queryParams };
      queryParams['agencyCode'] = agencyCode;

      this.router.navigate([], { queryParams: queryParams, queryParamsHandling: 'merge' });
    } else {
      return;
    }
  }

}
