import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { RouterListenersService } from 'src/app/services/translate-service/router-listeners/router-listeners.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public lang: string = 'en';
  private subs: Subscription;
  public toggle = false;


  // * Submenus
  public showTripsMenu = false;
  public showToursMenu = false;
  public showCancunTours = false;
  public showMexicoTours = false



  showToursSubmenu = false;
  showTripsSubmenu = false;



  showExcursions = false;
  showExcursions1 = false;
  showExcursions2 = false;
  showExcursions3 = false;
  showExcursions4 = false;
  showExcursions5 = false;
  showExcursions6 = false;
  timedOutCloser;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private listenerService: RouterListenersService,
  ) { }

  ngOnInit(): void {
    this.lang = this.translate.defaultLang;
    this.subs = this.translate.onLangChange.subscribe(lang => {
      this.lang = lang.lang;
    })
  }

  public change(e) {
    this.lang = e;
    this.translate.use(this.lang);
    this.listenerService.navigateToSameRoute(e);
  }

  changeToggle() {
    this.toggle = !this.toggle
  }

  openMyMenu(menuTrigger: MatMenuTrigger) {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    menuTrigger.openMenu();
  }

  closeMyMenu(menuTrigger: MatMenuTrigger) {
    this.timedOutCloser = setTimeout(() => {
      menuTrigger.closeMenu();
    }, 50);
  }
}
