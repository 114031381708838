import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  showToursSubmenu = false;
  showTripsSubmenu = false;

  showCancunTours = false;
  showMexicoTours = false;

  showExcursions = false;
  showExcursions1 = false;
  showExcursions2 = false;
  showExcursions3 = false;
  showExcursions4 = false;
  showExcursions5 = false;
  showExcursions6 = false;
  dropdownPosition = '0px';
  public lang: string = 'en';
  private subs: Subscription;

  constructor(private translate: TranslateService,) { }

  ngOnInit(): void {
    this.lang = this.translate.defaultLang;
    this.subs = this.translate.onLangChange.subscribe(lang => {
      this.lang = lang.lang;
    })
  }

  calculateDropdownPosition() {
    const dropdown = document.querySelector('.dropdown');
    if (dropdown) {
      const rect = dropdown.getBoundingClientRect();
      this.dropdownPosition = `${rect.left}px`;
    }
  }

  mouseEnter() {
    this.showToursSubmenu = true
  }
}
