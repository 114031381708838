<ng-container *ngIf="loading">
  <div class="spinner">
  </div>
</ng-container>

<form id="payment-form">

  <!-- A Stripe element will be inserted here -->
  <div id="cardElement" #cardElement>

  </div>

  <!-- Used to display Element errors. -->
  <p>{{cardErrors}}</p>
  <button class="btn-buy" (click)="confirmStripePayment(globalElements)">{{'STRIPE-FORM.BTN-RESERVATION' |
    translate}}</button>
</form>
