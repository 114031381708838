import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CartService } from 'src/app/services/cart/cart.service';
import { EmailService } from 'src/app/services/email/email.service';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { EditCheckoutInfoComponent } from '../../edit-checkout-info/edit-checkout-info.component';
import { CouponService } from 'src/app/services/coupon/coupon.service';
import { Coupon } from '../../models/coupon.model';
import Swal from 'sweetalert2';
import { HotelsService } from 'src/app/services/hotels/hotels.service';
import { AirlinesService } from '../../services/airlines/airlines.service';
import { invalid } from '@angular/compiler/src/render3/view/util';
import { AgencyService } from 'src/app/services/agencies/agency.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { MatAccordion } from '@angular/material/expansion';
import { MatExpansionPanel } from '@angular/material/expansion';

//Date pikcer
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { TranslateService } from '@ngx-translate/core';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  // providers: [
  //     {
  //         provide: DateAdapter,
  //         useClass: MomentDateAdapter,
  //         deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  //     },

  //     { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  // ],
})
export class CheckoutComponent implements OnInit {

  public loader: boolean = false;
  public terms: boolean = false;
  public notify: boolean = false;
  public paySection: boolean = false;
  public actualMethodPay: string = 'openpay';

  public submitForm: FormGroup;
  public emailControl: FormControl;

  public comment: string = '';
  public infoOrder: any;
  public dialogRef: MatDialogRef<any>;
  public agencyPaymentMethods = [];
  public agencyDiscount: number = 0;

  public transferData: any;
  public minDate = new Date();
  public departureDate = new Date();

  public airLines: any[] = [];

  // NG2Tel
  public phoneDialCodeSelected: string = '+52';
  public phoneIsoCodeSelected: string = 'mx';
  public inputObj: any;

  //Coupons
  public couponInput: string = '';
  public couponUsed: boolean = false;
  public coupon: Coupon;
  public finalPrice: number = 0;
  public newFinalPrice: number = 0;
  // public langSpanish: boolean;

  public maxDate: any;

  public dateParse: any = ""
  public firstIsOpen: boolean = false;
  public secondIsOpen: boolean = false;
  public thirdIsOpen: boolean = false;

  public agency: any;
  public activeLang = 'en';
  public hotelZoneId = '';
  public hotelZoneNameEs = '';
  public hotelZoneSelected: any = null;
  public transporter = "https://firebasestorage.googleapis.com/v0/b/mexico-top-tours.appspot.com/o/Volkswagen%20transporter%20(1).png?alt=media&token=7bf9caaa-acf6-49fe-b809-74c05b39ad67"
  public toyota = "https://firebasestorage.googleapis.com/v0/b/mexico-top-tours.appspot.com/o/Toyota%20hiace.png?alt=media&token=ac6770ee-1851-446a-9cd6-490f2d8fcfa6"
  public transporters = "https://firebasestorage.googleapis.com/v0/b/guerreros-del-voto.appspot.com/o/camionetas-top-tours%2FDos-Volkswagen.png?alt=media&token=552787de-5eac-47dd-a359-e9a00d5089c4"
  public toyotas = "https://firebasestorage.googleapis.com/v0/b/guerreros-del-voto.appspot.com/o/camionetas-top-tours%2FDos-Toyota%20hiace%20(1).png?alt=media&token=ccc97e72-b10e-4b2e-b430-89c819a9beec"


  @ViewChild('picker') picker!: MatDatepicker<Date>;
  @ViewChild('picker2') picker2!: MatDatepicker<Date>;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('myPanel') myPanel: MatExpansionPanel;
  @ViewChild('acordion3') acordion3: MatExpansionPanel;

  public showWarning: string = '';

  //PAYPAL
  public payPalConfig?: IPayPalConfig;
  public loaderpage: boolean = false;


  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private router: Router,
    private couponService: CouponService,
    private airlinesService: AirlinesService,
    private hotelService: HotelsService,
    private agencyService: AgencyService,
    private cartService: CartService,
    private mailService: EmailService,
    private datePipe: DatePipe,
    private translate: TranslateService,
  ) {
    this.submitForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,63}')]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', [Validators.required]],
      // para arrival
      arrival_airline: ['', Validators.required],
      arrival_flightNumber: ['', Validators.required],
      arrivalDate: ['', Validators.required],
      arrivalHour: ['', Validators.required],
      // para departure
      departure_airline: ['', Validators.required],
      departure_flightNumber: ['', Validators.required],
      departureDate: ['', Validators.required],
      departureHour: ['', Validators.required],
      paymentAgencyMethod: ['']

      // email: ['c.oviedo@dappertechnologies.com', [Validators.required, Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,63}')]],
      // firstName: ['Josue', Validators.required],
      // lastName: ['Oviedo', Validators.required],
      // phoneNumber: ['9984131699', [Validators.required]],
      // // para arrival
      // arrival_airline: ['VIVA', Validators.required],
      // arrival_flightNumber: ['001', Validators.required],
      // arrivalDate: ['', Validators.required],
      // arrivalHour: ['', Validators.required],
      // // para departure
      // departure_airline: ['MEX', Validators.required],
      // departure_flightNumber: ['002', Validators.required],
      // departureDate: ['', Validators.required],
      // departureHour: ['', Validators.required],
      // paymentAgencyMethod: ['']
    })
  }

  async ngOnInit() {

    this.activeLang = this.translate.currentLang;
    this.phoneDialCodeSelected = '+52'
    this.phoneIsoCodeSelected = 'mx';

    let sub = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.activeLang = this.translate.currentLang;
        }, 200);
      }
    });

    this.route.queryParams.subscribe(async params => {
      this.transferData = JSON.parse(params.data);
      console.log(this.transferData);

      this.showWarning = this.containsHolboxOrChiquila(this.transferData.hotel);

      this.validateTransferDataFields(this.transferData.type)
      this.initConfigCoupon();

      if (this.transferData.agencyCode != '') {
        this.submitForm.controls['paymentAgencyMethod'].setValidators(Validators.required);
        this.getAgencyPaymentMethods(this.transferData.agencyCode);
        this.submitForm.updateValueAndValidity();
      } else {
        this.submitForm.controls['paymentAgencyMethod'].clearValidators();
        this.submitForm.controls['paymentAgencyMethod'].updateValueAndValidity();
      }

    })

    this.airLines = this.airlinesService.airLines;

    const formLS = this.getFormLS();

    if (formLS != null) {
      formLS.arrivalDate = new Date(formLS.arrivalDate);
      formLS.departureDate = new Date(formLS.departureDate);

      formLS.arrivalHour = new Date(formLS.arrivalHour);
      formLS.departureHour = new Date(formLS.departureHour);

      this.submitForm.patchValue(formLS);
    }

    const toDay: Date = new Date(); // Fecha actual
    const maxDate: Date = new Date(toDay.getFullYear() + 2, toDay.getMonth(), toDay.getDate());
    this.maxDate = maxDate;
    this.datePipe = new DatePipe('en-US');
    let hotel: any = await this.getTransferInformationByHotelName(this.transferData.hotel);
    this.hotelZoneId = hotel[0].zone
    this.hotelZoneSelected = await this.hotelService.getZone(hotel[0].zone)
    this.hotelZoneNameEs = this.hotelZoneSelected.nameEs
  }

  containsHolboxOrChiquila(input: string): any {
    const lowercaseInput = input.toLowerCase();
    console.log(lowercaseInput);
    if (lowercaseInput.includes("holbox") || lowercaseInput.includes("chiquila")) {
      return this.showWarning = 'WARNING_MESSAGE-HOLBOX'
    }
    else if (lowercaseInput.includes("isla mujeres") || lowercaseInput.includes("puerto juárez")) {
      return this.showWarning = 'WARNING_MESSAGE-MUJERES'
    }
    else if (lowercaseInput.includes("cozumel")) {
      return this.showWarning = 'WARNING_MESSAGE-COZUMEL'
    }
    else {
      return '';
    }
    // return lowercaseInput.includes("holbox") || lowercaseInput.includes("chiquila") || lowercaseInput.includes("isla mujeres") || lowercaseInput.includes("puerto juárez");
  }

  async getAgencyPaymentMethods(agencyCode: string) {
    try {
      const response = await this.agencyService.searchAgencyCode(agencyCode);
      this.agency = await this.agencyService.searchAgencyCode(agencyCode);
      this.agencyPaymentMethods = response[0].paymentTypes
      return response
    } catch (error) {
      console.log('error al solicitar pagos de la agencia', error);
    }
  }

  validateTransferDataFields(type: any) {
    switch (type) {
      // all required
      case '1':
      case 1:
        this.submitForm.get('arrival_airline').setValidators(Validators.required);
        this.submitForm.get('arrival_flightNumber').setValidators(Validators.required);
        this.submitForm.get('arrivalDate').setValidators(Validators.required);
        this.submitForm.get('arrivalHour').setValidators(Validators.required);
        this.submitForm.get('departure_airline').setValidators(Validators.required);
        this.submitForm.get('departure_flightNumber').setValidators(Validators.required);
        this.submitForm.get('departureDate').setValidators(Validators.required);
        this.submitForm.get('departureHour').setValidators(Validators.required);
        break;
      // Exit only
      case '2':
      case 2:
        this.submitForm.get('arrival_airline').clearValidators();
        this.submitForm.get('arrival_flightNumber').clearValidators();
        this.submitForm.get('arrivalDate').clearValidators();
        this.submitForm.get('arrivalHour').clearValidators();
        this.submitForm.get('departure_airline').setValidators(Validators.required);
        this.submitForm.get('departure_flightNumber').setValidators(Validators.required);
        this.submitForm.get('departureDate').setValidators(Validators.required);
        this.submitForm.get('departureHour').setValidators(Validators.required);
        break;
      // arrival only
      case '3':
      case 3:
        this.submitForm.get('departure_airline').clearValidators();
        this.submitForm.get('departure_flightNumber').clearValidators();
        this.submitForm.get('departureDate').clearValidators();
        this.submitForm.get('departureHour').clearValidators();
        this.submitForm.get('arrival_airline').setValidators(Validators.required);
        this.submitForm.get('arrival_flightNumber').setValidators(Validators.required);
        this.submitForm.get('arrivalDate').setValidators(Validators.required);
        this.submitForm.get('arrivalHour').setValidators(Validators.required);
        break;
      default:
        this.submitForm.get('arrival_airline').setValidators(Validators.required);
        this.submitForm.get('arrival_flightNumber').setValidators(Validators.required);
        this.submitForm.get('arrivalDate').setValidators(Validators.required);
        this.submitForm.get('arrivalHour').setValidators(Validators.required);
        this.submitForm.get('departure_airline').setValidators(Validators.required);
        this.submitForm.get('departure_flightNumber').setValidators(Validators.required);
        this.submitForm.get('departureDate').setValidators(Validators.required);
        this.submitForm.get('departureHour').setValidators(Validators.required);
        break;
    }
    this.submitForm.get('arrival_airline').updateValueAndValidity();
    this.submitForm.get('arrival_flightNumber').updateValueAndValidity();
    this.submitForm.get('arrivalDate').updateValueAndValidity();
    this.submitForm.get('arrivalHour').updateValueAndValidity();
    this.submitForm.get('departure_airline').updateValueAndValidity();
    this.submitForm.get('departure_flightNumber').updateValueAndValidity();
    this.submitForm.get('departureDate').updateValueAndValidity();
    this.submitForm.get('departureHour').updateValueAndValidity();
  }

  changeDate(event: MatDatepickerInputEvent<Date>) {
    this.submitForm.controls.departureDate.reset();
    // const formattedDate = this.datePipe.transform(event.value, 'MMMM d yy');
    // this.dateParse = formattedDate;
    this.departureDate = this.submitForm.controls.arrivalDate.value;
  }

  getPercent(price) {
    var percent = ((16 / 100) * price);
    return percent;
  }
  getTaxes(price) {
    var percent = (price / 1.16);
    return percent;
  }

  private initConfigCoupon() {

    let finalPrice = this.getPercent(this.transferData.price) + this.transferData.price;

    //Coupon
    this.finalPrice = this.transferData.price;
    if (this.couponUsed) {
      if (this.finalPrice > 0) {
        this.newFinalPrice = this.finalPrice * (1 - this.coupon.discount)
      } else {
        this.newFinalPrice = this.finalPrice;
      }
    } else {
      this.newFinalPrice = this.finalPrice;
    }
  }

  async initConfig(): Promise<void> {
    let termsMessage: string = ''
    if (this.activeLang == 'es') {
      termsMessage = 'Debe aceptar los Términos y Condiciones'
    } else {
      termsMessage = 'You must accept the terms and conditions'
    }

    if (!this.terms) {
      Swal.fire({
        icon: 'info',
        title: termsMessage,
      })
      return
    }

    this.validateTransferDataFields(this.transferData.type)
    if (this.submitForm.invalid) {
      this.submitForm.markAllAsTouched();
      let termsMessage: string = ''
      if (this.activeLang == 'es') {
        termsMessage = 'Completa el formulario'
      } else {
        termsMessage = 'Complete the form'
      }
      Swal.fire({
        icon: 'info',
        title: termsMessage,
      })
    } else {
      await this.onPurchaseComplete();
      console.log('FORMULARIO', this.infoOrder.client_info);
      // return
      let isValid = this.checkMinimumPrice();
      if (!isValid) return;
      window.scrollTo(0, 0);
      this.setFormLS();
      this.paySection = true;
    }

  }

  checkMinimumPrice() {
    let amount = parseFloat(this.newFinalPrice.toString());

    const roundAmount: number = Math.round(amount * 100) / 100;

    if (roundAmount < 0.50) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        // text: 'Sorry, we cannot make a payment less than 0.50 USD',
        // title: this.lang == 'es' ? 'Ups!' : 'Oops...',
        text: this.activeLang == 'es' ? `Lo sentimos, no podemos efectuar un pago menor a 0.50 USD` : 'Sorry, we cannot make a payment less than 0.50 USD',
      })
      return false;
    }
    return true
  }

  private setFormLS() {
    let form = this.submitForm.value;
    window.localStorage.setItem('flightInfo', JSON.stringify(form));
  }

  private getFormLS() {
    const flightInfo = localStorage.getItem('flightInfo');
    return JSON.parse(flightInfo);
  }


  public openDialog(type: number) {
    this.dialogRef = this.dialog.open(ErrorModalComponent, {
      width: '340px',
      disableClose: true,
      data: { type: type }
    });
  }

  async onPurchaseComplete() {
    let form = this.submitForm.value;
    // form.phoneNumber = `${this.phoneDialCodeSelected}${form.phoneNumber}`
    form.dialCode = this.phoneDialCodeSelected
    form.language = this.activeLang

    // form.arrivalDate = form.arrivalDate.toISOString()
    // form.departureDate = form.departureDate.toISOString()
    this.transferData.hotelZoneId = this.hotelZoneId
    this.transferData.hotelZoneNameEs = this.hotelZoneNameEs
    this.transferData.hotelZoneSelected = this.hotelZoneSelected
    let order: any = {
      item_info: this.transferData,
      client_info: form,
      createdAt: new Date(),
      client: 'Web'
    }

    if (this.transferData.agencyCode != '') {
      const agency = await this.getAgencyPaymentMethods(this.transferData.agencyCode)
      order.agency = agency[0]
      order.client = agency[0].name
      order.payment_status = order.client_info.paymentAgencyMethod
      const newFinalPrice = await this.validateAgencyPaymentPrice(order)
      order.item_info.finalPrice = newFinalPrice;
    }

    // console.log('orden final', order);


    this.infoOrder = order;
  }

  async validateAgencyPaymentPrice(order: any) {
    switch (order.client_info.paymentAgencyMethod) {
      case 'fullPaymentWithoutCredit':
        this.newFinalPrice = this.newFinalPrice
        return this.newFinalPrice;

      case 'paymentOf50PercentInCredit':
        this.agencyDiscount = this.newFinalPrice / 2;
        this.newFinalPrice = this.newFinalPrice / 2;
        return this.newFinalPrice;

      case 'paymentOf100PercentInCredit':
        order.item_info.finalPrice = this.newFinalPrice;
        await this.upload100PercentOrder(order)
        const queryParams = {
          credit_payment: true
        }

        this.router.navigate(['/thanks'], { queryParams })
        break;

      default: this.newFinalPrice = this.newFinalPrice
        break;
    }
  }

  async uploadOrderOld(infoPurchase: any) {
    infoPurchase.payment_status = 'pending';
    this.cartService.saveOrder(infoPurchase);
  }

  async upload100PercentOrder(infoPurchase: any) {

    let orderId = this.generateOrderId();

    // infoPurchase.item_info.finalPrice = this.amount;
    // infoPurchase.paymentIntentId = paymentIntentId;
    // infoPurchase.infoPurchase = this.paymentIntent;
    infoPurchase.orderId = orderId;
    infoPurchase.item_info.serviceType = 1;

    if (infoPurchase.item_info.type == 2) {
      infoPurchase.client_info.arrivalDate = infoPurchase.client_info.departureDate;
      infoPurchase.client_info.arrivalHour = infoPurchase.client_info.departureHour;
      infoPurchase.item_info.serviceType = 2;
    }

    if (infoPurchase.item_info.type == 3) {
      infoPurchase.client_info.departureDate = infoPurchase.client_info.arrivalDate;
      infoPurchase.client_info.departureHour = infoPurchase.client_info.arrivalHour;
      infoPurchase.item_info.serviceType = 3;
    }

    infoPurchase.client_info.arrivalDateFormatted = this.formatFecha(infoPurchase.client_info.arrivalDate, this.activeLang);
    infoPurchase.client_info.departureDateFormatted = this.formatFecha(infoPurchase.client_info.departureDate, this.activeLang);

    infoPurchase.client_info.arrivalHourFormatted = this.formatHora(infoPurchase.client_info.arrivalHour);
    infoPurchase.client_info.departureHourFormatted = this.formatHora(infoPurchase.client_info.departureHour);

    await this.cartService.saveOrder(infoPurchase);

    if (infoPurchase.item_info.type == 1) {
      infoPurchase.client_info.arrivalDate = infoPurchase.client_info.departureDate;
      infoPurchase.client_info.arrivalHour = infoPurchase.client_info.departureHour;
      // infoPurchase.infoPurchase.amount = 0;
      infoPurchase.item_info.finalPrice = 0;
      infoPurchase.paymentIntentId = '';
      // infoPurchase.payment_status = 'success';
      infoPurchase.item_info.serviceType = 2;

      await this.cartService.saveOrder(infoPurchase);
      console.log("Order Type 1: ", infoPurchase);
    }

  }

  generateOrderId(): string {
    const letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numeros = '0123456789';
    let resultado = '';

    for (let i = 0; i < 3; i++) {
      const letraAleatoria = letras.charAt(Math.floor(Math.random() * letras.length));
      const numeroAleatorio = numeros.charAt(Math.floor(Math.random() * numeros.length));
      resultado += letraAleatoria + numeroAleatorio;
    }

    return resultado;
  }

  formatFecha(timestamp, lang) {
    const meses = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto",
      "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    const months = [
      "January", "February", "March", "April", "May", "June", "July", "August",
      "September", "October", "November", "December"
    ];

    const fecha = new Date(timestamp);
    const dia = fecha.getUTCDate();
    const mes = lang == 'es' ? meses[fecha.getUTCMonth()] : months[fecha.getUTCMonth()];
    const año = fecha.getUTCFullYear();

    return `${dia} ${mes} ${año}`;
  }


  formatHora(fechaString) {
    const fecha = new Date(fechaString);
    let horas = fecha.getHours();
    const minutos = fecha.getMinutes();
    let ampm = "am";
    console.log('fecha', fecha);

    // Ajustamos las horas para formato de 12 horas (am/pm)
    if (horas > 12) {
      horas -= 12;
      ampm = "pm";
    } else if (horas === 12) {
      ampm = "pm";
    } else if (horas === 0) {
      horas = 12;
    }

    return `${horas}:${minutos.toString().padStart(2, "0")}${ampm}`;
  }

  editData() {
    const dialogRef = this.dialog.open(EditCheckoutInfoComponent, {
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result == undefined) {
        return;
      }
      console.log(result);
      let adults = parseInt(result.newValues.adults);
      let kids = parseInt(result.newValues.kids);
      this.transferData.persons = adults + kids;
      this.transferData.hotel = result.newValues.hotel;
      this.showWarning = this.containsHolboxOrChiquila(this.transferData.hotel);

      this.transferData.type = result.newValues.tripType;
      this.transferData.adults = result.newValues.adults;
      this.transferData.kids = result.newValues.kids;
      this.agency = await this.agencyService.searchAgencyCode(result.newValues.agencyCode ?? '');
      this.transferData.agencyCode = result.newValues.agencyCode ?? ''
      if (this.transferData.agencyCode == '') {
        this.submitForm.controls['paymentAgencyMethod'].clearValidators();
        this.submitForm.controls['paymentAgencyMethod'].updateValueAndValidity();
      }
      // TODO: Obtener id del hotel
      let hotelId = await this.getTransferInformationByHotelName(this.transferData.hotel);

      // TODO:Obtener información del hotel
      let hotelInfo = hotelId[0];
      this.hotelZoneId = hotelInfo['zone'];
      let zone = await this.hotelService.getZone(hotelInfo['zone']);
      console.log(zone);
      this.hotelZoneSelected = zone
      this.hotelZoneNameEs = zone.nameEs
      // SET IMAGE
      if (this.transferData.persons <= 7) this.transferData.image = this.transporter
      if (this.transferData.persons >= 8 && this.transferData.persons <= 9) this.transferData.image = this.toyota
      if (this.transferData.persons >= 10 && this.transferData.persons <= 14) this.transferData.image = this.transporters
      if (this.transferData.persons >= 15) this.transferData.image = this.toyotas

      // TODO: Generar nuevo precio con base al pax
      console.log(this.transferData);

      switch (this.transferData.type) {
        case "1":
          if (this.transferData.persons <= 3) {
            this.transferData.title = zone.nameEs,
              this.transferData.price = zone.roundTripPricesPaxOne > 0 ? zone.roundTripPricesPaxOne : 10;
            // this.transferData.image = zone.image
            this.transferData.subtitle = '1-3 pax';
          }
          if (this.transferData.persons >= 4 && this.transferData.persons <= 7) {
            this.transferData.title = zone.nameEs,
              this.transferData.subtitle = '4-7 pax';
            // this.transferData.image = zone.image
            this.transferData.price = zone.roundTripPricesPaxTwo > 0 ? zone.roundTripPricesPaxTwo : 10;
          }
          if (this.transferData.persons >= 8 && this.transferData.persons <= 9) {
            this.transferData.title = zone.nameEs,
              this.transferData.subtitle = '8-9 pax';
            // this.transferData.image = zone.image
            this.transferData.price = zone.roundTripPricesPaxThree > 0 ? zone.roundTripPricesPaxThree : 10;
          }
          if (this.transferData.persons >= 10 && this.transferData.persons <= 13) {
            this.transferData.title = zone.nameEs,
              this.transferData.subtitle = '10-13 pax';
            // this.transferData.image = zone.image
            this.transferData.price = zone.roundTripPricesPaxFour > 0 ? zone.roundTripPricesPaxFour : 10;
          }
          if (this.transferData.persons >= 14 && this.transferData.persons <= 18) {
            this.transferData.title = zone.nameEs,
              this.transferData.subtitle = '14-18 pax';
            // this.transferData.image = zone.image
            this.transferData.price = zone.roundTripPricesPaxFive > 0 ? zone.roundTripPricesPaxFive : 10;
          }
          if (this.transferData.persons > 18) {
            this.transferData.title = zone.nameEs,
              this.transferData.subtitle = '18+ pax';
            // this.transferData.image = zone.image
            this.navigateToLimitPax(this.transferData);
            return;
          }
          if (this.agency && this.agency.length > 0 && this.agency != null) {
            let agency = this.agency[0].tariff.key;

            if (this.transferData.persons <= 3) {
              this.transferData.title = zone.nameEs,
                this.transferData.price = this.validatePrice(zone[agency].roundTripPricesPaxOne, zone.roundTripPricesPaxOne);
              // this.transferData.image = zone.image
              this.transferData.subtitle = '1-3 pax';
            }
            if (this.transferData.persons >= 4 && this.transferData.persons <= 7) {
              this.transferData.title = zone.nameEs,
                this.transferData.subtitle = '4-7 pax';
              // this.transferData.image = zone.image
              this.transferData.price = this.validatePrice(zone[agency].roundTripPricesPaxTwo, zone.roundTripPricesPaxTwo);
            }
            if (this.transferData.persons >= 8 && this.transferData.persons <= 9) {
              this.transferData.title = zone.nameEs,
                this.transferData.subtitle = '8-10 pax';
              // this.transferData.image = zone.image
              this.transferData.price = this.validatePrice(zone[agency].roundTripPricesPaxThree, zone.roundTripPricesPaxThree);
            }
            if (this.transferData.persons >= 10 && this.transferData.persons <= 13) {
              this.transferData.title = zone.nameEs,
                this.transferData.subtitle = '10-13 pax';
              // this.transferData.image = zone.image
              this.transferData.price = this.validatePrice(zone[agency].roundTripPricesPaxFour, zone.roundTripPricesPaxFour);
            }
            if (this.transferData.persons >= 14 && this.transferData.persons <= 18) {
              this.transferData.title = zone.nameEs,
                this.transferData.subtitle = '14-18 pax';
              // this.transferData.image = zone.image
              this.transferData.price = this.validatePrice(zone[agency].roundTripPricesPaxFive, zone.roundTripPricesPaxFive);
            }
            if (this.transferData.persons > 18) {
              this.transferData.title = zone.nameEs,
                this.transferData.subtitle = '18+ pax';
              // this.transferData.image = zone.image
              this.navigateToLimitPax(this.transferData);
              return;
            }
          }

          break;
        case "2":
          if (this.transferData.persons <= 3) {
            this.transferData.title = zone.nameEs,
              this.transferData.subtitle = '1-3 pax';
            // this.transferData.image = zone.image
            this.transferData.price = zone.hotelPricesPaxOne > 0 ? zone.hotelPricesPaxOne : 10;
          }
          if (this.transferData.persons >= 4 && this.transferData.persons <= 7) {
            this.transferData.title = zone.nameEs,
              this.transferData.subtitle = '4-7 pax';
            // this.transferData.image = zone.image
            this.transferData.price = zone.hotelPricesPaxTwo > 0 ? zone.hotelPricesPaxTwo : 10;
          }
          if (this.transferData.persons >= 8 && this.transferData.persons <= 9) {

            this.transferData.subtitle = '8-9 pax';
            // this.transferData.image = zone.image
            this.transferData.price = zone.hotelPricesPaxThree > 0 ? zone.hotelPricesPaxThree : 10;
          }
          if (this.transferData.persons >= 10 && this.transferData.persons <= 13) {

            this.transferData.subtitle = '10-13 pax';
            // this.transferData.image = zone.image
            this.transferData.price = zone.hotelPricesPaxFour > 0 ? zone.hotelPricesPaxFour : 10;
          }
          if (this.transferData.persons >= 14 && this.transferData.persons <= 18) {

            this.transferData.subtitle = '14-18 pax';
            // this.transferData.image = zone.image
            this.transferData.price = zone.hotelPricesPaxFive;
          }
          if (this.transferData.persons > 18) {
            this.transferData.title = zone.nameEs,
              this.transferData.subtitle = '18+ pax';
            // this.transferData.image = zone.image
            this.navigateToLimitPax(this.transferData);
            return;
          }
          if (this.agency && this.agency.length > 0 && this.agency != null) {
            let agency = this.agency[0].tariff.key;
            if (this.transferData.persons <= 3) {
              this.transferData.title = zone.nameEs,
                this.transferData.price = this.validatePrice(zone[agency].hotelPricesPaxOne, zone.hotelPricesPaxOne);
              // this.transferData.image = zone.image
              this.transferData.subtitle = '1-3 pax';
            }
            if (this.transferData.persons >= 4 && this.transferData.persons <= 7) {
              this.transferData.title = zone.nameEs,
                this.transferData.subtitle = '4-7 pax';
              // this.transferData.image = zone.image
              this.transferData.price = this.validatePrice(zone[agency].hotelPricesPaxTwo, zone.hotelPricesPaxTwo);
            }
            if (this.transferData.persons >= 8 && this.transferData.persons <= 9) {
              this.transferData.title = zone.nameEs,
                this.transferData.subtitle = '8-9 pax';
              // this.transferData.image = zone.image
              this.transferData.price = this.validatePrice(zone[agency].hotelPricesPaxThree, zone.hotelPricesPaxThree);
            }
            if (this.transferData.persons >= 10 && this.transferData.persons <= 13) {
              this.transferData.title = zone.nameEs,
                this.transferData.subtitle = '10-13 pax';
              // this.transferData.image = zone.image
              this.transferData.price = this.validatePrice(zone[agency].hotelPricesPaxFour, zone.hotelPricesPaxFour);
            }
            if (this.transferData.persons >= 14 && this.transferData.persons <= 18) {
              this.transferData.title = zone.nameEs,
                this.transferData.subtitle = '14-18 pax';
              // this.transferData.image = zone.image
              this.transferData.price = this.validatePrice(zone[agency].hotelPricesPaxFive, zone.hotelPricesPaxFive);
            }
            if (this.transferData.persons > 18) {
              this.transferData.title = zone.nameEs,
                this.transferData.subtitle = '18+ pax';
              // this.transferData.image = zone.image
              this.navigateToLimitPax(this.transferData);
              return;
            }
          }
          break;
        case "3":
          if (this.transferData.persons <= 3) {
            this.transferData.title = zone.nameEs,
              this.transferData.subtitle = '1-3 pax';
            this.transferData.price = zone.hotelPricesPaxOne > 0 ? zone.hotelPricesPaxOne : 10;
            // this.transferData.image = zone.image
          }
          if (this.transferData.persons >= 4 && this.transferData.persons <= 7) {
            this.transferData.title = zone.nameEs,
              this.transferData.subtitle = '4-7 pax';
            this.transferData.price = zone.hotelPricesPaxTwo > 0 ? zone.hotelPricesPaxTwo : 10;
            // this.transferData.image = zone.image
          }
          if (this.transferData.persons >= 8 && this.transferData.persons <= 9) {
            this.transferData.title = zone.nameEs,
              this.transferData.subtitle = '8-9 pax';
            this.transferData.price = zone.hotelPricesPaxThree > 0 ? zone.hotelPricesPaxThree : 10;
            // this.transferData.image = zone.image
          }
          if (this.transferData.persons >= 10 && this.transferData.persons <= 13) {
            this.transferData.title = zone.nameEs,
              this.transferData.subtitle = '10-13 pax';
            this.transferData.price = zone.hotelPricesPaxFour > 0 ? zone.hotelPricesPaxFour : 10;
            // this.transferData.image = zone.image
          }
          if (this.transferData.persons >= 14 && this.transferData.persons <= 18) {
            this.transferData.title = zone.nameEs,
              this.transferData.subtitle = '14-18 pax';
            this.transferData.price = zone.hotelPricesPaxFive > 0 ? zone.hotelPricesPaxFive : 10;
            // this.transferData.image = zone.image
          }
          if (this.transferData.persons > 18) {
            this.transferData.title = zone.nameEs,
              this.transferData.subtitle = '18+ pax';
            // this.transferData.image = zone.image
            this.navigateToLimitPax(this.transferData);
            return;
          }
          if (this.agency && this.agency.length > 0 && this.agency != null) {
            let agency = this.agency[0].tariff.key;
            if (this.transferData.persons <= 3) {
              this.transferData.title = zone.nameEs,
                this.transferData.price = this.validatePrice(zone[agency].hotelPricesPaxOne, zone.hotelPricesPaxOne);
              // this.transferData.image = zone.image
              this.transferData.subtitle = '1-3 pax';
            }
            if (this.transferData.persons >= 4 && this.transferData.persons <= 7) {
              this.transferData.title = zone.nameEs,
                this.transferData.subtitle = '4-7 pax';
              // this.transferData.image = zone.image
              this.transferData.price = this.validatePrice(zone[agency].hotelPricesPaxTwo, zone.hotelPricesPaxTwo);
            }
            if (this.transferData.persons >= 8 && this.transferData.persons <= 9) {
              this.transferData.title = zone.nameEs,
                this.transferData.subtitle = '8-9 pax';
              // this.transferData.image = zone.image
              this.transferData.price = this.validatePrice(zone[agency].hotelPricesPaxThree, zone.hotelPricesPaxThree);
            }
            if (this.transferData.persons >= 10 && this.transferData.persons <= 13) {
              this.transferData.title = zone.nameEs,
                this.transferData.subtitle = '10-13 pax';
              // this.transferData.image = zone.image
              this.transferData.price = this.validatePrice(zone[agency].hotelPricesPaxFour, zone.hotelPricesPaxFour);
            }
            if (this.transferData.persons >= 14 && this.transferData.persons <= 18) {
              this.transferData.title = zone.nameEs,
                this.transferData.subtitle = '14-18 pax';
              // this.transferData.image = zone.image
              this.transferData.price = this.validatePrice(zone[agency].hotelPricesPaxFive, zone.hotelPricesPaxFive);
            }
            if (this.transferData.persons > 18) {
              this.transferData.title = zone.nameEs,
                this.transferData.subtitle = '10+ pax';
              // this.transferData.image = zone.image
              this.navigateToLimitPax(this.transferData);
              return;
            }
          }
          break;
        default:
          break;
      }

      var city = "";

      switch (result.newValues.city) {
        case 'cdmx':
          city = "Ciudad de México"
          break;
        case 'cancun':
          city = "Cancún"
          break;
        default:
          city = result.newValues.city
          break;
      }
      this.validateTransferDataFields(this.transferData.type)
      console.log('--> transferData.price al editar', this.transferData.price);
      this.newFinalPrice = this.transferData.price;
      // this.transferData.title = city;
      this.transferData.title = zone.nameEs;
      this.validateTransferDataFields(this.transferData.type)
      if (this.couponUsed) {
        this.validCoupon(this.newFinalPrice);
      }
    });

  }

  async validCoupon(price: number) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 1500,
    });

    await this.couponService.getAll(this.couponInput.trim()).subscribe(data => {
      if (data.length > 0) {
        Toast.fire({
          title: this.activeLang == 'es' ? 'El cupón se ha aplicado correctamente' : 'Coupon redeemed succesfully',
          icon: 'success',
          timerProgressBar: true,
        });
        this.couponUsed = true;
        this.coupon = data[0];

        if (price > 0) {
          this.newFinalPrice = price * (1 - this.coupon.discount);
        }
        return;
      }
      else {
        Toast.fire({
          title: this.activeLang == 'es' ? 'Cupón no encontrado' : 'Coupon not found',
          icon: 'error',
          timerProgressBar: true,
        });
      }
    });
  }

  deleteCoupon() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 1500,
    });
    this.couponUsed = false;

    Toast.fire({
      title: this.activeLang == 'es' ? 'El cupón se ha eliminado correctamente' : 'Coupon deleted successfully',
      icon: 'success',
      timerProgressBar: true,
    });

    this.newFinalPrice = this.transferData.price;
  }

  goBack() {
    if (this.paySection == true) {
      this.paySection = false;
    } else {
      this.router.navigate(['/home']);
    }

  }

  async getTransferInformationByHotelName(hotelName: string) {
    let result = await this.hotelService.searchHotelByName(hotelName);
    return result;
  }

  navigateToLimitPax(TravelInfo: any) {
    this.router.navigate(['/limit-pax/'], { queryParams: TravelInfo })
  }

  onCountryChange(event: any) {
    this.phoneDialCodeSelected = '+' + event.dialCode;
    this.phoneIsoCodeSelected = event.iso2
  }


  telInputObject(obj: any) {
    this.inputObj = obj;
  }

  openPicker() {
    this.picker.open();
  }

  openPicker2() {
    this.picker2.open();
  }

  manageAcordion(event: any) {
    this.firstIsOpen = !this.firstIsOpen;
    if (this.firstIsOpen) {
      this.accordion.openAll()
    } else {
      this.accordion.closeAll()
    }
  }

  manageAcordion2(event: any) {
    this.secondIsOpen = !this.secondIsOpen;
    if (this.secondIsOpen) {
      this.myPanel.open()
    } else {
      this.myPanel.close()
    }
  }

  manageAcordion3(event: any) {
    this.thirdIsOpen = !this.thirdIsOpen;
    if (this.thirdIsOpen) {
      this.acordion3.open()
    } else {
      this.acordion3.close()
    }
  }

  // setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>, formControl: string) {
  //   const ctrlValue = moment();
  //   ctrlValue.month(normalizedMonthAndYear.month());
  //   ctrlValue.year(normalizedMonthAndYear.year());
  //   this.submitForm.controls[formControl].setValue(ctrlValue);
  //   datepicker.close();
  // }

  validatePrice(agencyPrice: number, publicPrice: number) {
    // console.log('====================================');
    // console.log("agencyPrice: ", agencyPrice);
    // console.log("publicPrice: ", publicPrice);
    // console.log('====================================');
    if (agencyPrice == 0 || agencyPrice == null || agencyPrice == undefined) {
      return publicPrice;
    } else if (agencyPrice == 0 && publicPrice == 0) {
      // console.log("Los dos son 0");
      return 10;
    } else {
      return agencyPrice
    }
  }

  testMail() {
    try {
      this.mailService.sendTestMail('', 'es')
    } catch (error) {
      console.log('error', error);

    }
  }

  getAgencyOptionName(nameEs) {
    if (this.activeLang == 'es') {
      return nameEs
    } else {
      switch (nameEs) {
        case 'Pago Completo sin crédito':
          return 'Full payment without credit'
        case 'Pago con 100% de crédito':
          return 'Payment with 100% credit'
        case 'Pago con 50% de crédito':
          return 'Payment with 50% credit'
        default:
          return nameEs
      }
    }
  }

  changeMethodPay(method: string) {
    this.actualMethodPay = method;
  }

}
