import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { CartService } from 'src/app/services/cart/cart.service';
import { EmailService } from 'src/app/services/email/email.service';
import { OpenpayService } from 'src/app/services/openpay/openpay.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-openpay-elements',
  templateUrl: './openpay-elements.component.html',
  styleUrls: ['./openpay-elements.component.scss']
})
export class OpenpayElementsComponent implements OnInit {

  @Input() amount: any | undefined = undefined;
  @Input() orderInfo: any | null = null;
  // @Output() goBackEvent = new EventEmitter<any>()

  public isPaying: boolean = false;
  private deviceSessionId: string = "";
  private userInfo: any = "";
  public activeLang = 'en';
  public lang: string;
  myForm: FormGroup;

  constructor(
    public openPayService: OpenpayService,
    private fb: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private cartService: CartService,
    private translate: TranslateService,
    private emailService: EmailService,
  ) {
    // SANDBOX CONFIG
    // OpenPay.setId('mn3kpaiyfzibn8chetqb');
    // OpenPay.setApiKey('pk_c967739bf7eb481ab7836ab1e754bcf1');
    // OpenPay.setSandboxMode(true);
    // PROD CONFIG
    OpenPay.setId('mn3kpaiyfzibn8chetqb');
    OpenPay.setApiKey('pk_aad3baf673c04e219fe514abf433d965');
    OpenPay.setSandboxMode(false);
    this.myForm = this.fb.group({
      holder_name: ['', Validators.required],
      card_number: ['', [Validators.required, Validators.min(16)]],
      expiration_month: ['', [Validators.required, Validators.min(2)]],
      expiration_year: ['', [Validators.required, Validators.min(2)]],
      cvv2: ['', [Validators.required, Validators.min(3)]],
      // holder_name: ['A', Validators.required],
      // card_number: ['4242424242424242', Validators.required],
      // expiration_month: ['10', Validators.required],
      // expiration_year: ['24', Validators.required],
      // cvv2: ['123', Validators.required],
    });
  }

  ngOnInit(): void {
    this.lang = this.translate.defaultLang;
    this.activeLang = this.translate.currentLang;
    this.userInfo = this.orderInfo.client_info;
    let sub = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          console.log('idiomas', this.translate.currentLang);
          this.activeLang = this.translate.currentLang;
        }, 200);
      }
    });
    console.log(this.amount);
    this.deviceSessionId = OpenPay.deviceData.setup("payment-form", "deviceIdHiddenFieldName");
  }

  async pay() {
    this.myForm.markAllAsTouched();
    if (this.myForm.invalid) {
      Swal.fire({
        icon: 'info',
        title: this.activeLang == 'es' ? 'Completa el formulario' : 'Complete the form',
      });
      return;
    }
    if (this.myForm.valid) {
      this.isPaying = true;
      Swal.fire({
        position: 'center',
        // icon: 'info',
        title: this.activeLang == 'es' ? 'Pagando...' : 'Paying...',
        text: this.activeLang == 'es' ? 'Por favor no salga de la página' : 'Please do not leave the page',
        showConfirmButton: false,
        backdrop: false
      })
      Swal.showLoading();
      var values = this.myForm.value;
      console.log('Creando pago directo...');
      // FOR NORMAL PAY
      await OpenPay.token.create(values, this.success_callbak.bind(this), this.error_callbak.bind(this));
    } else {
      console.log("invalid");
      this.alertService.shootSimpleAlert('error', this.activeLang === 'es' ? 'Lo sentimos, pero no pudimos procesar su pago.' : 'We are sorry, but we were unable to process your payment.', this.activeLang === 'es' ? ' Por favor, comuníquese con su banco para más información.' : 'Please contact your bank for more information.');
      return
    }
  }

  async success_callbak(response: any) {
    console.log('success');
    try {
      let form = this.myForm.value;
      let orderId = this.generateOrderId();
      this.orderInfo.orderId = orderId;
      this.orderInfo.deviceSessionId = this.deviceSessionId;
      let paymentInfo = {
        // plan_id: planId,
        customerRequest: {
          "name": this.userInfo ? (this.userInfo.firstName + ' ' + this.userInfo.lastName) : '',
          "phone_number": this.userInfo ? this.userInfo.phoneNumber : '',
          "email": this.userInfo ? this.userInfo.email : '',
        },
        // cardRequest: {
        //   'card_number': form.card_number,
        //   'holder_name': form.holder_name,
        //   'expiration_year': form.expiration_year,
        //   'expiration_month': form.expiration_month,
        //   'cvv2': form.cvv2,
        // },
        orderInfoCheckParams: {
          "type": this.orderInfo ? this.orderInfo.item_info.title : 0,
          "reach": this.orderInfo ? this.orderInfo.item_info.hotel : '',
          // "paymentOption": this.orderInfo ? this.orderInfo.item_info.paymentOption : '',
          "total": this.amount ? this.amount : 0,
        },
        userInfo: this.userInfo,
      }
      let chargeRequest = {
        'source_id': response.data.id,
        'method': 'card',
        // 'amount': this.orderInfo ? this.orderInfo.summary.total : 0,
        'amount': this.amount ? this.amount : 0,
        // 'currency': 'MXN',
        'currency': 'USD',
        // 'redirect_url': "http://www.openpay.mx/index.html",
        'use_3d_secure': "true",
        "redirect_url": `https://mexicotoptours-transfers.web.app/${this.activeLang}/thanks`,
        'description': this.orderInfo ? (this.orderInfo.item_info.subtitle + ' ' + this.orderInfo.item_info.hotel) : '',
        'device_session_id': this.deviceSessionId,
      }
      // await this.emailService.sendTestMail(this.orderInfo);
      // return
      let serviceResponse = await this.openPayService.paymentCharge(chargeRequest, paymentInfo);
      console.log(serviceResponse);
      // console.log(serviceResponse.code);
      // console.log(serviceResponse.data.openpay_charge.status);
      // console.log(serviceResponse.data.openpay_charge.payment_method.url);
      if (serviceResponse.code == 200) {
        this.orderInfo.openpay_charge_id = serviceResponse.data.openpay_charge.id;
        await this.uploadOrder(this.orderInfo);
        window.location.href = serviceResponse.data.openpay_charge.payment_method.url;
        return
        // this.myForm.reset();
        // this.orderInfo!.paymentInfo = paymentInfo;
        // await this.uploadOrder(this.orderInfo);
        // this.isPaying = false;
        // Swal.close();
        // console.log('thanks');
        // this.router.navigateByUrl('/thanks');
      } else {
        this.isPaying = false;
        Swal.close()
        // this.alertService.shootSimpleAlert('error', 'Ha ocurrido un error inesperado', 'Por favor intente más tarde');
        this.alertService.shootSimpleAlert('error', this.activeLang === 'es' ? 'Lo sentimos, pero no pudimos procesar su pago.' : 'We are sorry, but we were unable to process your payment.', this.activeLang === 'es' ? ' Por favor, comuníquese con su banco para más información.' : 'Please contact your bank for more information.');
      }
    } catch (error) {
      this.isPaying = false;
      // this.alertService.shootSimpleAlert('error', 'Ha ocurrido un error inesperado, por favor intente más tarde');
      // this.alertService.shootSimpleAlert('error', this.activeLang === 'es' ? 'Ha ocurrido un error inesperado' : 'An unexpected error has occurred', this.activeLang === 'es' ? 'Por favor intente más tarde' : 'Please try again later');
      this.alertService.shootSimpleAlert('error', this.activeLang === 'es' ? 'Lo sentimos, pero no pudimos procesar su pago.' : 'We are sorry, but we were unable to process your payment.', this.activeLang === 'es' ? ' Por favor, comuníquese con su banco para más información.' : 'Please contact your bank for more information.');
    }

  };

  error_callbak(response: any) {
    var desc = response.data.description != undefined ? response.data.description : response.message;
    this.isPaying = false;
    this.alertService.shootSimpleAlert('error', "ERROR [" + response.status + "] " + desc)
    // alert("ERROR [" + response.status + "] " + desc);
  };

  generateOrderId(): string {
    const letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numeros = '0123456789';
    let resultado = '';
    for (let i = 0; i < 3; i++) {
      const letraAleatoria = letras.charAt(Math.floor(Math.random() * letras.length));
      const numeroAleatorio = numeros.charAt(Math.floor(Math.random() * numeros.length));
      resultado += letraAleatoria + numeroAleatorio;
    }
    return resultado;
  }

  async uploadOrder(infoPurchase: any, paymentIntent?: any) {
    infoPurchase.item_info.finalPrice = this.amount;
    // infoPurchase.paymentIntentId = paymentIntent.data.id;
    // infoPurchase.infoPurchase = paymentIntent.data;
    // infoPurchase.orderId = orderId;
    infoPurchase.item_info.serviceType = 1;
    if (infoPurchase.item_info.type == 2) {
      infoPurchase.client_info.arrivalDate = infoPurchase.client_info.departureDate;
      infoPurchase.client_info.arrivalHour = infoPurchase.client_info.departureHour;
      infoPurchase.item_info.serviceType = 2;
    }
    if (infoPurchase.item_info.type == 3) {
      infoPurchase.client_info.departureDate = infoPurchase.client_info.arrivalDate;
      infoPurchase.client_info.departureHour = infoPurchase.client_info.arrivalHour;
      infoPurchase.item_info.serviceType = 3;
    }
    infoPurchase.client_info.arrivalDateFormatted = this.formatFecha(infoPurchase.client_info.arrivalDate, this.activeLang);
    infoPurchase.client_info.departureDateFormatted = this.formatFecha(infoPurchase.client_info.departureDate, this.activeLang);
    infoPurchase.client_info.arrivalHourFormatted = this.formatHora(infoPurchase.client_info.arrivalHour);
    infoPurchase.client_info.departureHourFormatted = this.formatHora(infoPurchase.client_info.departureHour);
    // infoPurchase.payment_status = 'success';
    await this.cartService.saveOrder(infoPurchase);
    if (infoPurchase.item_info.type == 1) {
      infoPurchase.client_info.arrivalDate = infoPurchase.client_info.departureDate;
      infoPurchase.client_info.arrivalHour = infoPurchase.client_info.departureHour;
      // infoPurchase.infoPurchase.amount = 0;
      infoPurchase.item_info.finalPrice = 0;
      infoPurchase.paymentIntentId = '';
      // infoPurchase.payment_status = 'success';
      if (infoPurchase.client_info.paymentAgencyMethod == 'paymentOf50PercentInCredit') {
        infoPurchase.payment_status = 'paymentOf50PercentInCredit';
      }
      infoPurchase.item_info.serviceType = 2;
      await this.cartService.saveOrder(infoPurchase);
      console.log("Order Type 1: ", infoPurchase);
    }
    // await this.emailService.sendTestMail(infoPurchase, this.activeLang);
  }

  formatFecha(timestamp, lang) {
    const meses = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto",
      "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];
    const months = [
      "January", "February", "March", "April", "May", "June", "July", "August",
      "September", "October", "November", "December"
    ];
    const fecha = new Date(timestamp);
    const dia = fecha.getUTCDate();
    const mes = lang == 'es' ? meses[fecha.getUTCMonth()] : months[fecha.getUTCMonth()];
    const año = fecha.getUTCFullYear();
    return `${dia} ${mes} ${año}`;
  }

  formatHora(fechaString) {
    const fecha = new Date(fechaString);
    let horas = fecha.getHours();
    const minutos = fecha.getMinutes();
    let ampm = "am";
    console.log('fecha', fecha);
    if (horas > 12) {
      horas -= 12;
      ampm = "pm";
    } else if (horas === 12) {
      ampm = "pm";
    } else if (horas === 0) {
      horas = 12;
    }
    return `${horas}:${minutos.toString().padStart(2, "0")}${ampm}`;
  }

}

