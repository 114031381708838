// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyA-goFX8iirkFfQDPQRoI1W3BVuYWj3gms",
    authDomain: "mexico-top-tours.firebaseapp.com",
    databaseURL: "https://mexico-top-tours.firebaseio.com",
    projectId: "mexico-top-tours",
    storageBucket: "mexico-top-tours.appspot.com",
    messagingSenderId: "165768182832",
    appId: "1:165768182832:web:d6c9535a54ae9e751981f5",
    measurementId: "G-PTDF6W6NZX"
  },
  stripe_pk: "pk_live_51Hm4n1E8EO7nJS3EVbH8q2TQdgvELzyAHpJozAhWOZrSFfb77e05Z5KKIDc9AY0jK7xkfj0uCDxzCqUTS09fe99200UDqUnWm0",
  // URL_API: "http://localhost:8080/api",
  URL_API: "https://mextoptuors-back.herokuapp.com/api"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
