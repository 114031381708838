import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Transfer } from 'src/app/models/transfer.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TransferService {

  constructor(private afs: AngularFirestore, private http: HttpClient) { }

  private paymentIntentURI: string = `${environment.URL_API}/order/payment-intent`;
  private updatePaymentIntentURI: string = `${environment.URL_API}/order/update-payment-intent`;

  public getTransfers(city: string) {
    return this.afs.collection('transfers', ref => ref.where('city', '==', city)).get().toPromise();
  }


  public async getTransferById(idTransfer: string) {
    try {
      let res = await this.afs.collection('transfers').doc(idTransfer).ref.get()
      return res.data() as Transfer;

    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async getTransferPurchaseById(idTransfer: string) {
    try {
      let res = await this.afs.collection('pruchases-orders-transfers').doc(idTransfer).ref.get();
      return res.data() as Transfer;

    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async paymentIntentStripe(amount: number) {
    try {

      console.log('amount', amount);
      // console.log(this.paymentIntentURI);
      let response = await this.http.post<any>(this.paymentIntentURI, { amount: amount }).toPromise();
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  }

  async updatePaymentIntentStripe(paymentIntentId: string, metadata: any) {
    try {
      let response = await this.http.post<any>(this.updatePaymentIntentURI, { paymentIntentId, metadata }).toPromise();
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getOrderByPaymentIntent(paymentIntentId: string) {
    try {
      let ref = this.afs.collection('pruchases-orders-transfers', ref => ref.where('paymentIntentId', '==', paymentIntentId));
      var snapshot = await ref.get().toPromise();
      var docs = snapshot.docs.map(individualDoc => {
        return individualDoc.data();
      })
      return docs;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
