import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CartService } from 'src/app/services/cart/cart.service';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {

  public sub: Subscription;
  public transfer: any;

  constructor(
    private cartService: CartService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.sub = this.cartService.getCart().subscribe(res => {
      if (res == null) {
        this.router.navigateByUrl("/");
        this.sub ? this.sub.unsubscribe() : '';
      }
      this.transfer = res;
    })
  }

  ngOnDestroy() {
    this.sub ? this.sub.unsubscribe() : '';
  }

}
