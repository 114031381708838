import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotel } from 'src/app/models/hotel.model';
import { Transfer } from 'src/app/models/transfer.model';
import { CartService } from 'src/app/services/cart/cart.service';
import { HotelsService } from 'src/app/services/hotels/hotels.service';

@Component({
  selector: 'app-form-transfer',
  templateUrl: './form-transfer.component.html',
  styleUrls: ['./form-transfer.component.scss']
})
export class FormTransferComponent implements OnInit {

  public submitForm: FormGroup
  @Input() transferInfo: Transfer
  @Input() lang: string;
  @Input() hotel: string;
  public hotels: Array<Hotel>;

  public minDate = new Date();
  public departureDate = new Date();

  public price: number = 0;

  constructor(
    private fb: FormBuilder,
    private hotels_service: HotelsService,
    private cart_service: CartService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.submitForm = this.fb.group({
      persons: ['', Validators.required],
      wayRoundTrip: ['', Validators.required,],
      hotelCity: ['', Validators.required],
      arrivalDate: ['', Validators.required],
      departureDate: ['', Validators.required],
      price: ['', Validators.required],
      img: [''],
      nameCity: [''],
      typeTravel: ['']
    })

    if (this.lang == 'es') {
      this.submitForm.controls.nameCity.setValue(this.transferInfo.nameEs)
    } else {
      this.submitForm.controls.nameCity.setValue(this.transferInfo.nameEs)
    }

    this.submitForm.controls.img.setValue(this.transferInfo.image)
    this.submitForm.controls.hotelCity.setValue(this.hotel)


    this.hotels_service.getHotelsByCity(this.transferInfo.city).subscribe(hotels => {
      this.hotels = hotels;
    })

    this.cart_service.getCart().subscribe(data => {
      console.log(data);

    })
  }

  changeDate() {
    this.submitForm.controls.departureDate.reset();
    this.departureDate = this.submitForm.controls.arrivalDate.value;
  }

  selectPersonAndPax() {
    // valida la cantidad de personas y el tipo de paquete para dar un precio
    let personPax = parseInt(this.submitForm.controls.persons.value)
    let wayRoundTrip = this.submitForm.controls.wayRoundTrip.value;
    let type;

    switch (wayRoundTrip) {
      case 'oneWayHotel':
        type = 'hotelPricesPax'

        if (this.lang == 'es') {
          this.submitForm.controls.typeTravel.setValue('Traslado al Hotel')
        } else {
          this.submitForm.controls.typeTravel.setValue('One Way Arrival to the Hotel')

        }

        break;
      case 'roundTrip':
        type = 'roundTripPricesPax'

        if (this.lang == 'es') {
          this.submitForm.controls.typeTravel.setValue('Viaje Redondo')
        } else {
          this.submitForm.controls.typeTravel.setValue('Round Trip')
        }
        break;
      case 'oneWayAirport':
        type = 'airportPricesPax'

        if (this.lang == 'es') {
          this.submitForm.controls.typeTravel.setValue('Traslado al Aeropuerto')
        } else {
          this.submitForm.controls.typeTravel.setValue('One Way Arrival to the Airport')
        }
        break;
      default:
        type = 'roundTripPricesPax'
        break;
    }

    if (personPax <= 3) {
      this.price = parseInt(this.transferInfo[`${type}One`])
    }
    if (personPax >= 4 && personPax <= 7) {
      this.price = parseInt(this.transferInfo[`${type}Two`])
    }
    if (personPax > 7 && personPax <= 10) {
      this.price = parseInt(this.transferInfo[`${type}Three`])

    }

    this.submitForm.controls.price.setValue(this.price)
  }


  save() {

    let dataToSave = this.submitForm.value;

    console.log(dataToSave);

    if (this.submitForm.valid) {
      this.cart_service.setCart(dataToSave)
      this.router.navigateByUrl('/store');
    }
  }



}
