import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  public searchInput: string = '';
  public loader: boolean = false;
  public inputValue:string;
  @ViewChild(MatAutocompleteTrigger) autocomplete: any;

  constructor() { }

  ngOnInit(): void {
  }

  searchInfo() {
    window.open("https://www.mexicotoptours.com/tienda/search?keywords="+this.inputValue, "_self");
  }

}
