import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  constructor(private afs: AngularFirestore) { }

  getAll(couponCode: string) {
    return this.afs.collection('coupons', ref => ref.where('coupon', '==', couponCode)).snapshotChanges().pipe(map(actions => {
      return actions.map(action => {
        const data = action.payload.doc.data() as any;
        const id = action.payload.doc.id;
                
        return { id, ...data };
      });
    }));
  }
}
