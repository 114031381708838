import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AirlinesService {

  public airLines: any[] = [
    { value: 'EDELWEISS', viewValue: 'EDELWEISS' },
    { value: 'AEROLINEAS ARGENTINAS', viewValue: 'AEROLINEAS ARGENTINAS' },
    { value: 'AEROMAR', viewValue: 'AEROMAR' },
    { value: 'AEROMEXICO', viewValue: 'AEROMEXICO' },
    { value: 'AIR CANADA', viewValue: 'AIR CANADA' },
    { value: 'AIR EUROPA', viewValue: 'AIR EUROPA' },
    { value: 'AIR FRANCE', viewValue: 'AIR FRANCE' },
    { value: 'AIR TRANSAT', viewValue: 'AIR TRANSAT' },
    { value: 'ALASKA AILINES', viewValue: 'ALASKA AILINES' },
    { value: 'ALLENGIANT AIR', viewValue: 'ALLENGIANT AIR' },
    { value: 'AMERICAN AIRLINES', viewValue: 'AMERICAN AIRLINES' },
    { value: 'ARKEFLY', viewValue: 'ARKEFLY' },
    { value: 'AVIANCA', viewValue: 'AVIANCA' },
    { value: 'AZUR AIR', viewValue: 'AZUR AIR' },
    { value: 'BRITISH AIRWAYS', viewValue: 'BRITISH AIRWAYS' },
    { value: 'CONDOR', viewValue: 'CONDOR' },
    { value: 'CONVIASA', viewValue: 'CONVIASA' },
    { value: 'COPA AIRLINES', viewValue: 'COPA AIRLINES' },
    { value: 'CUBANA DE AVIACIÓN', viewValue: 'CUBANA DE AVIACIÓN' },
    { value: 'DELTA AIRLINES', viewValue: 'DELTA AIRLINES' },
    { value: 'FRONTIER', viewValue: 'FRONTIER' },
    { value: 'GOL LINEAS AEREA', viewValue: 'GOL LINEAS AEREAS' },
    { value: 'IBEROJET', viewValue: 'IBEROJET' },
    { value: 'INTERJET', viewValue: 'INTERJET' },
    { value: 'JETAIRFLY', viewValue: 'JETAIRFLY' },
    { value: 'JETBLUE', viewValue: 'JETBLUE' },
    { value: 'KLM ROYAL DUTCH AIRLINES', viewValue: 'KLM ROYAL DUTCH AIRLINES' },
    { value: 'LATAM', viewValue: 'LATAM' },
    { value: 'LOT POLISH', viewValue: 'LOT POLISH' },
    { value: 'LUFTHANSA', viewValue: 'LUFTHANSA' },
    { value: 'MAGNICHARTERS', viewValue: 'MAGNICHARTERS' },
    { value: 'MIAMI AIR', viewValue: 'MIAMI AIR' },
    { value: 'NEOS', viewValue: 'NEOS' },
    { value: 'NORDWIND', viewValue: 'NORDWIND' },
    { value: 'ORBEST', viewValue: 'ORBEST' },
    { value: 'ROYAL FLIGHT', viewValue: 'ROYAL FLIGHT' },
    { value: 'SOUTHWEST', viewValue: 'SOUTHWEST' },
    { value: 'SPIRIT AIRLINES', viewValue: 'SPIRIT AIRLINES' },
    { value: 'SUN COUNTRY', viewValue: 'SUN COUNTRY' },
    { value: 'SUNWING', viewValue: 'SUNWING' },
    { value: 'SWIFT AIR', viewValue: 'SWIFT AIR' },
    { value: 'SWOOP', viewValue: 'SWOOP' },
    { value: 'TAG AIRLINES', viewValue: 'TAG AIRLINES' },
    { value: 'TAP AIR PORTUGA', viewValue: 'TAP AIR PORTUGAL' },
    { value: 'TUI THOMPSON', viewValue: 'TUI THOMPSON' },
    { value: 'TUIFLY NORDIC', viewValue: 'TUIFLY NORDIC' },
    { value: 'TURKISH AIRLINES', viewValue: 'TURKISH AIRLINES' },
    { value: 'UNITED AIRLINES', viewValue: 'UNITED AIRLINES' },
    { value: 'VIVA AEROBUS', viewValue: 'VIVA AEROBUS' },
    { value: 'VIVA AIR COLOMBIA', viewValue: 'VIVA AIR COLOMBIA' },
    { value: 'WAMOS AIR', viewValue: 'WAMOS AIR' },
    { value: 'WESTJET', viewValue: 'WESTJET' },
    { value: 'WINGO', viewValue: 'WINGO' },
    { value: 'WORLD2FLY', viewValue: 'WORLD2FLY' },
    { value: 'OTRO', viewValue: 'OTRO' },

  ];

  constructor() { }
}
