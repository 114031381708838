import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-chose-transfer',
  templateUrl: './chose-transfer.component.html',
  styleUrls: ['./chose-transfer.component.scss']
})
export class ChoseTransferComponent implements OnInit {

  public resultZones: Array<any> = [];

  // lang
  public lang: string;
  private subs: Subscription

  constructor(
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    //this.bookNow()
  }

  ngOnInit(): void {
    this.lang = this.translate.defaultLang;
    this.subs = this.translate.onLangChange.subscribe(lang => {
      this.lang = lang.lang;
    })

    let selectedHotel = this.activatedRoute.snapshot.queryParamMap.get('hotel')
    let selectedType = this.activatedRoute.snapshot.queryParamMap.get('type')
    let selectedPersons = this.activatedRoute.snapshot.queryParamMap.get('persons')
    let selectedPrice = this.activatedRoute.snapshot.queryParamMap.get('price')
    let selectedSubtitle = this.activatedRoute.snapshot.queryParamMap.get('subtitle')
    let selectedImage = this.activatedRoute.snapshot.queryParamMap.get('image')
    let selectedKids = this.activatedRoute.snapshot.queryParamMap.get('kids')
    let selectedAdults = this.activatedRoute.snapshot.queryParamMap.get('adults')
    let selectedTitle = this.activatedRoute.snapshot.queryParamMap.get('title')
    let agencyCode = this.activatedRoute.snapshot.queryParamMap.get('agencyId');

    this.resultZones.push({
      price: selectedPrice,
      image: selectedImage,
      subtitle: selectedSubtitle,
      hotel: selectedHotel,
      type: selectedType,
      adults: selectedAdults,
      kids: selectedKids,
      persons: selectedPersons,
      title: selectedTitle,
      agencyCode: agencyCode
    })
    //this.bookNow()
  }

  ngOnDestroy() {
    if (this.subs) this.subs.unsubscribe();
  }

  bookNow() {
    var params = JSON.stringify(this.resultZones[0]);
    this.router.navigate(['/checkout'], { queryParams: { data: params } });
  }
}
