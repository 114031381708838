import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Hotel } from 'src/app/models/hotel.model';
import { HotelsService } from 'src/app/services/hotels/hotels.service';
import { TransferService } from 'src/app/services/transfer/transfer.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    public citySelect: string = '';
    public transfers: any = [];
    public lang: string;
    private subs: Subscription

    public placeSelected: any;
    public hotels: Array<any>;
    public hotelSelected: string = '';
    public otherHotel = false;
    public myControlHotel = new FormControl();
    filteredOptions: Observable<any[]>;


    public addForm: FormGroup;
    public hotelsTransfers = [];
    public hotelsControl = new FormControl();
    public filteredHotels: Observable<string[]>;

    public currentStep = 1;
    public resultZones = [];

    constructor(
        private router: Router,
        private transferService: TransferService,
        private hotelService: HotelsService,
        private translate: TranslateService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute
    ) {

        this.addForm = this.formBuilder.group({
            city: ['cancun', Validators.required],
            tripType: ['1', Validators.required],
            adults: ['1', Validators.required],
            kids: ['0', Validators.required],
        });
    }


    ngOnInit(): void {
        this.lang = this.translate.defaultLang;
        this.subs = this.translate.onLangChange.subscribe(lang => {
            this.lang = lang.lang;
        })

        this.findHotel({ value: 'SUITES GABY' });

        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });
    }

    _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.hotels.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    ngOnDestroy() {
        if (this.subs) this.subs.unsubscribe();
    }

    getTransfers() {
        console.log('City', this.citySelect);

        this.currentStep = 2;
        this.transferService.getTransfers(this.citySelect).then(data => {
            this.transfers = data.docs.map(doc => {
                const data = doc.data() as any;
                const id = doc.id;
                return { id, ...data }
            });
        })
    }

    public changeSort(payload: any): any {
        const removeAccents = (str) => {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }

        if (payload === 'ascending') {
            return this.transfers.sort((a, b) => {
                if (a.id < b.id) {
                    return -1;
                } else if (a.id > b.id) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 1) {
            return this.transfers.sort((a, b) => {
                if (removeAccents(a.nameEs.toLowerCase()) < removeAccents(b.nameEs.toLowerCase())) {
                    return -1;
                } else if (removeAccents(a.nameEs.toLowerCase()) > removeAccents(b.nameEs.toLowerCase())) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 2) {
            return this.transfers.sort((a, b) => {
                if (removeAccents(a.nameEs.toLowerCase()) > removeAccents(b.nameEs.toLowerCase())) {
                    return -1;
                } else if (removeAccents(a.nameEs.toLowerCase()) < removeAccents(b.nameEs.toLowerCase())) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 3) {
            return this.transfers.sort((a, b) => {
                if (parseFloat(a.airportPricesPaxOne) < parseFloat(b.airportPricesPaxOne)) {
                    return -1;
                } else if (parseFloat(a.airportPricesPaxOne) > parseFloat(b.airportPricesPaxOne)) {
                    return 1;
                }
                return 0;
            })
        } else if (payload === 4) {
            return this.transfers.sort((a, b) => {
                if (parseFloat(a.airportPricesPaxOne) > parseFloat(b.airportPricesPaxOne)) {
                    return -1;
                } else if (parseFloat(a.airportPricesPaxOne) < parseFloat(b.airportPricesPaxOne)) {
                    return 1;
                }
                return 0;
            })
        }
    }


    selectPlace(item) {
        this.currentStep = 3;
        this.placeSelected = item;
        this.hotelService.getHotelsByCity(item.city).subscribe(hotels => {
            this.hotels = hotels;
            this.filteredOptions = this.myControlHotel.valueChanges
                .pipe(
                    startWith(''),
                    map(value => this._filter(value))
                );
        })
    }

    selectHotel() {
        let hotel = this.otherHotel ? this.hotelSelected : this.myControlHotel.value;
        console.log('Hotel, ', hotel);
        if (hotel != null || hotel != '') {
        }
    }

    _filterHotels(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.hotelsTransfers.filter(option => option.nameEs.toLowerCase().includes(filterValue));
    }

    findHotel(e) {
        this.hotelsControl.setValue('');
        this.hotelService.searchHotelTrasnfers(e.value).subscribe(res => {
            this.hotelsTransfers = res;
            this.filteredHotels = this.hotelsControl.valueChanges.pipe(
                startWith(''),
                map(value => this._filterHotels(value))
            );
        })
    }

    bookNow() {
        console.log('Book Now', this.resultZones);
        var params = JSON.stringify(this.resultZones[0]);
        this.router.navigate(['/checkout'], { queryParams: { data: params } });
    }
}
