<section>
    <app-search-bar></app-search-bar>
    <div class="head-page">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <h1 class="h1 title">{{'LIMITPAX.HEADING' | translate}}</h1>
                </div>
            </div>
        </div>
    </div>

    <!-- <h2>{{'LIMITPAX.TITLE' | translate}}</h2> -->

    <div class="row container">
        <div class="form-wrapper">
            <form [formGroup]="limitPaxForm">
                <mat-expansion-panel style="margin: 0.5rem auto;" [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <p>{{'LIMITPAX.HEADING-FORM' | translate}}</p>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'LIMITPAX.FORM.FIRSTNAME' | translate}}</mat-label>
                                <input matInput formControlName="firstName">
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'LIMITPAX.FORM.LASTNAME' | translate}}</mat-label>
                                <input matInput formControlName="lastName">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'LIMITPAX.FORM.EMAIL' | translate}}</mat-label>
                                <input matInput formControlName="email">
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'LIMITPAX.FORM.PHONE' | translate}}</mat-label>
                                <input matInput formControlName="phoneNumber" type="number" maxlength="12"
                                    oninput="(value=value.replace(/\D+/g, ''))" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label> {{'LIMITPAX.FORM.MESSAGE' | translate}} </mat-label>
                                <textarea formControlName="message" matInput
                                    [placeholder]="'LIMITPAX.FORM.MESSAGE-PLACEHOLDER' | translate"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-expansion-panel>
                <ng-container *ngIf="loading">
                    <div class="spinner">
                    </div>
                </ng-container>
                <div class="btn-wrapper">

                    <button class="btn-cancel" *ngIf="!loading" (click)="goBack()">{{'LIMITPAX.FORM.BUTTON-CANCEL' |
                        translate}}</button>
                    <button class="btn-buy" *ngIf="!loading" (click)="sendMail()">{{'LIMITPAX.FORM.BUTTON' |
                        translate}}</button>
                </div>
            </form>
        </div>

        <div class="row justify-content-center info-trip" style="padding: 40px; width: 100%;">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-3">
                        <img [src]="resultZones[0]?.image" alt="" width="200">
                    </div>
                    <div class="col-md-6">
                        <div class="info-flex">
                            <h2>{{resultZones[0]?.title}}</h2>
                            <p *ngIf="resultZones[0]?.type == 1">{{'FORM.ROUNDTRIP' | translate}}</p>
                            <p *ngIf="resultZones[0]?.type == 2">{{'FORM.HOTELTOAIR' | translate}}</p>
                            <p *ngIf="resultZones[0]?.type == 3">{{'FORM.AIRTOHOTEL' | translate}}</p>
                            <p>{{resultZones[0]?.hotel}}</p>
                            <p>{{resultZones[0]?.subtitle}} ({{resultZones[0]?.adults}} {{'FORM.ADULTS' | translate}},
                                {{resultZones[0]?.kids}} {{'FORM.KIDS' | translate}})</p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="price-flex">
                            <p id="taxes"> {{ 'FORM.TAXES' | translate }} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <span>Valid</span>
        <pre>{{limitPaxForm.valid}}</pre>

        <span>Touched</span>
        <pre>{{limitPaxForm.touched}}</pre>

        <span>Value</span>
        <pre>{{limitPaxForm.value | json}}</pre> -->
    </div>
</section>