import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { Transfer } from 'src/app/models/transfer.model';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  public cart: BehaviorSubject<Transfer> = new BehaviorSubject(null);
  obs$: Observable<Transfer> = this.cart.asObservable();
  constructor(private afs: AngularFirestore) { }

  setCart(item) {
    this.cart.next(item);
  }

  getCart() {
    return this.obs$;
  }

  async saveOrder(data: any) {
    console.log("Data: ", data);
    try {
      // return await this.afs.collection('pruchases-orders-transfers').add(data).then(id => {
      return await this.afs.collection('pruchases-orders-transfers').add(data).then(id => {
        return id.id
      })
    } catch (error) {
      console.log("Error saveOrder: ", error);
    }
  }
}
