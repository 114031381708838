import { Component, Input, OnInit } from '@angular/core';
import { Transfer } from 'src/app/models/transfer.model';

@Component({
  selector: 'app-slider-transfer',
  templateUrl: './slider-transfer.component.html',
  styleUrls: ['./slider-transfer.component.scss']
})
export class SliderTransferComponent implements OnInit {

  @Input() transferInfo:Transfer;


  constructor() { }

  ngOnInit(): void {
    
  }

}
