<app-search-bar>
</app-search-bar>
<section>
    <div class="step">
        <h2>{{'STEP' | translate}}5</h2>
    </div>
    <div class="container">
        <div class="cart">
            <h1>{{'CART.TITLE' | translate}}</h1>
            <app-cart-item [transfer]="transfer"></app-cart-item>
            <hr>
            <div class="total">
                <h1>TOTAL</h1>
                <h1>{{transfer.price | currency}}USD</h1>
            </div>
        </div>
        <div class="checkout">
            <h1>{{'CHECKOUT.TITLE' | translate}}</h1>
            <app-checkout></app-checkout>
        </div>
    </div>
</section>