<div class="item">
    <div class="img">
        <!-- <img src="https://d2j6dbq0eux0bg.cloudfront.net/images/25851017/1591096359.jpg" alt=""> -->
    </div>
    <div class="summary">
        <div class="header">
            <!-- <a routerLink="/transfers">Cancun</a> -->
            <!-- <mat-icon matPrefix>close</mat-icon> -->
        </div>
        <ul>
            <li>PAX: {{transfer.persons}}</li>
            <li>{{ "CART.TYPE_WAY" | translate }}: {{transfer.wayRoundTrip}}</li>
            <li>{{ "CART.HOTEL" | translate }}: {{transfer.hotelCity}}</li>
            <!-- <li>{{ "CART.AIRLINE" | translate }}: {{transfer.airline}} <span>{{ "CART.FLIGHT_NUMBER" | translate }}: {{transfer.flightNumber}}</span></li> -->
            <li>{{ "CART.ARRIVAL" | translate }}: {{transfer.arrivalDate | date}}</li>
            <li>{{ "CART.DEPARTURE" | translate }}: {{transfer.departureDate | date}}</li>
            <!-- <li>{{ "CART.NAME" | translate }}: {{transfer.firstName}} <span>{{ "CART.L_NAME" | translate }}: {{transfer.lastName}}</span></li> -->
            <!-- <li>{{ "CART.PHONE" | translate }}:</li> -->
        </ul>
    </div>
</div>