<section>
  <div class="column">
    <img [src]="imgStatus" alt="">
    <p [innerHTML]="" style="padding-bottom: 0;">{{message}}</p>
    <p [innerHTML]="" style="padding-top: 0;">{{message2}}</p>

  </div>
  <div id="print" *ngIf="transferPurchase">
    <div class="container">
      <div class="row">
        <div class="card">
          <div class="card-header" style="background: black; color: white; text-transform: uppercase; padding: 1rem;">
            {{'CONTACT_INFO' | translate}}
          </div>
          <div class="card-body">
            <h1 style="font-weight: bold;text-transform: capitalize;">{{infoPurchase?.firstName}}
              {{infoPurchase?.lastName}}</h1>
            <div class="row">
              <div class="col-md-4">
                <p><strong>{{ "TRIP_TYPE" | translate }}</strong>
                  <br>
                  <span *ngIf="infoTransfer.type == 1">{{'FORM.ROUNDTRIP' | translate}}</span>
                  <span *ngIf="infoTransfer.type == 2">{{'FORM.HOTELTOAIR' | translate}}</span>
                  <span *ngIf="infoTransfer.type == 3">{{'FORM.AIRTOHOTEL' | translate}}</span>
                </p>
              </div>
              <div class="col-md-4">
                <p><strong>{{"TRANSFER.FORM.FIELD6" | translate}}</strong>
                  <br>
                  <span>{{infoPurchase?.arrivalDate.toDate() | date: 'dd/MM/yyy'}}</span>
                </p>
              </div>
              <div class="col-md-4">
                <p><strong>{{"TRANSFER.FORM.FIELD7" | translate}}</strong>
                  <br>
                  <span>{{infoPurchase?.departureDate.toDate() | date: 'dd/MM/yyy'}}</span>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p><strong>Hotel</strong>
                  <br>
                  <span>{{infoTransfer?.hotel}}</span>
                </p>
              </div>
              <div class="col-md-4">
                <p><strong>Transfer</strong>
                  <br>
                  <span>Regular</span>
                </p>
              </div>
              <div class="col-md-4">
                <p><strong>Pax</strong>
                  <br>
                  <span>{{infoTransfer?.adults}} {{'FORM.ADULTS' | translate}}, {{infoTransfer?.kids}}
                    {{'FORM.KIDS' | translate}}</span>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p><strong>{{'RESERVATION_NUMBER' | translate}}</strong>
                  <br>
                  <span>#{{infoPurchase?.id}}</span>
                </p>
              </div>
              <div class="col-md-4">
                <p><strong>{{'RESERVATION_DATE' | translate}}</strong>
                  <br>
                  <span>{{transferPurchase?.createdAt.toDate() | date: 'dd/MM/yyyy'}}</span>
                </p>
              </div>
              <div class="col-md-4">
                <p><strong>Total</strong>
                  <br>
                  <span>{{infoPurchase?.purchase_units[0].amount.value}}
                    {{infoPurchase?.purchase_units[0].amount.currency_code}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="card">
          <div class="card-header" style="background: black; color: white; text-transform: uppercase; padding: 1rem;">
            {{'FLIGTH_INFO' | translate}}
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <p><strong>{{"TRANSFER.FORM.FIELD75" | translate}}</strong>
                  <br>
                  <span>{{infoPurchase?.arrivalHour}}</span>
                </p>
              </div>
              <div class="col-md-4">
                <p><strong>{{"AIRLINE" | translate}}</strong>
                  <br>
                  <span>{{infoPurchase?.arrival_airline}}</span>
                </p>
              </div>
              <div class="col-md-4">
                <p><strong>{{"FLIGTH_NUMBER" | translate}}</strong>
                  <br>
                  <span>{{infoPurchase?.arrival_flightNumber}}</span>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p><strong>{{"TRANSFER.FORM.FIELD76" | translate}}</strong>
                  <br>
                  <span>{{infoPurchase?.departureHour}}</span>
                </p>
              </div>
              <div class="col-md-4">
                <p><strong>{{"AIRLINE" | translate}}</strong>
                  <br>
                  <span>{{infoPurchase?.departure_airline}}</span>
                </p>
              </div>
              <div class="col-md-4">
                <p><strong>{{"FLIGTH_NUMBER" | translate}}</strong>
                  <br>
                  <span>{{infoPurchase?.departure_flightNumber}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="btn-wrapper">
    <button mat-flat-button class="btn-green" style="margin-top: 1rem;" (click)="redirect()">{{"BACK_HOME" |
      translate}}</button>
  </div>
</section>
