<a href="javascript:void(0)">
    <div class="transfer-card">
        <div class="top" [routerLink]="['/transfer/',transfer.id]" [ngStyle]="{'background-image': 'url(' + transfer.image + ')'}"></div>
        <div class="bottom">
            <h1>{{lang == 'es' ? transfer.nameEs : transfer.nameEn}}</h1>
            <h1>{{transfer.airportPricesPaxOne | currency}} USD</h1>
            <a [routerLink]="['/transfer/',transfer.id]" >
                <p>{{'BUY_NOW' | translate}}</p>
            </a>
        </div>
    </div>
</a>