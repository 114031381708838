<button (click)="close()" class="btnClose" mat-mini-fab color="primary" aria-label="Example icon button with a delete icon">
    <mat-icon>close</mat-icon>
</button>

<div class="form-div">
    <form [formGroup]="editForm ">
        <p>
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>{{'FORM.CITY' | translate}}</mat-label>
                <mat-select formControlName="city">
                    <mat-option value="cancun">Cancún</mat-option>
                    <!-- <mat-option value="cdmx">{{'FORM.CDMX' | translate}}</mat-option> -->
                </mat-select>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>{{'FORM.TYPE' | translate}}</mat-label>
                <mat-select formControlName="tripType">
                    <mat-option value="1">{{'FORM.ROUNDTRIP' | translate}}</mat-option>
                    <mat-option value="2">{{'FORM.HOTELTOAIR' | translate}}</mat-option>
                    <mat-option value="3">{{'FORM.AIRTOHOTEL' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field class="full-width" appearance="fill">
                <mat-label>Hotel</mat-label>
                <input type="text" placeholder="Hotel" aria-label="Hotel" matInput [formControl]="hotelsControl"
                    [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredHotels | async" [value]="option.nameEs">
                        {{option.nameEs}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <ng-container *ngIf="hotelsControl.value == null && hotelsControl.touched">
                <p class="text-danger">{{'FORM.FIELD-REQUIRED' | translate}}</p>
            </ng-container>
        </p>
        <div class="row">
            <div class="col-md-6">
                <p *ngIf="isDesktop">
                    <mat-form-field appearance="fill" class="full-width">
                        <mat-label>{{'FORM.ADULTS' | translate}}</mat-label>
                        <input min="0" matInput type="number" formControlName="adults">
                    </mat-form-field>
                    <ng-container *ngIf="editForm.get('adults').hasError('max')">
                        <p class="text-danger">{{'FORM.ADULT-MAX' | translate}}</p>
                    </ng-container>
                </p>
                <p *ngIf="!isDesktop">
                    <mat-form-field appearance="fill" class="full-width">
                        <mat-label>{{'FORM.ADULTS' | translate}}</mat-label>
                        <mat-select formControlName="adults">
                            <mat-option *ngFor="let option of adultsArray" [value]="option">
                                {{option}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="editForm.get('adults').hasError('max')">
                        <p class="text-danger">{{'FORM.ADULT-MAX' | translate}}</p>
                    </ng-container>
                </p>
            </div>
            <div class="col-md-6">
                <p *ngIf="isDesktop">
                    <mat-form-field appearance="fill" class="full-width">
                        <mat-label>{{'FORM.KIDS' | translate}}</mat-label>
                        <input min="0" matInput value="{{0}}" type="number" formControlName="kids">
                    </mat-form-field>
                    <ng-container *ngIf="editForm.get('kids').hasError('max')">
                        <p class="text-danger">{{'FORM.CHILDREN-MAX' | translate}}</p>
                    </ng-container>
                </p>
                <p *ngIf="!isDesktop">
                    <mat-form-field appearance="fill" class="full-width">
                        <mat-label>{{'FORM.KIDS' | translate}}</mat-label>
                        <mat-select formControlName="kids">
                            <mat-option *ngFor="let option of kidsArray" [value]="option">
                                {{option}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="editForm.get('kids').hasError('max')">
                        <p class="text-danger">{{'FORM.CHILDREN-MAX' | translate}}</p>
                    </ng-container>
                </p>
            </div>
        </div>
        <p>
          <mat-form-field appearance="fill" class="full-width">
              <mat-label>{{'FORM.AGENCY' | translate}}</mat-label>
              <input matInput type="text" formControlName="agencyId" maxlength="6">
          </mat-form-field>
      </p>
        <button (click)="save()" class="btn btn-next" [disabled]="!editForm.valid">{{'FORM.CHANGES' | translate}}</button>
    </form>
</div>
